//Lib
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { useToken } from "hook/auth";
import { useOnClickOutside } from "hook/click-outside";
import clsx from "clsx";
import { toast } from "react-toastify";

// Services
import {
  getClients,
  getProjectToManage,
  postRestoreArchive,
  postApproveArchive,
  postActiveProject,
  postRequestArchive,
  exportExcelForAdmin,
  postRejectArchive,
  createMatterApi,
  postRequestDelete,
  postRequestNearline
} from "services/ProjectAdministrationService";

// Store
import { setProjectAdminSearchCondition } from "store/SearchConditionReducer";
import { fetchProjectList } from "store/ProjectReducer";
import { resetStoreReview } from "store/GeneralReviewReducer";
import { clearDatePickerData } from "store/DatePickerReducer";

//Components
import PrintExport from "components/shared/print-export/PrintExport";
import { PaginationResult } from "components/shared/paging/PaginationResult";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";
import ItemResultInline from "components/shared/item-result-inline/ItemResultInline";
import { MemberList } from "components/event-timeline/members/MemberList";
import LastActivity from "components/shared/last-activity/LastActivity";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import { Button } from "components/shared/button/Button";
import CreateMatterModal from "components/project/create-matter-modal/CreateMatterModal";

// Constants
import {
  SCREEN_WIDTH_3839,
  SORT_BY,
  DATE_TIME_TYPE,
} from "constants/Constants";
import { PROJECT_CONSTANT } from "constants/ProjectConstant";
import {
  ARCHIVE_ACTION,
  ARCHIVE_MSG,
  ARCHIVE_TYPE,
  COMMON_TEXT,
  NO_DATABASE,
  PATHNAME_ITEMS,
  PATH_NAME,
  PRINT_SCREEN_NAME,
} from "constants/Common";
import { MATTER_MANAGEMENT_TOAST } from "constants/MessageToastCommon";
import { MATTER_VALIDATION } from "constants/Validations";
import { LOCAL_STORAGE } from "constants/LocalStorage";

// Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { addDays } from "helpers/DateTimeFormatterHelper";
import { trimObject } from "helpers/ObjectHelper";

// Styles
import "components/project/styles.scss";
import styles from "./ProjectAdministration.module.scss";

const ProjectsAdministration = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const actionRef = useRef();

  const itemPerTable = {
    lg: 20,
    md: 15,
  };

  const tablePerPage =
    window.innerWidth > SCREEN_WIDTH_3839 ? itemPerTable.lg : itemPerTable.md;

  const COLUMN_NAME = {
    clientName: "clientName",
    projectName: "projectName",
    userCount: "userCount",
    userRequest: "userRequest",
    dateCreated: "dateCreated",
    status: "status",
  };
  //initStateSort
  const initSort = [
    { type: COLUMN_NAME.clientName, isAcs: true },
    { type: COLUMN_NAME.projectName, isAcs: true },
    { type: COLUMN_NAME.userCount, isAcs: true },
    { type: COLUMN_NAME.userRequest, isAcs: true },
    { type: COLUMN_NAME.dateCreated, isAcs: false },
    { type: COLUMN_NAME.status, isAcs: true },
  ];

  //Modal information
  const modalInformation = {
    restore: {
      title: "Restore Matter",
      content: ARCHIVE_MSG.restore,
      textConfirm: "Submit",
    },
    archive: {
      title: "Archive Matter",
      content: ARCHIVE_MSG.archive,
      textConfirm: "Approve",
      textReject: "Reject",
    },
    active: {
      title: "Confirm Active Matter",
      content: ARCHIVE_MSG.active,
      textConfirm: "Approve",
    },
    request: {
      title: "Request Archive",
      content: ARCHIVE_MSG.request,
      textConfirm: "Yes",
    },
    delete: {
      title: "Request Deletion",
      content: ARCHIVE_MSG.delete,
      textConfirm: "Yes",
    },
    nearline: {
      title: "Request Nearline",
      content: ARCHIVE_MSG.nearline,
      textConfirm: "Yes",
    },
  };

  //UI data
  const [suggestions, setSuggestions] = useState();
  const [lastActivities, setLastActivities] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportLoading, setExportLoading] = useState(false);

  //Modal check
  const [showAction, setShowAction] = useState();
  const [modalType, setModalType] = useState(ARCHIVE_ACTION.request);
  const [isShowModal, setIsShowModal] = useState(false);

  //Function/params
  const [projectToManage, setProjectToManage] = useState([]);
  const [projectId, setProjectId] = useState(0);
  const [tableCurrentPage, setTableCurrentPage] = useState(0);
  const [tableTotalRecords, setTableTotalRecords] = useState(0);
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState(0);
  const [sortColumns, setSortColumns] = useState(initSort);
  const [isSearch, setIsSearch] = useState(false);
  const [sortParams, setSortParams] = useState({
    "PaginationParams.ColumnSort": COLUMN_NAME.dateCreated,
    "PaginationParams.Orderby": SORT_BY.desc,
  });
  const [showCreateMatterModal, setShowCreateMatterModal] = useState(false);

  const { isAdmin, isClientAdmin } = useToken();

  // Store
  const { searchInput, pageIndex } = useSelector(
    (state) => state.searchCondition.projectAdminSearch
  );

  //Sort Handle
  const onSortTable = (columnName) => {
    const obj = sortColumns.find((item) => item.type === columnName);
    obj.isAcs = !obj.isAcs;
    if (columnName === COLUMN_NAME.dateCreated) {
      setSortColumns([
        ...initSort.filter((item) => item.type !== columnName),
        obj,
      ]);
    } else {
      const objDate = sortColumns.find(
        (item) => item.type === COLUMN_NAME.dateCreated
      );
      objDate.isAcs = true;
      setSortColumns([
        ...initSort.filter(
          (s) => ![columnName, COLUMN_NAME.dateCreated].includes(s.type)
        ),
        obj,
        objDate,
      ]);
    }
    setSortParams({
      "PaginationParams.ColumnSort": obj.type,
      "PaginationParams.Orderby": obj.isAcs ? SORT_BY.asc : SORT_BY.desc,
    });
    setReRender(!reRender);
  };

  const classSort = (columnName) => {
    const obj = sortColumns.find((item) => item.type === columnName);
    return `sort ${obj.isAcs ? SORT_BY.sortAsc : SORT_BY.sortDesc}`;
  };

  //Define actions
  const action = (key) => {
    switch (key) {
      case ARCHIVE_TYPE.pending:
        return [ARCHIVE_TYPE.archive];
      case ARCHIVE_TYPE.archived:
        return [ARCHIVE_TYPE.restore];
      case ARCHIVE_TYPE.creating:
        return [ARCHIVE_TYPE.active];
      case ARCHIVE_TYPE.active:
        return [ARCHIVE_TYPE.request, ARCHIVE_TYPE.delete, ARCHIVE_TYPE.nearline];
      default:
        return [""];
    }
  };

  const fetchClients = async () => {
    try {
      const result = await getClients();
      if (!result) return;
      setClients(result.data);
      isClientAdmin() && setClientId(result.data[0]?.clientId);
    } catch (error) {
      console.log(error);
    }
  };

  //Handle Export Excel Admin
  const exportExcelAdmin = () => {
    const params = {
      clientId: clientId === 0 ? null : clientId,
      search: inputRef?.current?.value?.trim(),
      timeOffset: new Date().getTimezoneOffset(),
      paginationParams: {
        pageNumber: tableCurrentPage + 1,
        pageSize: tablePerPage,
        columnSort: sortParams["PaginationParams.ColumnSort"],
        orderBy: sortParams["PaginationParams.Orderby"],
      },
    };
    return exportExcelForAdmin(params);
  };

  //Export Excel function
  const exportProjectToExcel = async () => {
    setExportLoading(true);
    try {
      const result = await exportExcelAdmin();
      const url = window.URL.createObjectURL(result.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `StreemView_Matters.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error.response);
    } finally {
      setExportLoading(false);
    }
  };

  const updateSearchCondition = () => {
    dispatch(
      setProjectAdminSearchCondition({
        searchInput: inputRef?.current?.value,
        pageIndex: currentPage,
      })
    );
  };

  //Get projects with admin and client
  const getProjectTable = async () => {
    setTableLoading(true);
    try {
      const params = {
        ...sortParams,
        clientId: clientId === 0 ? null : clientId,
        search: inputRef?.current?.value?.trim(),
        "PaginationParams.PageSize": tablePerPage,
        "PaginationParams.PageNumber": tableCurrentPage + 1,
      };
      const result = await getProjectToManage(params);
      if (!result) return;
      setProjectToManage(result.data.projects.items);
      setTableTotalRecords(result.data.projects.totalRecords);
      setSuggestions(result.data.projects.selectName);
      setLastActivities(result.data.recentActivities);
    } catch (error) {
      console.log(error);
    } finally {
      setTableLoading(false);
    }
  };

  //handle click outside of action
  useOnClickOutside(actionRef, () => setShowAction(false));

  const handleChangeDropItem = (event) => {
    setTableCurrentPage(0);
    setClientId(event.value !== "all-client" ? event.value.split("__")[0] : 0);
    setReRender(!reRender);
  };

  const handlePagingTable = (event) => {
    setTableCurrentPage(event.selected);
    setReRender(!reRender);
  };

  const clientList = [{ value: "all-client", label: "All Clients" }].concat(
    clients.map((elem, i) => ({
      value: elem.clientId + "__" + i,
      label: elem.clientName,
    }))
  );

  //Handle Action: Reject, Approve,....
  const handleActionModal = (action) => {
    setIsShowModal(true);
    switch (action) {
      case ARCHIVE_TYPE.restore:
        setModalType(ARCHIVE_ACTION.restore);
        break;
      case ARCHIVE_TYPE.archive:
        setModalType(ARCHIVE_ACTION.archive);
        break;
      case ARCHIVE_TYPE.active:
        setModalType(ARCHIVE_ACTION.active);
        break;
      case ARCHIVE_TYPE.request:
        setModalType(ARCHIVE_ACTION.request);
        break;
      case ARCHIVE_TYPE.delete:
        setModalType(ARCHIVE_ACTION.delete);
        break;
      case ARCHIVE_TYPE.nearline:
        setModalType(ARCHIVE_ACTION.nearline);
        break;
      default:
        break;
    }
  };

  // Handle click Action
  const handleAction = (projectId, action) => {
    setProjectId(projectId);
    handleActionModal(action);
  };

  // Handle Reject Archive
  const handleRejectArchive = () => {
    postRejectArchive(projectId)
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(fetchProjectList());
        setReRender(!reRender);
      });
  };

  // Handle Approve Archive
  const handleApproveArchive = () => {
    postApproveArchive(projectId)
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(fetchProjectList());
        setReRender(!reRender);
      });
  };

  //Handle Request Archive
  const handleRequestArchive = () => {
    postRequestArchive(projectId)
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(fetchProjectList());
        setReRender(!reRender);
      });
  };

  //Handle Request Archive
  const handleRequestDelete = () => {
    postRequestDelete(projectId)
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(fetchProjectList());
        setReRender(!reRender);
      });
  };

  //Handle Request Archive
  const handleRequestNearline = () => {
    postRequestNearline(projectId)
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(fetchProjectList());
        setReRender(!reRender);
      });
  };

  // Handle Restore
  const handleRestoreArchive = () => {
    postRestoreArchive(projectId)
      .catch((error) => {
        const msg = error.response.data.message;
        if (msg === NO_DATABASE) {
          toast.error(
            <span>
              {MATTER_MANAGEMENT_TOAST.noDatabase}{" "}
              <strong>MatterID: {projectId}</strong>
            </span>
          );
        }
      })
      .finally(() => {
        dispatch(fetchProjectList());
        setReRender(!reRender);
      });
  };

  // handle Active
  const handleActive = () => {
    postActiveProject(projectId)
      .catch((error) => {
        const msg = error.response.data.message;
        if (msg === NO_DATABASE) {
          toast.error(
            <span>
              {MATTER_MANAGEMENT_TOAST.noDatabase}{" "}
              <strong>MatterID: {projectId}</strong>
            </span>
          );
        }
      })
      .finally(() => {
        dispatch(fetchProjectList());
        setReRender(!reRender);
      });
  };

  const handleConfirmModal = (action) => {
    setIsShowModal(false);
    setTableLoading(true);
    setShowAction(false);
    switch (modalType) {
      case ARCHIVE_ACTION.restore:
        handleRestoreArchive();
        break;
      case ARCHIVE_ACTION.archive:
        if (action === ARCHIVE_ACTION.reject) handleRejectArchive();
        else handleApproveArchive();
        break;
      case ARCHIVE_ACTION.active:
        handleActive();
        break;
      case ARCHIVE_ACTION.request:
        handleRequestArchive();
        break;
      case ARCHIVE_ACTION.delete:
        handleRequestDelete();
        break;
      case ARCHIVE_ACTION.nearline:
        handleRequestNearline();
        break;
      default:
        break;
    }
  };

  //return projectDetails
  const showMatterStats = (project) => {
    const {
      totalItems,
      totalGBHosted,
      responsiveItems,
      nonResponsiveItems,
      unTaggedItems,
      exportedItems,
      lastActivity,
    } = project;

    const matterStatsData = [
      {
        label: "Total items",
        value: totalItems?.toLocaleString(),
        isBold: true,
      },
      {
        label: "Total GB",
        value: totalGBHosted?.toLocaleString(),
        isBold: true,
      },
      {
        label: "Responsive",
        value: responsiveItems?.toLocaleString(),
        isBold: true,
      },
      {
        label: "Non-Responsive",
        value: nonResponsiveItems?.toLocaleString(),
        isBold: true,
      },
      {
        label: "Untagged",
        value: unTaggedItems?.toLocaleString(),
        isBold: true,
      },
      {
        label: "Exported",
        value: exportedItems?.toLocaleString(),
        isBold: true,
      },
      {
        label: "Last Activity",
        value: formatDateTime({
          dateTime: lastActivity,
          type: DATE_TIME_TYPE.MM_DD_YYYY,
        }),
      },
    ];

    return project.status !== ARCHIVE_TYPE.creating ? (
      <>
        <div className={styles["table-project-info"]}>
          <div className={styles["table-project-total-items"]}>
            <span>{`#items = ${project.totalItems?.toLocaleString()}`}</span>
          </div>
          <div className={styles["table-project-info-more"]}>
            <div className={styles["table-project-info-header"]}>
              <div
                className={styles["project-name"]}
                title={project.projectName}
              >
                {project.projectName}
              </div>
            </div>
            {matterStatsData.map((item) => (
              <ItemResultInline
                key={item.label}
                label={item.label}
                value={item.value}
                fwBold={item.isBold}
              />
            ))}
          </div>
        </div>
      </>
    ) : (
      COMMON_TEXT.default
    );
  };

  //Status style
  const statusStyles = (status) => {
    switch (status) {
      case ARCHIVE_TYPE.pending:
      case ARCHIVE_TYPE.pendingDeletion:
      case ARCHIVE_TYPE.pendingNearline:
        return clsx(
          styles["table-project-status"],
          styles["table-project-status-pending"]
        );
      case ARCHIVE_TYPE.archived:
        return clsx(
          styles["table-project-status"],
          styles["table-project-status-archived"]
        );
      case ARCHIVE_TYPE.creating:
        return clsx(
          styles["table-project-status"],
          styles["table-project-status-creating"]
        );
      default:
        return clsx(
          styles["table-project-status"],
          styles["table-project-status-active"]
        );
    }
};
  // Set value for Go back button
  const toProjectDetail = () => {
    // clear store review
    dispatch(resetStoreReview());
    dispatch(clearDatePickerData());
    //get new project list
    dispatch(fetchProjectList());
    localStorage.setItem(PROJECT_CONSTANT.projectDetail, "");
    localStorage.setItem(LOCAL_STORAGE.previousPath, "");
  };

  const handleToProjectDetail = (item) => {
    if (
      [
        ARCHIVE_TYPE.archived,
        ARCHIVE_TYPE.creating,
        ARCHIVE_TYPE.deleted,
      ].includes(item.status)
    ) {
      return PATHNAME_ITEMS.error404;
    } else {
      return `/${PATH_NAME.matters}/${item.projectId}/${PATH_NAME.streems}`;
    }
  };

  const handleSearchProject = () => {
    setIsSearch(inputRef.current.value.length !== 0);
    setTableCurrentPage(0);
    setReRender(!reRender);
    updateSearchCondition();
  };

  // Create matter api
  const handleCreateMatter = async (data, setFieldError) => {
    setTableLoading(true);
    setShowCreateMatterModal(false);

    try {
      const params = {
        ...data,
        clientId: isAdmin() ? data.clientId : clientId,
      };
      const trimData = trimObject(params);

      toast.success(MATTER_MANAGEMENT_TOAST.createMatter.initiated);
      await createMatterApi(trimData);
      toast.success(MATTER_MANAGEMENT_TOAST.createMatter.success);

      setReRender(!reRender);
    } catch (error) {
      toast.error(MATTER_MANAGEMENT_TOAST.createMatter.error);
      if (error.response.data.message === MATTER_MANAGEMENT_TOAST.matterNameExists)
        setFieldError("matterName", MATTER_VALIDATION.exists);
      setTableLoading(false);
    }
  };

  //fetch client
  useEffect(() => {
    inputRef.current.value = searchInput || "";
    setCurrentPage(pageIndex);
    fetchClients();
    return () => {
      dispatch(
        setProjectAdminSearchCondition({
          searchInput: "",
          pageIndex: 1,
        })
      );
    };
  }, []);

  //fetchData
  useEffect(() => {
    getProjectTable();
  }, [reRender]);

  //Main components
  return (
    <div className="container-fluid active-project">
      {showCreateMatterModal && (
        <CreateMatterModal
          show={showCreateMatterModal}
          onClose={() => setShowCreateMatterModal(false)}
          onSubmit={handleCreateMatter}
          clientList={clients.map((item) => ({
            value: item.clientId,
            label: item.clientName,
          }))}
        />
      )}
      <PopupConfirm
        isShow={isShowModal}
        title={modalInformation[modalType].title}
        content={modalInformation[modalType].content}
        textConfirm={modalInformation[modalType].textConfirm}
        textReject={modalInformation[modalType].textReject}
        handleSubmit={() => handleConfirmModal()}
        handleReject={() => handleConfirmModal("reject")}
        handleClose={() => setIsShowModal(false)}
      />
      <div className={clsx("main", styles["project-management"])} id="main">
        <div className="row gx-5">
          <div className="main-container-projects main-container-auto-width col-12 col-lg-9 col-xl-10">
            <div className="projects">
              <div
                className={clsx(
                  "entity-navigate",
                  styles["project-management-header"]
                )}
              >
                <div className="entity-navigate__title-wrap mb-16">
                  <h2>Matter Management</h2>
                </div>
                {isAdmin() && (
                  <div className="client-filter mb-16">
                    <Select
                      className="dropdown-react-select"
                      isLoading={tableLoading}
                      defaultValue={{ label: "All Clients" }}
                      onChange={handleChangeDropItem}
                      options={clientList}
                      noOptionsMessage={() => null}
                    />
                  </div>
                )}
                <div className="entity-search mb-16">
                  <PrintExport
                    screenName={PRINT_SCREEN_NAME.projectManagement}
                    handleExport={exportProjectToExcel}
                    isDisplayTime
                    exportLoading={exportLoading}
                  />
                  <Button
                    handleClick={() => setShowCreateMatterModal(true)}
                    type="button"
                    name="Create Matter"
                    iconUrl="/images/plus-icon-blue.svg"
                  />
                </div>
              </div>
              <div className={styles["project-admin-body"]}>
                <div className={styles["project-table-header"]}>
                  <span className={styles["project-table-count"]}>
                    {tableTotalRecords} Matter
                    {tableTotalRecords > 1 ? "s" : ""}
                  </span>
                  <div className="entity-search">
                    <SearchInputGeneral
                      inputRef={inputRef}
                      isAutoComplete={true}
                      suggestions={suggestions}
                      placeholder="Search Matters"
                      name="search"
                      onSubmitSearch={handleSearchProject}
                      searchInput={searchInput || ""}
                    />
                  </div>
                </div>
                {tableLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    role="status"
                    className="spinner-loading"
                  />
                ) : projectToManage?.length > 0 ? (
                  <>
                    <div className={styles["table-project-management"]}>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <span>ID</span>
                            </th>
                            <th>
                              <a
                                className={classSort(COLUMN_NAME.clientName)}
                                onClick={() =>
                                  onSortTable(COLUMN_NAME.clientName)
                                }
                              >
                                Client Name
                              </a>
                            </th>
                            <th>
                              <a
                                className={classSort(COLUMN_NAME.projectName)}
                                onClick={() =>
                                  onSortTable(COLUMN_NAME.projectName)
                                }
                              >
                                Matter Name
                              </a>
                            </th>
                            <th>
                              <a
                                className={classSort(COLUMN_NAME.userCount)}
                                onClick={() =>
                                  onSortTable(COLUMN_NAME.userCount)
                                }
                              >
                                Users
                              </a>
                            </th>
                            <th>
                              <a
                                className={classSort(COLUMN_NAME.userRequest)}
                                onClick={() =>
                                  onSortTable(COLUMN_NAME.userRequest)
                                }
                              >
                                User Request
                              </a>
                            </th>
                            <th>
                              <a
                                className={classSort(COLUMN_NAME.dateCreated)}
                                onClick={() =>
                                  onSortTable(COLUMN_NAME.dateCreated)
                                }
                              >
                                Created Date
                              </a>
                            </th>
                            <th>
                              <span>Matter Stats</span>
                            </th>
                            <th>
                              <a
                                className={classSort(COLUMN_NAME.status)}
                                onClick={() => onSortTable(COLUMN_NAME.status)}
                              >
                                Status
                              </a>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectToManage.map((item, index) => (
                            <tr key={item.projectId}>
                              <td className={styles["table-project-id"]}>
                                <span>
                                  {tableCurrentPage * tablePerPage + index + 1}
                                </span>
                              </td>
                              <td>{item.clientName}</td>
                              <td>
                                <Link
                                  to={handleToProjectDetail(item)}
                                  onClick={toProjectDetail}
                                  className={clsx(
                                    styles["table-project-name"],
                                    item.status === ARCHIVE_TYPE.archived
                                      ? styles["table-project-disabled"]
                                      : ""
                                  )}
                                  title={item.projectName}
                                >
                                  {item.projectName}
                                </Link>
                              </td>
                              <td>
                                <div className={styles["table-project-user"]}>
                                  {item.users.length > 0 ? (
                                    <MemberList
                                      matterId={item.projectId}
                                      data={item.users}
                                      className={styles["member-list"]}
                                      limitMember={3}
                                    />
                                  ) : (
                                    COMMON_TEXT.default
                                  )}
                                </div>
                              </td>
                              <td>
                                {item.userRequest &&
                                  item.status !== ARCHIVE_TYPE.active ? (
                                  <span title={item.userRequest}>
                                    {item.userRequest}
                                  </span>
                                ) : (
                                  COMMON_TEXT.default
                                )}
                              </td>
                              <td>
                                <div>
                                  <span className={styles["create-date"]}>
                                    {formatDateTime({
                                      dateTime: item.dateCreated,
                                      type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
                                    })}
                                  </span>
                                  {item.status === ARCHIVE_TYPE.archived && (
                                    <p className={styles["delete-date"]}>
                                      To be deleted:{" "}
                                      {addDays(item.archivedDate, 30)}
                                    </p>
                                  )}
                                </div>
                              </td>
                              <td>{showMatterStats(item)}</td>
                              <td>
                                <div className={statusStyles(item.status)}>
                                  <span>{item.status}</span>
                                </div>
                              </td>
                              <td className={styles["table-project-action"]}>
                                <img
                                  src="/images/menu-control.svg"
                                  className={styles["table-project-action-button"]}
                                  alt="menu-icon"
                                  onClick={() =>
                                    setShowAction(showAction === index ? null : index)
                                  }
                                />
                                {showAction === index && (
                                  <div
                                    className={
                                      styles["table-project-action-show"]
                                    }
                                    ref={actionRef}
                                  >
                                    {action(item.status).map((status) => (
                                      <div
                                        className={styles["action-item"]}
                                        key={status}
                                        onClick={() =>
                                          handleAction(item.projectId, status)
                                        }
                                      >
                                        {status}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <PaginationResult
                      perPage={tablePerPage}
                      forcePage={tableCurrentPage}
                      totalRecord={tableTotalRecords}
                      pageItems={projectToManage.length}
                      handlePagingClick={handlePagingTable}
                      isSmall
                    />
                  </>
                ) : (
                  <div className={styles["no-result"]}>
                    <EmptyPage
                      messages={
                        isSearch
                          ? "No results found. Please try again."
                          : "You don't have any matters yet."
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="main-activities main-activities-modified col-12 col-lg-3 col-xl-2">
            <div className="project-detail__last-activity p-0">
              <h3>Last Activity</h3>
              {lastActivities &&
                lastActivities.map((activity, activityIndex) => (
                  <LastActivity
                    key={activityIndex}
                    className="activities-box"
                    title={activity.name}
                    name={activity.projectName}
                    content={activity.description}
                    postTime={activity.creationTime}
                    projectId={activity.projectId}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsAdministration;
