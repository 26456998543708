import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import clsx from "clsx";

// Constants
import { FILE_TYPE } from "constants/DataType";

//Components
import { AttachmentReview } from "../../communication-review/AttachmentReview";
import PreviewAudio from "components/intelligent-search/search-result/preview/preview-audio/PreviewAudio";
import EmbedImageAttachment from "../../shared/file-components/embed-image-attachment/EmbedImageAttachment";
import PreviewVideo from "components/intelligent-search/search-result/preview/preview-video/PreviewVideo";

//Styles
import styles from "./RenderAttachment.module.scss";

const RenderAttachment = ({ attachment, isAttachmentEmail, searchMsgInput }) => {
  const { contentType, attachmentID, hasNative, hasThumbnail, filename, url } = attachment;
  const showImage = hasThumbnail || hasNative || url;

  const { projectId } = useParams();
  const [showAttach, setShowAttach] = useState(false);
  const [attachmentId, setAttachmentId] = useState("");

  const showAttachment = (attachmentID) => {
    setShowAttach(true);
    setAttachmentId(attachmentID);
  };

  const handleClose = () => {
    setShowAttach(false);
  };

  const renderAttachNotAvailable = (key, filename) => (
    <div key={key} className={styles["attachment-not-available"]}>
      <img src="/images/icons/attachment.svg" alt="attachment" />
      {filename ? (
        <p>The attachment &quot;{filename}&quot; is not available.</p>
      ) : (
        <p>The attachment is not available.</p>
      )}
    </div>
  );

  const renderAttachNotContentType = (key, filename) => (
    <div
      key={key}
      onClick={() => showAttachment(key)}
      className={styles["attachment-not-content-type"]}
    >
      {filename}
    </div>
  );

  const renderAttachments = () => {
    if (!hasNative && !url) {
      return renderAttachNotAvailable(attachmentID, filename);
    }
    else {
      if (contentType) {
        const fileType = contentType.split("/");
        switch (fileType[0]) {
          case FILE_TYPE.application:
          case FILE_TYPE.text:
            return (
              <div
                className={clsx(attachment?.queryHit === "yes" ?
                  styles["attachment-query-hit"] :
                  styles["attachment-query"]
                )}
                key={attachmentID}
              >
                <img
                  className={clsx("pe-3", styles["file-attach-img"])}
                  src={attachment?.queryHit === "yes" ? "/images/file-attach-gold.svg"
                    : "/images/file-attach-blue.svg"
                  }
                  onClick={() => showAttachment(attachmentID)}
                  alt="File attach"
                />
                <span
                  className={styles["attachment-decoration"]}
                  onClick={() => showAttachment(attachmentID)}
                >
                  {filename}
                </span>
              </div>
            );
          case FILE_TYPE.image:
            return (
              <figure
                key={attachmentID}
                className={clsx(
                  styles["chat-content"],
                  !isAttachmentEmail ? styles["image-type"] : "",
                  "mar_t10"
                )}
              >
                <div
                  className={clsx(
                    styles["loading-blur"],
                    !isAttachmentEmail ? styles["loading-blur-chat"] : ""
                  )}
                >
                  <EmbedImageAttachment
                      matterId={projectId}
                      attachmentId={attachmentID}
                      optionalFileName={filename}
                      onClick={() => showImage && showAttachment(attachmentID)}
                  />
                </div>
              </figure>
            );
          case FILE_TYPE.video:
            return (
              <figure
                key={attachmentID}
                className={clsx(styles["chat-content"], "pt-3")}
              >
                {!isAttachmentEmail && (
                  <p>
                    <span
                      className={styles["attachment-decoration"]}
                      onClick={() => showAttachment(attachmentID)}
                    >
                      {filename}
                    </span>
                  </p>
                )}
                <div
                  className={styles["video-thumbnail-container"]}
                  onClick={() => showAttachment(attachmentID)}
                >
                  <PreviewVideo matterId={projectId} attachmentId={attachmentID} />
                </div>
              </figure>
            );
          case FILE_TYPE.audio:
            return <PreviewAudio matterId={projectId} attachmentId={attachmentID} filename={filename} />;
          default:
            return renderAttachNotAvailable(attachmentID, filename);
        }
      } else {
        return renderAttachNotContentType(attachmentID, filename);
      }
    }
  };

  return (
    <>
      {showAttach && (
        <AttachmentReview
          show={showAttach}
          onHide={handleClose}
          attachmentId={attachmentId}
          projectId={parseInt(projectId)}
          searchMsgInput={searchMsgInput}
        />
      )}
      {renderAttachments()}
    </>
  );
};

RenderAttachment.propTypes = {
  attachment: PropTypes.object,
  isAttachmentEmail: PropTypes.bool,
  allowEventPropagation: PropTypes.bool,
  searchMsgInput: PropTypes.string
};

export default RenderAttachment;
