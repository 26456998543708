import { DATA_TYPE } from "constants/DataType";

export const NATIVE_VIEWER = {
    label: "Native",
    value: "NativeViewer",
}

export const TEXT_VIEWER = {
    label: "Text",
    value: "TextViewer",
}

export const PREVIEW_RESULT_VIEWER_OPTIONS = {
    NATIVE_VIEWER: NATIVE_VIEWER,
    TEXT_VIEWER: TEXT_VIEWER,
}

export const MEDIA_VIEWER_TYPE = [DATA_TYPE.video, DATA_TYPE.audio, DATA_TYPE.image, DATA_TYPE.voicemail];
export const TEXT_VIEWER_TYPE = [DATA_TYPE.file, ...MEDIA_VIEWER_TYPE];