import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  viewerLoading: false,
  disableViewerActions: false,
  looseDocSettings: {
    userSelectedViewerType: null,
  }
};

const viewerSlice = createSlice({
  name: "viewer",
  initialState,
  reducers: {
    setViewerLoading: (state, action) => {
        state.viewerLoading = action.payload;
    },
    setDisableViewerActions: (state, action) => {
        state.disableViewerActions = action.payload;
    },
    setLooseDocsSettings: (state, action) => {
        state.looseDocSettings = action.payload;
    }
  },
});

export const {
    setViewerLoading,
    setDisableViewerActions,
    setLooseDocsSettings
} = viewerSlice.actions;

export const ViewerReducer = viewerSlice.reducer;
