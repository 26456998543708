import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BeatLoader } from "react-spinners";
import clsx from "clsx";

// Services
import { getSourceInfo } from "services/ImportManagementService";
import { convertCompressImageApi } from "services/StreemViewConfigServices";

// Components
import DropdownGeneral from "components/shared/dropdown-selector/dropdown-general/DropdownGeneral";
import { Button } from "components/shared/button/Button";

// Constants
import { COLORS } from "constants/Common";

// Styles
import styles from "./ConvertCompressImage.module.scss";

const ConvertCompressImage = (props) => {
  const {
    titleConfig = "",
    projectId = 0,
    isLoadingProject = false,
    options = [],
    onChangeProjectId = () => {},
  } = props;

  const [dataSourceId, setDataSourceId] = useState("");
  const [sourceInfoLoading, setSourceInfoLoading] = useState(true);
  const [dataSourceList, setDataSourceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSourceInformation = async () => {
    setSourceInfoLoading(true);
    try {
      const isImported = true;
      const { data } = await getSourceInfo(projectId, isImported);
      if (!data || !data.length) {
        setDataSourceList([]);
        return;
      }
      const dataSources = data.map((item) => ({
        label: item.dataSourceName,
        value: item.dataSourceID,
      }));
      setDataSourceList(dataSources);
    } catch (error) {
      console.log(error);
    } finally {
      setSourceInfoLoading(false);
    }
  };

  const handleConvertCompressImage = async () => {
    setIsLoading(true);
    try {
      const result = await convertCompressImageApi(projectId, dataSourceId);
      if (!result) return;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (projectId) getSourceInformation();
  }, [projectId]);

  return (
    <div className={styles["manage-image"]}>
      <h3>{titleConfig}</h3>
      <div className={clsx(styles["form-group"], "app-form-group")}>
        <label className="app-form-label">
          Project Name
          <span className={styles["form-required"]}> *</span>
        </label>
        <DropdownGeneral
          className={clsx(
            "dropdown-react-select",
            "w-100",
            styles["select-project"]
          )}
          placeholder="Select Project Name"
          options={options}
          isLoading={isLoadingProject}
          isDisabled={isLoadingProject}
          onChange={onChangeProjectId}
          value={options.filter((item) => item.value === projectId)}
        />
      </div>
      <div className={clsx(styles["form-group"], "app-form-group")}>
        <label className="app-form-label">
          Source Name
          <span className={styles["form-required"]}> *</span>
        </label>
        <DropdownGeneral
          className={clsx(
            "dropdown-react-select",
            "w-100",
            styles["select-project"]
          )}
          placeholder="Select Source Name"
          isLoading={projectId && sourceInfoLoading}
          isDisabled={sourceInfoLoading}
          value={dataSourceList.filter((item) => item.value === dataSourceId)}
          onChange={(event) => setDataSourceId(event.value)}
          options={dataSourceList}
        />
      </div>
      {isLoading ? (
        <BeatLoader
          color={COLORS.black}
          loading={true}
          size={8}
          className={styles["beat-loader"]}
        />
      ) : (
        <Button
          name="Run Job"
          className="btn-primary-fill"
          handleClick={handleConvertCompressImage}
        />
      )}
    </div>
  );
};

ConvertCompressImage.propTypes = {
  titleConfig: PropTypes.string,
  projectId: PropTypes.number,
  options: PropTypes.array,
  isShowProjectId: PropTypes.bool,
  isLoadingProject: PropTypes.bool,
  onChangeProjectId: PropTypes.func,
};

export default ConvertCompressImage;
