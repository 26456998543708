import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useToken } from "hook/auth";

// Services
import {
  createExportApi,
  editExportApi,
  getExportSummaryApi,
} from "services/ExportManagementService";

// Store
import {
  setCreateExport,
  setLoadingExportSummary,
  setOriginalData,
  setParameterConfig,
  setCurrentTime,
  setParamCondition,
} from "store/ExportManagementReducer";

// Components
import { Button } from "components/shared/button/Button";
import DropdownGeneral from "components/shared/dropdown-selector/dropdown-general/DropdownGeneral";
import Toggle from "components/shared/toggle/Toggle";
import { OnlyDateSelector } from "components/shared/date-picker/OnlyDateSelector";
import ExportConfiguration from "./export-config/ExportConfiguration";
import { Loading } from "components/shared/loading/Loading";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";

// Constants
import {
  DATE_FORMAT_SETTING,
  TIME_FORMAT_SETTING,
  TIME_FORMAT_DEFAULT,
  DATE_FORMAT_DEFAULT,
  DELIMITER_SELECT_OPTION,
  DELIMITER_OPTION_DEFAULT,
  EXPORT_TYPE,
  EXPORT_ACTION,
  DOCUMENT_SIZE_DEFAULT,
  DOCUMENT_SIZE_OPTION,
  BUTTON_ACTION,
  CONTROL_PREFIX_DEFAULT,
  CONTROL_NUMBER_DEFAULT,
  VOLUME_PREFIX_DEFAULT,
  VOLUME_NUMBER_DEFAULT,
  MESSAGE_CONFIG_DEFAULT,
  MSG_CONFIRM,
  STATUS_EXPORT,
} from "constants/ExportConstant";
import { EXPORT_VALIDATIONS } from "constants/Validations";
import { DATE_TIME_TYPE } from "constants/Constants";
import { RESPONSE_STATUS } from "constants/StatusCodeConstant";
import { COMMON_TEXT, PATHNAME_ITEMS, PATH_NAME } from "constants/Common";
import { ERROR_STATUS } from "constants/StatusCodeConstant";
import {
  FIELD_VALIDATION,
  REGEX_ONLY_NUMERIC,
  REGEX_ONLY_TEXT,
} from "constants/RegexConstant";
import { AUTH_CONSTANT } from "constants/AuthConstant";

// Helpers
import {
  formatDateTime,
  formatDateTimeWithOriginal,
} from "helpers/DateTimeFormatterHelper";
import { getValueReactSelect } from "helpers/CommonHelper";
import { removeEmptyStringObject } from "helpers/ObjectHelper";
import { tagSelected } from "helpers/ExportHelpers";
import { getTagSelectedName } from "helpers/GetFileNameHelper";

// Styles
import styles from "./ExportDetail.module.scss";

const ExportDetail = () => {
  const dispatch = useDispatch();
  const { projectId, exportId } = useParams();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { isAdmin, isClientAdmin } = useToken();

  //Get data from store
  const {
    createExport: {
      exportName,
      scheduleTime,
      totalItems,
      exportInformation,
      action,
      status,
      parameterConfig: {
        typeExport,
        tagID,
        excludeTags,
        startingControlPrefix,
        startingControlNumber,
        startingVolumePrefix,
        startingVolumeNumber,
        dateFormat,
        timeZone,
        delimiter,
        exportAroundItems,
        rsmfDocumentSize,
        emlDocumentSize,
      },
    },
    loadingExportSummary,
    currentTime,
    searchLoading,
  } = useSelector((state) => state.export);

  const [exportDAT, setExportDAT] = useState(
    typeExport.includes(EXPORT_TYPE.exportDAT)
  );
  const [exportNativeFile, setExportNativeFile] = useState(
    typeExport.includes(EXPORT_TYPE.exportNativeFiles)
  );
  const [exportRSMF, setExportRSMF] = useState(
    typeExport.includes(EXPORT_TYPE.exportRSMF)
  );
  const [exportEML, setExportEML] = useState(
    typeExport.includes(EXPORT_TYPE.exportEML)
  );
  const [scheduleExport, setScheduleExport] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [loadingPrint, setLoadingPrint] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [buttonAction, setButtonAction] = useState();
  const [isResetForm, setIsResetForm] = useState(false);
  const [errorMsgDate, setErrorMsgDate] = useState("");
  const [isCountTimeFirst, setIsCountTimeFirst] = useState(false);
  const [intervalId, setIntervalId] = useState(0);
  const [isFirstLoadComponent, setIsFirstLoadComponent] = useState(true);

  const { id: userID } =
    JSON.parse(localStorage.getItem(AUTH_CONSTANT.userInfo)) || "";
  const {
    tags: { showTags, allTags },
    loadingTag,
  } = useSelector((state) => state.tag);

  const validationSchema = Yup.object({
    exportName: Yup.string()
      .trim()
      .required(EXPORT_VALIDATIONS.exportName.required)
      .max(100, EXPORT_VALIDATIONS.exportName.maxLength)
      .matches(REGEX_ONLY_TEXT, EXPORT_VALIDATIONS.exportName.invalid)
      .test({
        message: EXPORT_VALIDATIONS.exportName.invalid,
        test: (value) => !FIELD_VALIDATION.emoji.test(value),
      }),
    tagID: Yup.array()
      .required(EXPORT_VALIDATIONS.tagID.required)
      .min(1, EXPORT_VALIDATIONS.tagID.required),
    startingControlPrefix: Yup.string()
      .trim()
      .required(EXPORT_VALIDATIONS.settingStarting.requiredAll)
      .max(100, EXPORT_VALIDATIONS.settingStarting.maxLengthPrefix)
      .matches(REGEX_ONLY_TEXT, EXPORT_VALIDATIONS.settingStarting.invalid)
      .test({
        message: EXPORT_VALIDATIONS.settingStarting.invalid,
        test: (value) => !FIELD_VALIDATION.emoji.test(value),
      }),
    startingControlNumber: Yup.string()
      .trim()
      .required(EXPORT_VALIDATIONS.settingStarting.requiredAll)
      .max(9, EXPORT_VALIDATIONS.settingStarting.maxLengthNumber)
      .matches(REGEX_ONLY_NUMERIC, EXPORT_VALIDATIONS.settingStarting.invalid)
      .test({
        message: EXPORT_VALIDATIONS.settingStarting.invalid,
        test: (value) => Number(value) !== 0,
      }),
    startingVolumePrefix: Yup.string()
      .trim()
      .required(EXPORT_VALIDATIONS.settingStarting.requiredAll)
      .max(100, EXPORT_VALIDATIONS.settingStarting.maxLengthPrefix)
      .matches(REGEX_ONLY_TEXT, EXPORT_VALIDATIONS.settingStarting.invalid)
      .test({
        message: EXPORT_VALIDATIONS.settingStarting.invalid,
        test: (value) => !FIELD_VALIDATION.emoji.test(value),
      }),
    startingVolumeNumber: Yup.string()
      .trim()
      .required(EXPORT_VALIDATIONS.settingStarting.requiredAll)
      .max(9, EXPORT_VALIDATIONS.settingStarting.maxLengthNumber)
      .matches(REGEX_ONLY_NUMERIC, EXPORT_VALIDATIONS.settingStarting.invalid)
      .test({
        message: EXPORT_VALIDATIONS.settingStarting.invalid,
        test: (value) => Number(value) !== 0,
      }),
  });

  const validationSchemaForDraft = Yup.object({
    exportName: Yup.string()
      .trim()
      .max(100, EXPORT_VALIDATIONS.exportName.maxLength)
      .matches(REGEX_ONLY_TEXT, EXPORT_VALIDATIONS.exportName.invalid)
      .test({
        message: EXPORT_VALIDATIONS.exportName.invalid,
        test: (value) => !FIELD_VALIDATION.emoji.test(value),
      }),
    startingControlPrefix: Yup.string()
      .trim()
      .max(100, EXPORT_VALIDATIONS.settingStarting.maxLengthPrefix)
      .matches(REGEX_ONLY_TEXT, EXPORT_VALIDATIONS.settingStarting.invalid)
      .test({
        message: EXPORT_VALIDATIONS.settingStarting.invalid,
        test: (value) => !FIELD_VALIDATION.emoji.test(value),
      }),
    startingControlNumber: Yup.string()
      .trim()
      .max(9, EXPORT_VALIDATIONS.settingStarting.maxLengthNumber)
      .matches(REGEX_ONLY_NUMERIC, EXPORT_VALIDATIONS.settingStarting.invalid)
      .test({
        message: EXPORT_VALIDATIONS.settingStarting.invalid,
        test: (value) => Number(value) !== 0,
      }),
    startingVolumePrefix: Yup.string()
      .trim()
      .max(100, EXPORT_VALIDATIONS.settingStarting.maxLengthPrefix)
      .matches(REGEX_ONLY_TEXT, EXPORT_VALIDATIONS.settingStarting.invalid)
      .test({
        message: EXPORT_VALIDATIONS.settingStarting.invalid,
        test: (value) => !FIELD_VALIDATION.emoji.test(value),
      }),
    startingVolumeNumber: Yup.string()
      .trim()
      .max(9, EXPORT_VALIDATIONS.settingStarting.maxLengthNumber)
      .matches(REGEX_ONLY_NUMERIC, EXPORT_VALIDATIONS.settingStarting.invalid)
      .test({
        message: EXPORT_VALIDATIONS.settingStarting.invalid,
        test: (value) => Number(value) !== 0,
      }),
  });

  //use Formik and Yup management form
  const formik = useFormik({
    initialValues: {
      exportName: "",
      tagID: [],
      excludeTags: [],
      startingControlPrefix: "",
      startingControlNumber: "",
      startingVolumePrefix: "",
      startingVolumeNumber: "",
    },
    validationSchema:
      action === EXPORT_ACTION.saveDraft
        ? validationSchemaForDraft
        : validationSchema,
    onSubmit: (values) => onSubmitExport(values),
  });

  const toastMsg = {
    created: {
      success: `${formik.values.exportName} has created successfully!`,
      failed: `${formik.values.exportName} has failed to create!`,
    },
    updated: {
      success: `${formik.values.exportName} has updated successfully!`,
      failed: `${formik.values.exportName} has failed to update!`,
    },
    saveDraft: {
      success: "Draft has saved successfully!",
      failed: "Draft has failed to save!",
    },
  };

  const getTagRequest = (tagIDs) =>
    getTagSelectedName(state?.isPrint ? allTags : showTags, tagIDs)?.map(
      (tag) => tag.tagID
    );

  const onSubmitExport = async (data) => {
    const {
      exportName,
      startingControlPrefix,
      startingControlNumber,
      startingVolumePrefix,
      startingVolumeNumber,
    } = data;
    try {
      if (
        action !== EXPORT_ACTION.saveDraft &&
        scheduleExport &&
        scheduleTime &&
        scheduleTime < new Date().toISOString()
      ) {
        setErrorMsgDate(EXPORT_VALIDATIONS.scheduleTime.error);
        return;
      }
      setErrorMsgDate("");
      setExportLoading(true);
      const bodyData = {
        exportID: exportId || "",
        projectId,
        userID,
        exportName: exportName.trim(),
        scheduleTime: scheduleExport ? scheduleTime || currentTime : "",
        action,
        totalItems,
        exportInformation,
        parameterConfiguration: {
          typeExport,
          tagID: getTagRequest(tagID),
          excludeTags: getTagRequest(excludeTags),
          startingControlPrefix: startingControlPrefix.trim(),
          startingControlNumber: Number(startingControlNumber),
          startingVolumePrefix: startingVolumePrefix.trim(),
          startingVolumeNumber: Number(startingVolumeNumber),
          exportAroundItems,
          emlDocumentSize,
          rsmfDocumentSize,
          delimiter,
          dateFormat,
          timeZone,
        },
      };
      if (!exportId) dispatch(setParamCondition({ isCreateExport: true }));
      const result = await createExportApi(removeEmptyStringObject(bodyData));
      if (result.status === RESPONSE_STATUS.success) {
        toast.success(
          handleToastMessage({
            action,
            isSuccess: true,
          })
        );
        navigate(
          `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.exportManagement}`
        );
      }
    } catch (error) {
      if (error.response.status === ERROR_STATUS.badRequest)
        formik.setFieldError("exportName", EXPORT_VALIDATIONS.exportName.exist);
      toast.error(handleToastMessage({ action }));
    } finally {
      setExportLoading(false);
    }
  };

  const handleToastMessage = ({ action, isSuccess = false }) => {
    if (action === EXPORT_ACTION.saveDraft)
      return isSuccess ? toastMsg.saveDraft.success : toastMsg.saveDraft.failed;
    if (exportId)
      return isSuccess ? toastMsg.updated.success : toastMsg.updated.failed;
    return isSuccess ? toastMsg.created.success : toastMsg.created.failed;
  };

  const handleToggleExportDAT = () => setExportDAT(!exportDAT);
  const handleToggleNativeFile = () => {
    if (!exportNativeFile && exportRSMF)
      setExportRSMF(false);

    setExportNativeFile(!exportNativeFile);
  };
  const handleToggleScheduleExport = () => setScheduleExport(!scheduleExport);
  const handleToggleExportRSMF = () => {
    if (!exportRSMF && exportNativeFile)
      setExportNativeFile(false);

    setExportRSMF(!exportRSMF);
  };
  const handleToggleExportEML = () => setExportEML(!exportEML);

  const openDatePicker = () => {
    setErrorMsgDate("");
    setShowDatePicker(true);
  };

  const handleDateSelected = (date) => {
    if (new Date(date) < new Date()) {
      setErrorMsgDate(EXPORT_VALIDATIONS.scheduleTime.error);
      return;
    }
    setErrorMsgDate("");
    dispatch(setCreateExport({ scheduleTime: new Date(date).toISOString() }));
    setShowDatePicker(false);
  };

  const handleChangeParameterConfig = (type, data) =>
    dispatch(setParameterConfig({ [type]: data.value }));

  const handleListTypeExport = () => {
    const listType = [];
    if (exportDAT) listType.push(EXPORT_TYPE.exportDAT);
    if (exportNativeFile) listType.push(EXPORT_TYPE.exportNativeFiles);
    if (exportRSMF) listType.push(EXPORT_TYPE.exportRSMF);
    if (exportEML) listType.push(EXPORT_TYPE.exportEML);
    return listType;
  };

  const resetFormExport = () => {
    formik.setFieldValue("exportName", "");
    formik.setFieldValue("tagID", []);
    formik.setFieldValue("excludeTags", []);
    formik.setFieldValue("startingControlPrefix", CONTROL_PREFIX_DEFAULT);
    formik.setFieldValue("startingControlNumber", CONTROL_NUMBER_DEFAULT);
    formik.setFieldValue("startingVolumePrefix", VOLUME_PREFIX_DEFAULT);
    formik.setFieldValue("startingVolumeNumber", VOLUME_NUMBER_DEFAULT);

    const initData = {
      projectId: 0,
      userID: "",
      exportName: "",
      exportTime: "",
      scheduleTime: "",
      isScheduleExport: false,
      action: 0,
      status: 0,
      totalItems: 0,
      exportInformation: "",
      parameterConfig: {
        typeExport: [EXPORT_TYPE.exportDAT],
        tagID: [],
        excludeTags: [],
        startingControlPrefix: CONTROL_PREFIX_DEFAULT,
        startingControlNumber: CONTROL_NUMBER_DEFAULT,
        startingVolumePrefix: VOLUME_PREFIX_DEFAULT,
        startingVolumeNumber: VOLUME_NUMBER_DEFAULT,
        exportAroundItems: MESSAGE_CONFIG_DEFAULT.value,
        rsmfDocumentSize: DOCUMENT_SIZE_DEFAULT.value,
        emlDocumentSize: DOCUMENT_SIZE_DEFAULT.value,
        delimiter: DELIMITER_OPTION_DEFAULT.value,
        dateFormat: DATE_FORMAT_DEFAULT.value,
        timeZone: TIME_FORMAT_DEFAULT.value,
      },
    };
    dispatch(setCreateExport(initData));
    dispatch(setOriginalData(exportId ? "" : JSON.stringify(initData)));
    setExportDAT(true);
    setExportNativeFile(false);
    setScheduleExport(false);
    setExportRSMF(false);
    setExportEML(false);
    setErrorMsgDate("");
  };

  const handleSubmitPopup = () => {
    if (buttonAction === BUTTON_ACTION.reset) {
      resetFormExport();
      setIsResetForm(true);
    }
    formik.setTouched({}, false);
    setShowPopup(false);
  };

  // Handle executing action save, save draft, save and run and reset a export
  const executeExport = (action) => {
    dispatch(setCreateExport({ action }));
    if (EXPORT_ACTION.saveAndRun === action || EXPORT_ACTION.save === action)
      formik.handleSubmit();
  };

  const handleSaveAsDraft = () => {
    setButtonAction(BUTTON_ACTION.saveDraft);
    formik.handleSubmit();
  };

  const handleResetFormExport = () => {
    setButtonAction(BUTTON_ACTION.reset);
    setShowPopup(true);
  };

  const renderContentPopupAction = () => {
    if (buttonAction === BUTTON_ACTION.saveDraft) return MSG_CONFIRM.saveDraft;
    if (buttonAction === BUTTON_ACTION.reset) return MSG_CONFIRM.reset;
    return "";
  };

  const updateSummaryData = (summary) => {
    dispatch(
      setCreateExport({
        exportInformation: JSON.stringify({
          MessageCount: summary?.MessageCount || 0,
          PhysicalFileCount: summary?.PhysicalFileCount || 0,
          EmailCount: summary?.EmailCount || 0,
          OtherTypeCount: summary?.OtherTypeCount || 0,
          TotalItems: summary?.TotalItems || 0,
          Size: summary?.Size || 0,
          Cost: summary?.Cost || 0,
          TotalFile: summary?.TotalFile || 0,
        }),
      })
    );
  };

  const fetchDataExportSummary = async () => {
    try {
      dispatch(setLoadingExportSummary(true));
      const bodyRequest = {
        tagIDs: getTagRequest(tagID),
        excludeTags: getTagRequest(excludeTags),
        typeExport,
        exportAroundItems,
        RSMFDocumentSize: typeExport.includes(EXPORT_TYPE.exportRSMF)
          ? rsmfDocumentSize
          : DOCUMENT_SIZE_DEFAULT.value,
      };

      const result = await getExportSummaryApi(projectId, bodyRequest);
      if (!result || !result.data) return;
      const {
        messageCount,
        physicalFileCount,
        emailCount,
        otherTypeCount,
        totalItems,
        size,
        cost,
        totalFile,
      } = result.data;
      const summaryData = {
        MessageCount: messageCount,
        PhysicalFileCount: physicalFileCount,
        EmailCount: emailCount,
        OtherTypeCount: otherTypeCount,
        TotalItems: totalItems,
        Size: size,
        Cost: cost,
        TotalFile: totalFile,
      };
      dispatch(
        setCreateExport({
          exportInformation: JSON.stringify(summaryData),
          totalItems: totalItems,
        })
      );
      dispatch(setLoadingExportSummary(false));
    } catch (error) {
      console.log(error);
      if (!error.response?.status) return;
      dispatch(setLoadingExportSummary(false));
    }
  };

  const handleShowData = (data) => {
    const {
      exportName,
      tagID,
      excludeTags,
      startingControlPrefix,
      startingControlNumber,
      startingVolumePrefix,
      startingVolumeNumber,
      typeExport,
      scheduleTime,
    } = data;
    formik.setFieldValue("exportName", exportName);
    formik.setFieldValue("tagID", tagID);
    formik.setFieldValue("excludeTags", excludeTags);
    formik.setFieldValue("startingControlPrefix", startingControlPrefix);
    formik.setFieldValue("startingControlNumber", startingControlNumber || "");
    formik.setFieldValue("startingVolumePrefix", startingVolumePrefix);
    formik.setFieldValue("startingVolumeNumber", startingVolumeNumber || "");
    if (scheduleTime) setScheduleExport(true);
    // check typeExport is exist
    if (!state?.isCloneExport) {
      if (typeExport.length > 0) {
        typeExport.forEach((item) => {
          if (item === EXPORT_TYPE.exportDAT) setExportDAT(true);
          if (item === EXPORT_TYPE.exportNativeFiles) setExportNativeFile(true);
          if (item === EXPORT_TYPE.exportRSMF) setExportRSMF(true);
          if (item === EXPORT_TYPE.exportEML) setExportEML(true);
        });
      } else {
        setExportDAT(false);
      }
    }
  };

  const fetchDataFromExportId = async () => {
    if (!exportId) return;
    const params = {
      projectId,
      exportID: exportId,
    };
    try {
      setExportLoading(true);
      const result = await editExportApi(params);
      if (!result || !result.data) return;
      const {
        exportName,
        scheduleTime,
        totalItems,
        exportInformation,
        exportTime,
        status,
      } = result.data;

      //Return 403 if no admin rights
      if (!(isAdmin() || isClientAdmin())) {
        navigate(PATHNAME_ITEMS.error403);
        return;
      }
      const {
        TypeExport,
        TagID,
        ExcludeTags,
        Condition,
        StartingControlPrefix,
        StartingControlNumber,
        StartingVolumePrefix,
        StartingVolumeNumber,
        ExportAroundItems,
        RSMFDocumentSize,
        EMLDocumentSize,
        Delimiter,
        DateFormat,
        TimeZone,
      } = result.data.exportConfiguration
        ? JSON.parse(result.data.exportConfiguration)
        : {};

      const exportData = {
        projectId,
        userID,
        exportName,
        exportTime: exportTime || "",
        scheduleTime,
        isScheduleExport: scheduleTime ? true : false,
        action: 0,
        status,
        totalItems,
        exportInformation: exportInformation || "",
        parameterConfig: {
          // remove exportEML when edit export
          typeExport: TypeExport.filter(
            (item) =>
              ![EXPORT_TYPE.exportEML].includes(item)
          ),
          tagID: TagID,
          excludeTags: ExcludeTags,
          condition: Condition,
          startingControlPrefix: StartingControlPrefix,
          startingControlNumber: StartingControlNumber,
          startingVolumePrefix: StartingVolumePrefix,
          startingVolumeNumber: StartingVolumeNumber,
          exportAroundItems: ExportAroundItems,
          rsmfDocumentSize: RSMFDocumentSize,
          emlDocumentSize: EMLDocumentSize,
          delimiter: Delimiter,
          dateFormat: DateFormat,
          timeZone: TimeZone,
        },
      };
      dispatch(setCreateExport(exportData));
      dispatch(setOriginalData(JSON.stringify(exportData)));
      const data = {
        exportName,
        scheduleTime,
        typeExport: TypeExport,
        tagID: TagID,
        excludeTags: ExcludeTags,
        startingControlPrefix: StartingControlPrefix,
        startingControlNumber: StartingControlNumber,
        startingVolumePrefix: StartingVolumePrefix,
        startingVolumeNumber: StartingVolumeNumber,
      };
      handleShowData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
      setLoadingPrint(false);
    }
  };

  const handleUnloadEvent = () => navigate(pathname, { state: null });

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnloadEvent);
    return () => window.removeEventListener("beforeunload", handleUnloadEvent);
  }, [handleUnloadEvent]);

  useEffect(() => {
    setIsFirstLoadComponent(true);
    // Update the current time
    dispatch(setCurrentTime(new Date().toISOString()));
    // Update schedule time realtime the first time
    setTimeout(() => {
      dispatch(setCurrentTime(new Date().toISOString()));
      setIsCountTimeFirst(true);
    }, 60000 - Number(new Date().toISOString().split(":")[2].split(".")[0]) * 1000);

    // show data when clone export
    if (state?.isCloneExport) {

      const data = {
        exportName,
        scheduleTime,
        typeExport,
        tagID: getTagRequest(tagID),
        excludeTags: getTagRequest(excludeTags),
        startingControlPrefix,
        startingControlNumber,
        startingVolumePrefix,
        startingVolumeNumber,
      };
      dispatch(
        setParameterConfig({
          tagID: getTagRequest(tagID),
          excludeTags: getTagRequest(excludeTags),
        })
      );
      handleShowData(data);
      return;
    }

    if (exportId) return;
    // Reset form when create export
    resetFormExport();
    setIsFirstLoadComponent(false);
    return () => clearInterval(intervalId);
  }, []);

  // Update schedule time realtime every 1 minute
  useEffect(() => {
    if (isCountTimeFirst) {
      const intervalId = setInterval(() => {
        dispatch(setCurrentTime(new Date().toISOString()));
      }, 60000);
      setIntervalId(intervalId);
    }
  }, [isCountTimeFirst]);

  useEffect(() => {
    // fetch data export edit screen
    if (!exportId) return;
    if (!loadingTag && !searchLoading) fetchDataFromExportId();
  }, [loadingTag, searchLoading]);

  useEffect(() => {
    if (STATUS_EXPORT.completed.value === status && state?.isPrint) {
      updateSummaryData(JSON.parse(exportInformation));
      dispatch(setLoadingExportSummary(false));
      return;
    }
    if (!tagSelected(tagID, showTags)) {
      updateSummaryData({});
      dispatch(setLoadingExportSummary(false));
      return;
    }
    fetchDataExportSummary();
  }, [
    JSON.stringify(tagID),
    exportAroundItems,
    rsmfDocumentSize,
    typeExport,
  ]);

  useEffect(() => {
    if (tagID != null && tagID.length !== 0) {
      fetchDataExportSummary();
    }
  }, [JSON.stringify(excludeTags)]);

  useEffect(() => {
    dispatch(setParameterConfig({ typeExport: handleListTypeExport() }));
  }, [exportDAT, exportRSMF, exportEML, exportNativeFile]);

  useEffect(() => {
    dispatch(
      setCreateExport({ isScheduleExport: scheduleExport ? true : false })
    );
  }, [scheduleExport]);

  useEffect(() => {
    if (state?.isPrint) {
      setTimeout(() => {
        state?.isCloneExport && setLoadingPrint(false);
        if (!loadingPrint && !loadingExportSummary && exportName) {
          const pdfFileName = `StreemView_Export Management_${formatDateTimeWithOriginal(
            {
              dateTime: new Date(),
              original: DATE_TIME_TYPE.LT,
              newFormat: DATE_TIME_TYPE.YYYYMMDDhhmmss,
              addZulu: false,
            }
          )}`;
          document.title = pdfFileName;
          window.print();
          document.title = "StreemView";
        }
        window.onafterprint = () => {
          navigate(
            `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.exportManagement}`
          );
        };
      }, 100);
    }
  }, [loadingPrint, loadingExportSummary, exportName]);

  useEffect(() => {
    if (!isFirstLoadComponent) resetFormExport();
  }, [projectId]);

  return (
    <>
      <Form
        className={styles["export-detail-form"]}
        onSubmit={formik.handleSubmit}
      >
        <h3>Export Details</h3>
        <ExportConfiguration
          formik={formik}
          isResetForm={isResetForm}
          setIsResetForm={setIsResetForm}
        />
        <div className={styles["export-type"]}>
          <div className={styles["export-type-form"]}>
            <Form.Group
              className={clsx("app-form-group", styles["type-form-group"])}
            >
              <Form.Label
                className={clsx(
                  "app-form-label",
                  styles["type-form-label"],
                  !exportDAT ? styles["form-disabled"] : ""
                )}
              >
                <Toggle
                  className={styles["export-type-toggle"]}
                  checked={exportDAT}
                  onChange={handleToggleExportDAT}
                />
                Export DAT
              </Form.Label>
              <DropdownGeneral
                className={clsx(
                  "dropdown-react-select w-100",
                  styles["export-type-select"]
                )}
                classNameContainer={styles["select-container"]}
                placeholder="Select Delimiter"
                isSearchable={false}
                isDisabled={!exportDAT}
                value={getValueReactSelect(
                  delimiter,
                  DELIMITER_OPTION_DEFAULT,
                  DELIMITER_SELECT_OPTION
                )}
                options={DELIMITER_SELECT_OPTION}
                onChange={(event) =>
                  handleChangeParameterConfig("delimiter", event)
                }
              />
            </Form.Group>
            <Form.Group
              className={clsx("app-form-group", styles["type-form-group"])}
            >
              <Form.Label
                className={clsx(
                  "app-form-label",
                  styles["type-form-label"],
                  !exportNativeFile ? styles["form-disabled"] : ""
                )}
              >
                <Toggle
                  className={styles["export-type-toggle"]}
                  checked={exportNativeFile}
                  onChange={handleToggleNativeFile}
                />
                Export Native File(s)
              </Form.Label>
            </Form.Group>
          </div>
          <div className={styles["export-type-form"]}>
            <Form.Group
              className={clsx("app-form-group", styles["type-form-group"])}
            >
              <Form.Label
                className={clsx(
                  "app-form-label",
                  styles["type-form-label"],
                  !scheduleExport ? styles["form-disabled"] : ""
                )}
              >
                <Toggle
                  className={styles["export-type-toggle"]}
                  checked={scheduleExport}
                  onChange={handleToggleScheduleExport}
                />
                Schedule Export
              </Form.Label>
              <div className={styles["schedule-export"]}>
                <Form.Control
                  type="text"
                  className={clsx(
                    "app-form-input",
                    styles["type-form-control"],
                    errorMsgDate ? "error" : ""
                  )}
                  disabled={!scheduleExport}
                  value={formatDateTime({
                    dateTime: scheduleTime || currentTime,
                    type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
                  })}
                />
                {errorMsgDate && (
                  <Form.Text
                    className={clsx(styles["form-error"], "error-text")}
                  >
                    {errorMsgDate}
                  </Form.Text>
                )}
                <img
                  src={`/images/icons/calendar-${
                    !scheduleExport ? "disable" : "blue"
                  }.svg`}
                  alt="calender-icon"
                  className={clsx(
                    styles["calender-icon"],
                    !scheduleExport ? styles["calender-icon-disabled"] : ""
                  )}
                  onClick={openDatePicker}
                />
                {showDatePicker && (
                  <OnlyDateSelector
                    initDate={formatDateTime({
                      dateTime: scheduleTime || currentTime,
                      type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
                    })}
                    showDatePicker={showDatePicker}
                    handleClose={() => setShowDatePicker(false)}
                    handleSubmit={handleDateSelected}
                    disableType="before"
                    errorMsg={errorMsgDate}
                  />
                )}
              </div>
            </Form.Group>
            <Form.Group
              className={clsx("app-form-group", styles["type-form-group"])}
            >
              <Form.Label
                className={clsx(
                  "app-form-label",
                  styles["type-form-label"],
                  !exportRSMF ? styles["form-disabled"] : ""
                )}
              >
                <Toggle
                  className={styles["export-type-toggle"]}
                  checked={exportRSMF}
                  onChange={handleToggleExportRSMF}
                />
                Export RSMF
              </Form.Label>
              <DropdownGeneral
                className={clsx(
                  "dropdown-react-select w-100",
                  styles["export-type-select"]
                )}
                classNameContainer={styles["select-container"]}
                isSearchable={false}
                placeholder="24 Hours Size"
                isDisabled={!exportRSMF}
                value={getValueReactSelect(
                  rsmfDocumentSize,
                  DOCUMENT_SIZE_DEFAULT,
                  DOCUMENT_SIZE_OPTION
                )}
                options={DOCUMENT_SIZE_OPTION}
                onChange={(event) =>
                  handleChangeParameterConfig("rsmfDocumentSize", event)
                }
              />
            </Form.Group>
            <Form.Group
              className={clsx("app-form-group", styles["type-form-group"])}
              hidden
            >
              <Form.Label
                className={clsx(
                  "app-form-label",
                  styles["type-form-label"],
                  !exportEML ? styles["form-disabled"] : ""
                )}
              >
                <Toggle
                  className={styles["export-type-toggle"]}
                  checked={exportEML}
                  onChange={handleToggleExportEML}
                />
                Export EML
              </Form.Label>
              <DropdownGeneral
                className={clsx(
                  "dropdown-react-select w-100",
                  styles["export-type-select"]
                )}
                classNameContainer={styles["select-container"]}
                isSearchable={false}
                placeholder="24 Hours Size"
                isDisabled={!exportEML}
                value={getValueReactSelect(
                  emlDocumentSize,
                  DOCUMENT_SIZE_DEFAULT,
                  DOCUMENT_SIZE_OPTION
                )}
                options={DOCUMENT_SIZE_OPTION}
                onChange={(event) =>
                  handleChangeParameterConfig("emlDocumentSize", event)
                }
              />
            </Form.Group>
          </div>
        </div>

        <div className={styles["export-title-header"]}>
          <h4>Time Format Setting</h4>
          <p className={styles["header-line"]}></p>
        </div>
        <div className={styles["time-format-setting"]}>
          <Form.Group className="app-form-group w-100 mar_r30">
            <Form.Label className="app-form-label">
              Date Format
              <span className={styles["app-form-required"]}> *</span>
            </Form.Label>
            <DropdownGeneral
              className={clsx(
                "dropdown-react-select w-100",
                styles["export-setting-select"]
              )}
              isSearchable={false}
              value={getValueReactSelect(
                dateFormat,
                DATE_FORMAT_DEFAULT,
                DATE_FORMAT_SETTING
              )}
              onChange={(event) =>
                handleChangeParameterConfig("dateFormat", event)
              }
              options={DATE_FORMAT_SETTING}
            />
          </Form.Group>
          <Form.Group className="app-form-group w-100">
            <Form.Label className="app-form-label">
              Time Format
              <span className={styles["app-form-required"]}> *</span>
            </Form.Label>
            <DropdownGeneral
              className={clsx(
                "dropdown-react-select w-100",
                styles["export-setting-select"]
              )}
              isSearchable={false}
              value={getValueReactSelect(
                timeZone,
                TIME_FORMAT_DEFAULT,
                TIME_FORMAT_SETTING
              )}
              options={TIME_FORMAT_SETTING}
              onChange={(event) =>
                handleChangeParameterConfig("timeZone", event)
              }
            />
          </Form.Group>
        </div>

        <div className={styles["export-detail-footer"]}>
          <Button name="Reset" handleClick={handleResetFormExport} />
          <div className={styles["save-button-group"]}>
            <span
              onMouseEnter={() => executeExport(EXPORT_ACTION.saveDraft)}
              onMouseLeave={() => executeExport(EXPORT_ACTION.reset)}
            >
              <Button
                name="Save as Draft"
                handleClick={handleSaveAsDraft}
              />
            </span>
            <Button
              name="Save Export"
              handleClick={() => executeExport(EXPORT_ACTION.save)}
            />
            <Button
              name="Save & Run Export"
              className="btn-primary-fill"
              handleClick={() => executeExport(EXPORT_ACTION.saveAndRun)}
            />
          </div>
        </div>
      </Form>
      <Loading loading={exportLoading} isBlur />
      <PopupConfirm
        isShow={showPopup}
        handleClose={() => setShowPopup(false)}
        handleSubmit={handleSubmitPopup}
        content={renderContentPopupAction()}
        textConfirm={COMMON_TEXT.yes}
        textReject={COMMON_TEXT.cancel}
        handleReject={() => setShowPopup(false)}
        isBackdropStatic={true}
      />
    </>
  );
};

export default ExportDetail;
