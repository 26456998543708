import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './AIPromptSuggestion.module.scss';

const AIPromptSuggestion = ({
    onSelect,
    text,
}) => {
    return (
        <div className={styles["suggestion-wrap"]} onClick={(e) => onSelect(e, text)}>
            {text}
        </div>
    );
};

AIPromptSuggestion.propTypes = {
    onSelect: PropTypes.func,
    text: PropTypes.string,
};

export default AIPromptSuggestion;