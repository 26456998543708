import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { clone, camelCase, get } from "lodash";
import clsx from "clsx";

// Components
import InformationContainer from "components/intelligent-search/search-result/information/InformationContainer";
import PreviewResultContainer from "components/intelligent-search/search-result/preview/PreviewResultContainer";
import { SearchResult } from "components/intelligent-search/search-result/result/SearchResult";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import PrintExport from "components/shared/print-export/PrintExport";
import QuickFilter from "components/shared/quick-filter/QuickFilter";

// constants
import {
  PAGINATION,
  PRINT_SCREEN_NAME,
  QUICK_SELECT_ITEM,
  TYPE_CLICK,
  PAGE_NAME,
  PATH_NAME,
  DROPDOWN_LABEL
} from "constants/Common";
import { CHAT_EMAIL_TYPE, DATA_TYPE } from "constants/DataType";
import { LOCAL_STORAGE } from "constants/LocalStorage";
import { breadCrumbSearchResult } from "constants/BreadCrumbConstants";
import { REGEX_ONLY_NUMERIC } from "constants/RegexConstant";

// Services
import {
  getISSearchResultApi,
  getISSearchResultDetailApi,
  getQueryTypes,
  getManageSearchDetailApi,
  exportISResultToExcel,
  exportGroupMessageToExcel,
  exportManageSearchToExcel,
} from "services";
import { handleAbortRequest } from "services/ApiConfig";
import { getGroupMsgInfo } from "services/GeneralReviewService";
import {
  exportEmailDetailToExcelApi,
  getEmailReviewDetailApi,
} from "services/EmailReviewService";
import { getSearchHits } from "services/SearchService";

// Store
import {
  resetQuickSelectParam,
  setGeneralInfoPreview,
  setIsData,
  setOriginalISData,
  setPaging,
  setParamSearch,
  setQuickSelect,
  resetGeneralInfoPreview,
  setIdDoc,
  setPageByDocId,
  setGeneralReviews,
  resetStoreSearchResult,
  setISResultMessageCount,
  setISFilteredTags,
  setISNegateTagQuery,
  setSelectAllUntagged
} from "store/ISReducer";
import { resetDateTimeMess, updateDateTime, updateDateTimeMessageViewPicker } from "store/DatePickerReducer";
import { setFilteredTags, setSearchError } from "store/GeneralReviewReducer";
import { fetchTagsList } from "store/TagReducer";
import { fetchDataSourceList } from "store/DataSourceReducer";
import { setIsMatterDisable, setIsGoToOrigin } from "store/CommonReducer";
import { setSearchContext as setMassTagSearchContext, setSearchContextQuickFilter, setMassTagSelectAllUntagged } from "store/MassTagReducer";
import { getCurrentTime } from "helpers/DateTimeFormatterHelper";

// Helpers
import {
  handleTypeFilter,
} from "helpers/DateTimeFormatterHelper";
import { onConvertMessages } from "helpers/ConversationHelper";
import {
  nextRecord,
  onSwitchRecord,
  previousRecord,
} from "helpers/PagingHelper";
import { isReadValue } from "helpers/ObjectHelper";
import {
  convertContact,
  convertFilter,
  convertTag,
  convertTypeToObject,
  getFilterValue,
  getFilterLabel,
  setPathToLocalStorage,
  formatListDataSource,
} from "helpers/CommonHelper";
import { convertDataSourceInfo } from "helpers/DataSourceHelper";
import { injectLinkToHtml } from "helpers/TextHelper";
import { getEmailWordClouds } from "helpers/EmailReviewHelper";
import { mapSearchError } from "helpers/SearchErrorHelper"

// Styles
import styles from "./IntelligentSearchResultPage.module.scss";

const IntelligentSearchResultPage = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef();
  const searchInputRef = useRef();

  const { projectId, searchResultId } = useParams();

  const pageSize = PAGINATION.recordPerPageTable;
  const pageNumberDefault = PAGINATION.pageNumberDefault;
  const [isResultLoading, setIsResultLoading] = useState(false);
  const [reviewType, setReviewType] = useState("");
  const [filterLoading, setFilterLoading] = useState(false);
  const [isInfoLoading, setIsInfoLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [isSwitchChat, setIsSwitchChat] = useState(false);

  const [totalSearchCount, setTotalSearchCount] = useState(0);
  const [totalMessageContainKeyWord, setTotalMessageContainKeyWord] =
    useState(0);
  const [listPageNumber, setListPageNumber] = useState([]);
  const [currentID, setCurrentID] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [allFirstLoadMessages, setAllFirstLoadMessages] = useState([]);
  const [isApplyQuickSelect, setIsApplyQuickSelect] = useState(false);
  const [isDateTimeFilter, setIsDateTimeFilter] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [quickFilterData, setQuickFilterData] = useState([]);
  const filterPreviewInit = {
    End: null,
    Search: null,
    TagFilter: [],
    IsNewest: false,
    PageNumber: pageNumberDefault,
    Start: null,
    includeBlankDate: false,
  };
  const [filterPreview, setFilterPreview] = useState(filterPreviewInit);
  const [exportLoading, setExportLoading] = useState(false);
  const [messageInfo, setMessageInfo] = useState({
    emojis: [],
    participants: [],
    wordClouds: [],
  });

  const [isDisablePrevious, setIsDisablePrevious] = useState(false);
  const [isDisableNext, setIsDisableNext] = useState(false);

  // Get tags from store
  const { selectTags, showTags } = useSelector((state) => state.tag.tags);

  // Intelligent search result data
  const { 
    idDoc,
    pageByDocId,
    isResultData,
    iSFilteredTags,
    iSNegateTagQuery,
    selectAllUntagged
  } = useSelector((state) => state.is);
  const isGoToOrigin = useSelector((state) => state.common.isGoToOrigin);
  const dataSourceList = useSelector(
    (state) => state.dataSource.dataSourceList
  );

  const {
    paging,
    paramSearch = {},
    generalReviews,
    generalInfoPreview = {},
    quickSelect: dataQueriesType,
  } = isResultData;

  // Data filter date
  const { datePicker: dateFilterData, messageViewDatePicker: dateFilterMsg } =
    useSelector((state) => state.datePicker);

  const { searchContext } = useSelector((state) => state.massTag);

  //Get intelligent search path from localStorage
  const intelligentSearchPath = localStorage.getItem(
    LOCAL_STORAGE.intelligentSearchPath
  );
  //Get previous path from localStorage
  const previousPath = localStorage.getItem(LOCAL_STORAGE.previousPath);

  // Data query from localStorage
  const localStorageRequest = JSON.parse(
    localStorage.getItem(`${LOCAL_STORAGE.searchData}${projectId}`)
  );

  const [paramFilter, setParamFilter] = useState(
    isGoToOrigin ? paramSearch : localStorageRequest ?? []
  );

  const dataQuery = localStorage.getItem(`${LOCAL_STORAGE.dataQuery}${projectId}`) || "";

  const pageTotal = Math.ceil(
    paging.totalRecords / PAGINATION.recordPerPageTable
  );
    
  const PRINT_PAGE_NAME =
    PAGE_NAME[camelCase(state?.fromScreen)] || PAGE_NAME.intelligentSearch;

  // Fetch data display quick select
  const fetchQueryTypes = async () => {
    setFilterLoading(true);
    try {
      const { data } = await getQueryTypes(projectId);
      dispatch(
        setQuickSelect({
          dataSources: get(data, "custodianSources", []),
          dataTypes: get(data, "dataTypes", []),
          entities: get(data, "entities", []),
          participants: get(data, "participants", [])
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setFilterLoading(false);
    }
  };

  const getParamInputSearch = (searchOld, searchNew) => {
    let searchInput = "";
    if (searchOld && searchOld !== "") {
      searchInput = searchNew ? `(${searchOld.trim()}) AND ${searchNew}` : searchOld.trim();
    } else searchInput = searchNew || "";
    return searchInput?.trim() || "";
  };

  // Fetch result intelligent search list
  const fetchISSearchResult = async (body) => {
    setIsResultLoading(true);
    const bodyData = clone(body);
    delete bodyData.searchMsgInput;
    delete bodyData.queryType;
    let dataResult = {};
    try {
      if (searchResultId) {
        const { data } = await getManageSearchDetailApi(
          projectId,
          searchResultId,
          {
            ...bodyData,
            searchInput: getParamInputSearch(
              dataQuery?.replace(/[()]/g, ""),
              bodyData?.searchInput || ""
            ),
          }
        );
        dataResult = data?.searchResult;
        if (paramFilter && data && !isGoToOrigin) {
          const {
            startDate,
            endDate,
            timeZone,
            includeBlankDate,
            sources,
            entities,
            dataTypes,
            searchID,
            name,
            type,
          } = data.searchDetails;

          const searchInfo = {
            dataSources: formatListDataSource(
              dataSourceList,
              sources.map((source) => source.dataSourceId.toString())
            ),
            sources,
            entities,
            dataTypes,
            searchID,
            name,
            type,
            // Add hours depend on time zone due to data from api is utc
            startDate: startDate,
            endDate: endDate,
            timeZone,
            includeBlankDate,
            typeFilter: handleTypeFilter(startDate, endDate),
          };
          localStorage.setItem(
            `${LOCAL_STORAGE.searchData}${projectId}`,
            JSON.stringify(searchInfo)
          );
          dispatch(setIsData({ ...searchInfo }));
          dispatch(setOriginalISData({ ...searchInfo }));
        }
      } else {
        const { data } = await getISSearchResultApi(projectId, {
          ...bodyData,
          searchInput: getParamInputSearch(dataQuery, bodyData?.searchInput),
        });
        dataResult = data;
      }
      var reviewItems = get(dataResult, "items", []);
      if (reviewItems.length === 0) {
        handleGeneralReviewError("No results found.");
        return;
      }
      handlePageByDocId(body, reviewItems, dispatch);
      dispatch(setGeneralReviews(reviewItems));
      dispatch(setSearchError(''));
      dispatch(setPaging({ totalRecords: get(dataResult, "totalRecords", 0) }));
    } catch (error) {
      const errorMessage = mapSearchError(error?.response?.data);
      handleGeneralReviewError(errorMessage);
      if (!error.response?.status) return;
    } finally {
      setIsResultLoading(false);
    }
  };

  const handleSelectedTagsChange = (newSelectedTags, negateTagQuery) => {
    handleAbortRequest();
    const paramSearchPreview = {
      ...filterPreview,
      TagFilter: newSelectedTags,
      NegateTagQuery: negateTagQuery,
      PageNumber: pageNumberDefault,
    };

    setIsPreviewLoading(true);
    fetchGroupMessage(currentID, false, paramSearchPreview);

    dispatch(setISFilteredTags(newSelectedTags));
    dispatch(setISNegateTagQuery(negateTagQuery));
  };

  const handlePageByDocId = (body, reviewItems, dispatch) => {
    if (body.pageByDocId) {
      const pageNumber = Number(reviewItems[0]?.page);
      
      if (!isNaN(pageNumber)) {
        dispatch(setPaging({ pageIndex: pageNumber - 1 }));
        dispatch(setIdDoc(body.pageByDocId));
        dispatch(setPageByDocId(true));
      } else {
        console.error("Invalid page number:", pageNumber);
      }
    }
  };  

  const handleGeneralReviewError = (error) => {
    dispatch(setSearchError(error))
    dispatch(setGeneralReviews([]));
    dispatch(resetGeneralInfoPreview());
    dispatch(setPaging({ totalRecords: 0 }));
  }

  const onClickWord = (word) => {
    handleAbortRequest();
    setIsPreviewLoading(true);
    dispatch(setParamSearch({ searchMsgInput: word.trim() }));
    const paramSearchPreview = {
      ...filterPreview,
      Search: word.trim(),
      TagFilter: filterPreview.TagFilter ?? iSFilteredTags,
      NegateTagQuery: iSNegateTagQuery,
      PageNumber: pageNumberDefault,
    };
    if ([DATA_TYPE.chatGroup, DATA_TYPE.chat].includes(reviewType))
      fetchGroupMessage(currentID, false, paramSearchPreview);
    else fetchEmailReviewDetail(currentID, paramSearchPreview, false);
  };

  // search email by date
  const handleSearchEmailDetailByDate = async (data) => {
    const dateFilter = {
      ...filterPreview,
      Start: data.dateTimeDataStart || null,
      End: data.dateTimeDataEnd || null,
      includeBlankDate: data.includeBlankDate,
    };
    setFilterPreview(dateFilter);
    await getSearchResultDetail(dateFilter, false);
  };

  //fetch data information of chat
  const fetchInformationChat = async (chatId) => {
    setIsInfoLoading(true);
    try {
      const { data } = await getGroupMsgInfo(projectId, chatId, {});
      if (!data) return;
      setMessageInfo({
        participants: data.participants,
        wordClouds: data.wordCloud,
        emojis: data.emoji,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsInfoLoading(false);
    }
  };

  const fetchGroupMessage = async (chatId, isFetchInfo = true, param = {}) => {
    try {
      if (isFetchInfo) fetchInformationChat(chatId);
      const searchFilter = param.Search ?? paramSearch?.searchMsgInput;
      const tagFilter = param.TagFilter ?? iSFilteredTags;
      const negateTagQueryFilter = param.NegateTagQuery ?? iSNegateTagQuery;
      const updatedFilter = { ...param, projectId, chatId, Search: searchFilter, TagFilter: tagFilter, NegateTagQuery: negateTagQueryFilter };
      SetPageStartValue(updatedFilter);
      const { data } = await getSearchHits(updatedFilter);
      let messages = onConvertMessages(data?.instantMessages, 0);
      const chatCurrent = generalReviews.find((item) => item.id === chatId);
      setAllFirstLoadMessages(messages);
      dispatch(setISResultMessageCount(chatCurrent.itemsCount));
      dispatch(
        setGeneralInfoPreview({
          deletedState: chatCurrent?.deleted,
          id: chatCurrent?.id,
          instantMessages: messages,
          type: chatCurrent?.type,
          chatID: chatCurrent?.id,
          totalMessage: data?.totalMessage,
          source: data?.sources,
        })
      );
      // set total search count when have search input
      if (paramSearch?.searchMsgInput) {
        setTotalSearchCount(data?.searchCount);
        setTotalMessageContainKeyWord(data?.messageCount);
      } else {
        setTotalSearchCount(0);
        setTotalMessageContainKeyWord(0);
      }
      setListPageNumber(data?.pageNumbers);
      setIsPreviewLoading(false);
    } catch (error) {
      dispatch(resetGeneralInfoPreview());
      if (!error.response.status) return;
      setIsPreviewLoading(false);
    } finally {
      setIsSwitchChat(false);
    }
  };

  const fetchEmailReviewDetail = async (
    threadId,
    param,
    isUpdateInfo = true
  ) => {
    if (!threadId) return;
    isUpdateInfo && setIsInfoLoading(true);
    try {
      const { dateTimeDataStart, dateTimeDataEnd } = dateFilterMsg;
      const { data } = await getEmailReviewDetailApi(projectId, threadId, {
        Start: dateTimeDataStart,
        End: dateTimeDataEnd,
        PageNumber: pageNumberDefault,
        ...param,
      });
      dispatch(
        setGeneralInfoPreview({
          id: threadId,
          emails: data.email.map((item) => ({
            ...item,
            body: item.body ?
            injectLinkToHtml(item.body) : "",
          })),
        })
      );
      isUpdateInfo &&
        setMessageInfo({
          participants: data.participants,
          wordClouds: getEmailWordClouds(data),
          emojis: data.emoji,
        });
      setIsPreviewLoading(false);
      setIsInfoLoading(false);
    } catch (err) {
      dispatch(resetGeneralInfoPreview());
      if (!err.response?.status) return;
      setIsPreviewLoading(false);
      setIsInfoLoading(false);
    }
  };

  const fetchSearchResultDetail = async (type, threadId, param) => {
    try {
      const response = await getISSearchResultDetailApi(
        camelCase(type),
        projectId,
        threadId,
        param
      );
      dispatch(setGeneralInfoPreview(response.data));
      setIsPreviewLoading(false);
      setIsInfoLoading(false);
    } catch (error) {
      dispatch(resetGeneralInfoPreview());
      if (!error.response.status) return;
      setIsPreviewLoading(false);
      setIsInfoLoading(false);
    } finally {
      setIsSwitchChat(false);
    }
  };

  // Get search result detail and dispatch to store
  const getSearchResultDetail = (param = {}, isFetchInfo = true) => {
    if (!currentID) return;
    if (CHAT_EMAIL_TYPE.includes(reviewType)) setIsInfoLoading(true);
    setIsSwitchChat(true);
    setIsPreviewLoading(true);
    if ([DATA_TYPE.chat, DATA_TYPE.chatGroup].includes(reviewType))
      fetchGroupMessage(currentID, isFetchInfo, param);
    else if ([DATA_TYPE.email, DATA_TYPE.emailThread].includes(reviewType))
      fetchEmailReviewDetail(currentID, param);
    else fetchSearchResultDetail(reviewType, currentID, param);
  };

  // Handle paging
  const onClickPagingHandle = (event) => {
    handleAbortRequest();

    var bodyData;
    if (event.label && event.label === DROPDOWN_LABEL.docId) {
      const updatedDocId = appendWithDocIfNecessary(event.selected);

      bodyData = {
        ...paramSearch,
        pageByDocId: updatedDocId,
      };

      dispatch(setPaging({ pageIndex: 1, recordNumber: 0 }));
    } else {
      bodyData = {
        ...paramSearch,
        paginationParams: {
          ...paramSearch.paginationParams,
          pageNumber: event.selected + 1,
        },
        pageByDocId: null
      };

      dispatch(setPaging({ pageIndex: event.selected, recordNumber: 0 }));
    }

    dispatch(setParamSearch(bodyData));
  };

  const setCurrentRecordByDocId = (items, docId) => {
    items.forEach((item, index) => {
      if (item.docID === docId)
        onClickSearchRecord({ ...item, index});
    });
  }

  const SetPageStartValue = (param) => {
    const messages = generalInfoPreview?.instantMessages;
  
    param.PageStartValue = messages?.length && param.PageNumber > 1
      ? messages.at(-1)?.order + 1 
      : 1;
  
    param.PageNumber = 1;
  }; 

  const appendWithDocIfNecessary = (docId) => {
    const isNumeric = REGEX_ONLY_NUMERIC.test(docId);
    if (isNumeric && !docId.startsWith("DOC_")) {
      return `DOC_${docId}`;
    }

    return docId;
  };  

  // Make filter request data from quick filter
  const getDataFilter = (data, isReset = false) => {
    const {
      entities = [],
      dataSources = [],
      dataTypes = [],
      participants = [],
      tags = []
    } = data;
    const isSelectAllParticipant =
      participants.length === dataQueriesType.participants.length;

    return {
      ...paramSearch,
      dataSources: dataSources.map((item) => ({
        custodianName: item.label,
        dataSourceId: item.value,
        dataSourceType: item.sourceType,
      })),
      dataTypes: getFilterValue(dataTypes),
      entities: getFilterValue(entities),
      participants: isSelectAllParticipant
        ? []
        : getFilterValue(participants),
      tags: getFilterValue(tags),
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: pageNumberDefault,
      },
      isApplyQuickSelect: true,
      isDateTimeFilter,
      searchInput: null,
      searchMsgInput: null,
      selectAllUntagged: isReset ? false : selectAllUntagged
    };
  };

  // Handle filter data from quick select
  const applyQS = useCallback((data) => {
    // Clear searchInputRef when input search box
    if (searchInputRef.current) searchInputRef.current.clearText();
    handleAbortRequest();
    setCurrentID(null);
    setIsApplyQuickSelect(true);
    fetchISSearchResult(getDataFilter(data));
    dispatch(setParamSearch(getDataFilter(data)));
    dispatch(setPaging({ pageIndex: 0, recordNumber: 0 }));
    setFilterPreview(filterPreviewInit);
    dispatch(resetDateTimeMess());
  });

  const goBack = () => {
    dispatch(resetStoreSearchResult()); //clear search result store
    previousPath
      ? navigate(`/${PATH_NAME.matters}/${projectId}/${previousPath}`)
      : navigate(-1);
  };

  // Handle breadcrumb data
  const getBreadCrumb = () => {
    if (previousPath)
      return breadCrumbSearchResult(
        previousPath,
        searchResultId,
        intelligentSearchPath
      );
  };

  // Handle search data by keyword search
  const onSubmitSearch = () => {
    setCurrentID(null);
    const { dateTimeDataStart, dateTimeDataEnd } = dateFilterData;
    const inputValue = inputRef?.current?.value?.trim();
    fetchISSearchResult({
      ...paramSearch,
      searchInput: inputValue,
      start: dateTimeDataStart || null,
      end: dateTimeDataEnd || null,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: pageNumberDefault,
        pageSize,
      },
      isApplyQuickSelect,
      isDateTimeFilter,
      selectAllUntagged,
    });
    dispatch(
      setParamSearch({
        searchInput: inputValue,
        searchMsgInput: inputValue,
        isSecondSearch: false,
        paginationParams: {
          ...paramSearch.paginationParams,
          pageNumber: pageNumberDefault,
          pageSize,
        },
      })
    );
    dispatch(setPaging({ recordNumber: 0, pageIndex: 0 }));
  };

  // Handle search result table
  const onSearchByDate = (data) => {
    const { dateTimeDataStart, dateTimeDataEnd, includeBlankDate, timeZone } = data;
    dispatch(setPaging({ recordNumber: 0, pageIndex: 0 }));
    const dataSearch = {
      ...paramSearch,
      start: dateTimeDataStart || null,
      end: dateTimeDataEnd || null,
      includeBlankDate,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: pageNumberDefault,
        pageSize,
      },
      isApplyQuickSelect,
      isDateTimeFilter: true,
      selectAllUntagged,
    };
    setIsDateTimeFilter(true);
    fetchISSearchResult(dataSearch);
    getSearchResultDetail({
      ...filterPreview,
      TagFilter: iSFilteredTags,
      NegateTagQuery: iSNegateTagQuery,
      PageNumber: pageNumberDefault,
      Start: dateTimeDataStart,
      End: dateTimeDataEnd,
      includeBlankDate: dateFilterData.includeBlankDate
    });
    dispatch(updateDateTime({ dateTimeDataStart, dateTimeDataEnd, timeZone, includeBlankDate }));
    dispatch(setParamSearch(dataSearch));
  };

  // Handle search result table
  const onSortTable = (data) => {
    handleAbortRequest();
    const dataRequest = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        columnSort: data.columnSort,
        orderBy: data.orderBy,
        pageSize,
      },
      isApplyQuickSelect,
      isDateTimeFilter,
      selectAllUntagged,
    };
    fetchISSearchResult(dataRequest);
    dispatch(setParamSearch(dataRequest));
  };

  // Handle paging table result
  const onHandlePagingTable = (type) => {
    const { recordNumber = 0, totalRecords = 0, pageIndex = 0 } = paging;
    handleAbortRequest();
    dispatch(resetGeneralInfoPreview());
    let item = null;
    if (type === TYPE_CLICK.next && nextRecord(recordNumber) < pageSize) {
      item = generalReviews[nextRecord(recordNumber)];
      dispatch(setIdDoc(item.docID));
    } else {
      item = generalReviews[previousRecord(recordNumber)];
      if (recordNumber > 0) dispatch(setIdDoc(item.docID));
    }
    if (item) {
      setCurrentID(item.id);
      setReviewType(item.type);
    }
    dispatch(
      setPaging(
        onSwitchRecord(
          type,
          pageIndex,
          recordNumber,
          generalReviews.length,
          totalRecords
        )
      )
    );
  };

  // Get search result detail
  const onClickSearchRecord = (data) => {
    if (!data) return;
    if (!isPreviewLoading && currentID !== data.id) {
      dispatch(setIdDoc(data.docID));
      dispatch(resetGeneralInfoPreview());
      setCurrentID(data.id);
      setReviewType(data.type);
      dispatch(setPaging({ recordNumber: data.index }));
    }
  };

  // Handle dispatch param search to search keyword
  const onSetParamSearchStore = (keyword) => {
    dispatch(setParamSearch(keyword ? { searchMsgInput: keyword } : {}));
  };

  const setSearchMsgInputStore = (keyword, isSecondSearch) => {
    if (isSecondSearch)
      dispatch(setParamSearch({ isSecondSearch, searchMsgInput: keyword }));
    else dispatch(setParamSearch({ searchMsgInput: keyword }));
  };

  const onSetParamIsNewestStore = (isNewest) => {
    dispatch(setParamSearch({ isNewest }));
  };

  const setInstantMessagesStore = (lstMessages, totalMessage = -1) => {
    dispatch(
      setGeneralInfoPreview({
        ...generalInfoPreview,
        instantMessages: [...lstMessages],
        totalMessage:
          totalMessage === -1 ? generalInfoPreview?.totalMessage : totalMessage,
      })
    );
  };

  const resetQuickSelect = (data) => {
    // Clear searchInputRef when input search box
    if (searchInputRef.current) searchInputRef.current.clearText();
    handleAbortRequest();
    setCurrentID(null);
    fetchISSearchResult(getDataFilter(data, true));
    dispatch(resetQuickSelectParam());
    dispatch(setParamSearch(getDataFilter(data, true)));
    dispatch(setPaging({ pageIndex: 0, recordNumber: 0 }));
    dispatch(setMassTagSelectAllUntagged(false));
    setIsApplyQuickSelect(true);
    setFilterPreview(filterPreviewInit);
    dispatch(resetDateTimeMess());
  };

  const downloadFile = (blobData, fileName) => {
    const url = window.URL.createObjectURL(blobData);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const exportResultsToExcel = async () => {
    try {
      setExportLoading(true);
      const body = { ...paramSearch };
      delete body.queryType;
      let resultData;
      if (searchResultId) {
        const result = await exportManageSearchToExcel(
          projectId,
          searchResultId,
          {
            ...body,
            searchInput: body?.searchInput || "",
            pageName: PRINT_PAGE_NAME,
          }
        );
        resultData = result.data;
      } else {
        const result = await exportISResultToExcel(projectId, {
          ...body,
          searchInput: getParamInputSearch(dataQuery, body?.searchInput),
          pageName: PRINT_PAGE_NAME,
        });
        resultData = result.data;
      }
      downloadFile(resultData, `StreemView_IntelligentSearch_${getCurrentTime()}.xlsx`);
    } catch (err) {
      console.log(err);
    } finally {
      setExportLoading(false);
    }
  };

  const exportMessageToExcel = async () => {
    setExportLoading(true);
    const { dateTimeDataStart, dateTimeDataEnd, includeBlankDate } =
      dateFilterMsg;
    try {
      const params = {
        Start: dateTimeDataStart,
        End: dateTimeDataEnd,
        PageNumber: pageNumberDefault,
        includeBlankDate,
        isNewest: paramSearch?.isNewest,
        chatID: generalInfoPreview?.chatID,
        pageName: PRINT_PAGE_NAME,
        Search: filterPreview.Search,
      };
      const result = await exportGroupMessageToExcel(
        projectId,
        currentID,
        params
      );
      downloadFile(result.data, `StreemView_Message_${getCurrentTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const exportEmailDetailToExcel = async () => {
    setExportLoading(true);
    try {
      const { dateTimeDataStart, dateTimeDataEnd, includeBlankDate } =
        dateFilterMsg;
      const params = {
        Start: dateTimeDataStart,
        End: dateTimeDataEnd,
        includeBlankDate,
        PageNumber: pageNumberDefault,
        isNewest: paramSearch?.isNewest,
        pageName: PRINT_PAGE_NAME,
        SearchInput: filterPreview.Search,
      };
      const result = await exportEmailDetailToExcelApi(
        projectId,
        currentID,
        params
      );
      downloadFile(result.data, `StreemView_Email_${getCurrentTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const getFunctionExport = () => {
    const isChatType = [DATA_TYPE.chat, DATA_TYPE.chatGroup].includes(reviewType);
    const isChatOrEmailType = CHAT_EMAIL_TYPE.includes(reviewType);
    const exportFunctions = [];

    if (isChatOrEmailType) {
      exportFunctions.push({
        exportName: "Export Threads",
        onclick: exportResultsToExcel,
      });

      if (isChatType) {
        exportFunctions.push({
          exportName: "Export Message View",
          onclick: exportMessageToExcel,
        });
      } else {
        exportFunctions.push({
          exportName: "Export Email Details",
          onclick: exportEmailDetailToExcel,
        });
      }
    }

    return exportFunctions;
  };

  const fetchSearchHitsApi = async (_, chatId, filter = {}) => {
    setFilterPreview(filter);
    const searchFilter = filter.Search ?? paramSearch?.searchMsgInput
    const tagFilter = filter.TagFilter ?? iSFilteredTags;
    const negateTagQueryFilter = filter.NegateTagQuery ?? iSNegateTagQuery;
    const updatedFilter = { ...filter, projectId, chatId, Search: searchFilter, TagFilter: tagFilter, NegateTagQuery: negateTagQueryFilter };
    SetPageStartValue(updatedFilter);
    return await getSearchHits(updatedFilter);
  };

  const handleToggle = () => {
    dispatch(setPaging({ pageIndex: 0, recordNumber: 0 }));
    const dataRequest = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: pageNumberDefault,
        pageSize,
      },
      isShowRecents: !paramSearch.isShowRecents,
      selectAllUntagged,
    };
    fetchISSearchResult(dataRequest);
    dispatch(setParamSearch(dataRequest));
  };

  const getDataSourceInfo = () => {
    const listDataSourceId =
      generalReviews[paging.recordNumber]?.dataSourceID?.split(/,\s*/) || [];
    return convertDataSourceInfo(dataSourceList, listDataSourceId);
  };

  const filterData = {
    loading: isInfoLoading || isResultLoading,
    previewLoading: isPreviewLoading,
    dataSourceInfo: { getDataSourceInfo },
    participantInfo: {
      participants: messageInfo.participants,
      isRedColor: CHAT_EMAIL_TYPE.includes(reviewType),
    },
    tagProps: {
      selectTags,
      handleSelectedTagsChange,
    },
    wordCloudProps: {
      listConversation: generalReviews,
      data: messageInfo.wordClouds,
      onClick: onClickWord,
    },
    emojiCloudProps: {
      listConversation: generalReviews,
      data: messageInfo.emojis,
      onClick: onClickWord,
    }
  };

  const recordControls = {
    disablePrevious: isDisablePrevious,
    disableNext: isDisableNext,
    handleRecordChange: (data) => onHandlePagingTable(data),
  };

  useEffect(() => {
    if (currentID && reviewType) {
      setIsFirstLoad(false);
      getSearchResultDetail({
        ...filterPreview,
        TagFilter: iSFilteredTags,
        NegateTagQuery: iSNegateTagQuery,
        PageNumber: pageNumberDefault,
        Start: dateFilterData.dateTimeDataStart,
        End: dateFilterData.dateTimeDataEnd,
        includeBlankDate: dateFilterData.includeBlankDate
      });
      dispatch(updateDateTimeMessageViewPicker(dateFilterData));
    } else dispatch(resetGeneralInfoPreview());
  }, [currentID]);

  useEffect(() => {
    selectTags && dispatch(setQuickSelect({ tags: selectTags || [] }));
  }, [selectTags]);

  useEffect(() => {
    if (isGoToOrigin) return;
    const updateParams = {
      entities: localStorageRequest?.entities,
      dataSources:
        localStorageRequest?.dataSources?.length > 0
          ? localStorageRequest.dataSources
          : formatListDataSource(
              dataSourceList,
              localStorageRequest?.sources?.map((source) =>
                source.dataSourceId.toString()
              )
            ),
    };
    setParamFilter({ ...paramFilter, ...updateParams });
    dispatch(setParamSearch({ ...paramSearch, ...updateParams }));
  }, [
    JSON.stringify(localStorageRequest?.entities),
    JSON.stringify(localStorageRequest?.sources),
  ]);

  // Fetch data display quick select and cache search result store if back to item detail
  useEffect(() => {
    // set previous path when first load page
    setPathToLocalStorage(state?.fromScreen);
    setFirstLoad(false);
    if (
      isGoToOrigin &&
      generalReviews?.length > 0
    ) {
      setFilterLoading(false);
      dispatch(setIsGoToOrigin(false));
      dispatch(setIsMatterDisable(true));
      return;
    } else {
      if (!paramFilter) return;
      const {
        startDate,
        endDate,
        timeZone,
        typeFilter,
        includeBlankDate,
        entities,
      } = paramFilter;

      let isIncludeBlankDate = true;
      if (isReadValue(includeBlankDate)) isIncludeBlankDate = includeBlankDate;
      const dataFromIntelligent = {
        ...paramFilter,
        entities: entities?.map((item) => item?.id || item) || [],
        start: startDate,
        end: endDate,
        timeZone: timeZone,
        includeBlankDate: isIncludeBlankDate,
      };

      let paginationParams = { 
        pageNumber: pageNumberDefault, 
        pageSize, 
        paginationParams: paramSearch.paginationParams 
      };
      const dataSearchFirstLoad = {
        ...dataFromIntelligent,
        paginationParams,
        includeBlankDate: isIncludeBlankDate,
        isApplyQuickSelect,
        isDateTimeFilter,
        isShowRecents: paramSearch.isShowRecents,
        selectAllUntagged: false,
      };

      fetchISSearchResult(dataSearchFirstLoad);
      setParamFilter(dataSearchFirstLoad);
      dispatch(setParamSearch(dataSearchFirstLoad));
      dispatch(
        updateDateTime({
          dateTimeDataStart: dataFromIntelligent.start,
          dateTimeDataEnd: dataFromIntelligent.end,
          timeZone: dataFromIntelligent.timeZone,
          typeFilter: typeFilter || handleTypeFilter(startDate, endDate),
          includeBlankDate: dataFromIntelligent.includeBlankDate,
        })
      );
      dispatch(setSelectAllUntagged(false));
    }
    dispatch(setFilteredTags([]));
    dispatch(fetchTagsList({ projectId }));
    dispatch(fetchDataSourceList(projectId));
    fetchQueryTypes();
    dispatch(setIsMatterDisable(true));
    return () => {
      dispatch(setIsMatterDisable(false));
      dispatch(resetDateTimeMess());
    };
  }, []);

  useEffect(() => {
    if (firstLoad) return;
    const dataSearch = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: paging.pageIndex + 1,
        pageSize,
      },
      includeBlankDate: dateFilterData.includeBlankDate,
      isApplyQuickSelect,
      isDateTimeFilter,
      selectAllUntagged,
      pageByDocId: paramSearch.pageByDocId ? paramSearch.pageByDocId : null
    };
    fetchISSearchResult(dataSearch);
    dispatch(
      setParamSearch({
        ...dataSearch,
        searchInput: paramSearch?.searchInput,
      })
    );
  }, [paging.pageIndex]);

  useEffect(() => {
    setFilterPreview({ ...filterPreview, Search: paramSearch.searchMsgInput });
  }, [paramSearch.searchMsgInput]);

  useEffect(() => {
    setFilterPreview({ ...filterPreview, TagFilter: iSFilteredTags });
  }, [iSFilteredTags]);

  useEffect(() => {
    setFilterPreview({...filterPreview, Start: dateFilterMsg.dateTimeDataStart, End: dateFilterMsg.dateTimeDataEnd, includeBlankDate: dateFilterMsg.includeBlankDate});
  }, [dateFilterMsg]);

  // Fetch intelligent search preview detail
  useEffect(() => {
    if (generalReviews.length > 0 && generalReviews[paging?.recordNumber]) {
      dispatch(setIdDoc(generalReviews[paging?.recordNumber]?.docID));
      const item = generalReviews[paging?.recordNumber];
      setCurrentID(item.id);
      setReviewType(item.type);
    } else {
      setCurrentID(null);
      setReviewType(null);
    }
  }, [generalReviews]);

  useEffect(() => {
    !filterLoading && setQuickFilterData([
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.dataSources.value),
          filterType: QUICK_SELECT_ITEM.dataSources.value,
          filterData: {
            dataSelected: convertFilter(
              paramFilter?.dataSources,
              QUICK_SELECT_ITEM.dataSources.value
            ),
            dataUnSelect: convertFilter(
              dataQueriesType.dataSources,
              QUICK_SELECT_ITEM.dataSources.value
            ),
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.dataTypes.value),
          filterType: QUICK_SELECT_ITEM.dataTypes.value,
          filterData: {
            dataSelected: convertTypeToObject(
              dataQueriesType.dataTypes,
              paramFilter?.dataTypes
            ),
            dataUnSelect: dataQueriesType?.dataTypes,
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.entities.value),
          filterType: QUICK_SELECT_ITEM.entities.value,
          filterData: {
            dataSelected: convertFilter(
              convertContact(dataQueriesType.entities, paramFilter?.entities),
              QUICK_SELECT_ITEM.entities.value
            ),
            dataUnSelect: convertFilter(
              dataQueriesType.entities,
              QUICK_SELECT_ITEM.entities.value
            ),
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.identifiers.value),
          filterType: QUICK_SELECT_ITEM.participants.value,
          filterData: {
            dataSelected: convertFilter(
              convertContact(
                dataQueriesType.participants,
                paramFilter?.participants
              ),
              QUICK_SELECT_ITEM.participants.value
            ),
            dataUnSelect: convertFilter(
              dataQueriesType.participants,
              QUICK_SELECT_ITEM.participants.value
            ),
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.tags.value),
          filterType: QUICK_SELECT_ITEM.tags.value,
          filterData: {
            dataSelected: convertFilter(
              convertTag(dataQueriesType.tags, paramFilter?.tags),
              QUICK_SELECT_ITEM.tags.value
            ),
            dataUnSelect: convertFilter(
              dataQueriesType.tags,
              QUICK_SELECT_ITEM.tags.value
            ),
          },
          notSelectedOption: {
            label: "Select Not Tagged",
            onSelectCallback: (isSelected) => {
              dispatch(setSelectAllUntagged(isSelected));
              dispatch(setMassTagSelectAllUntagged(isSelected));
            }
          }
        },
      ]);
      if (filterLoading) {
        dispatch(setMassTagSelectAllUntagged(false));
      }
  }, [filterLoading, dataQueriesType.tags, paramFilter?.entities]);

  useEffect(() => {
    dispatch(setMassTagSearchContext({
      ...searchContext, 
      savedSearchId: searchResultId,
      isShowRecents: false,
    }));
    dispatch(setSearchContextQuickFilter({ 
      ...searchContext.quickFilter,
      intelligentSearchQuery: dataQuery,
      filterSearchQuery: "",
      start: undefined,
      end: undefined,
      includeBlankDate: false,
    }));
    dispatch(setMassTagSelectAllUntagged(false));
  }, [searchResultId]);

  useEffect(() => {
    if (pageByDocId)
      setCurrentRecordByDocId(generalReviews, idDoc);
  }, [generalReviews]);

  useEffect(() => {
    const { pageIndex, recordNumber } = paging;
    
    setIsDisablePrevious(pageIndex === 0 && recordNumber === 0);
    setIsDisableNext(pageIndex === pageTotal - 1 && recordNumber === generalReviews.length - 1);
  }, [paging, generalReviews.length]);

  return (
    <div className={clsx("main", styles["is-wrap"])}>
      <div className={styles["is-head"]}>
        <BreadCrumb goBack={goBack} breadCrumbData={getBreadCrumb()} />
        <div className={styles["is-head-control"]}>
          <div className={styles["is-head-print"]}>
            <PrintExport
              screenName={PRINT_SCREEN_NAME.streems}
              isDisplayTime
              exportOption={getFunctionExport()}
              handleExport={exportResultsToExcel}
              exportLoading={exportLoading}
            />
          </div>
        </div>
      </div>
      <div className={styles["is-body"]}>
        <div className={clsx(styles["is-quick-select"])}>
          <QuickFilter
            data={quickFilterData}
            loading={filterLoading}
            onApply={applyQS}
            onReset={resetQuickSelect}
          />
        </div>
        <div className={styles["is-search-result"]}>
          <SearchResult
            paging={paging}
            loading={isResultLoading}
            isResultsData={generalReviews}
            inputRef={inputRef}
            searchInput={paramSearch?.searchInput}
            onSort={onSortTable}
            onClickPagingHandle={onClickPagingHandle}
            onSwitchRecord={onClickSearchRecord}
            onSubmitSearch={onSubmitSearch}
            onSubmitDate={onSearchByDate}
            handleToggle={handleToggle}
            isShowRecent={paramSearch.isShowRecents}
            searchInputRef={searchInputRef}
            dataSourceList={dataSourceList}
          />
          <div
            className={
              styles["is-result-info-and-preview"]
            }
          >
            <div className={styles["is-result-info"]}>
              <InformationContainer
                loading={isPreviewLoading || isResultLoading}
                data={generalInfoPreview}
                filterData={filterData}
                reviewType={reviewType}
                totalRecords={paging.totalRecords}
                recordControls={recordControls}
                docID={idDoc}
              />
            </div>
            <div className={styles["is-result-preview"]}>
              <PreviewResultContainer
                loading={isPreviewLoading || isResultLoading || isInfoLoading}
                reviewType={reviewType}
                data={generalInfoPreview}
                participants={messageInfo.participants}
                paging={paging}
                totalItemOfPage={generalReviews.length}
                searchMsgInput={paramSearch?.searchMsgInput || ""}
                dateTime={dateFilterMsg}
                totalSearchCount={totalSearchCount}
                tagsList={selectTags}
                showTagsList={showTags}
                totalMessageContainKeyWord={totalMessageContainKeyWord}
                isSwitchChat={isSwitchChat}
                listPageNumber={listPageNumber}
                setParamSearch={onSetParamSearchStore}
                onSetParamIsNewestStore={onSetParamIsNewestStore}
                setTotalSearchCount={setTotalSearchCount}
                setTotalMessageContainKeyWord={setTotalMessageContainKeyWord}
                setListPageNumber={setListPageNumber}
                setSearchMsgInputStore={setSearchMsgInputStore}
                setInstantMessagesStore={setInstantMessagesStore}
                setIsResultLoading={setIsResultLoading}
                handleSearchEmailDetailByDate={handleSearchEmailDetailByDate}
                fetchChatDetailApi={fetchSearchHitsApi}
                allFirstLoadMessages={allFirstLoadMessages}
                titleActivity={PRINT_PAGE_NAME}
                isFirstLoad={isFirstLoad}
                setIsFirstLoad={setIsFirstLoad}
                onClearSearchMsg={() =>
                  dispatch(setParamSearch({ searchMsgInput: null }))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntelligentSearchResultPage;
