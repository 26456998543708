import { createSlice } from "@reduxjs/toolkit";

const attachmentReviewSlice = createSlice({
  name: "attachmentReview",
  initialState: {
    viewerSettings: {
        viewerType: null,
    }
  },
  reducers: {
    setViewerSettings: (state, action) => {
        state.viewerSettings = action.payload;
    }
  },
});

export const {
  setViewerSettings,
} = attachmentReviewSlice.actions;
export const AttachmentReviewReducer = attachmentReviewSlice.reducer;
