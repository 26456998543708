import axios, { authHeader } from "./ApiConfig";

const getAttachmentReviewApi = (attachmentId, projectId, search) =>
  axios.post(
    `/communicationReview/${projectId}/attachment/${attachmentId}`,
    {
      attachmentId,
      projectId,
      search
    },
    {
      headers: authHeader(),
    }
  );

const getSuggestWord = (projectId, data = {}) =>
  axios.post(`/communicationReview/getSuggestWord/${projectId}`, data, {
    headers: authHeader(),
  });

export {
  getAttachmentReviewApi,
  getSuggestWord,
};
