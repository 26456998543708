export const MATTER_TYPES = [
  {
    label: "Litigation",
    options: [
      { value: "GeneralLitigation", label: "General Litigation" },
      { value: "CommercialLitigation", label: "Commercial Litigation" },
      { value: "IpLitigation", label: "Intellectual Property (IP) Litigation" },
      { value: "EmploymentLitigation", label: "Employment Litigation" },
      { value: "ProductLiabilityLitigation", label: "Product Liability Litigation" },
      { value: "AntitrustLitigation", label: "Antitrust Litigation" },
      { value: "SecuritiesLitigation", label: "Securities Litigation" },
      { value: "EnvironmentalLitigation", label: "Environmental Litigation" },
      { value: "ConstructionRealEstateLitigation", label: "Construction and Real Estate Litigation" },
      { value: "InsuranceLitigation", label: "Insurance Litigation" },
      { value: "BankruptcyLitigation", label: "Bankruptcy Litigation" },
    ]
  },
  {
    label: "Investigation",
    options: [
      { value: "GeneralInvestigations", label: "General Investigations" },
      { value: "InternalCorporateInvestigations", label: "Internal Corporate Investigations" },
      { value: "RegulatoryGovernmentInvestigations", label: "Regulatory and Government Investigations" },
      { value: "WhiteCollarInvestigations", label: "White-Collar Crime Investigations" },
      { value: "PrivacyCybersecurityInvestigations", label: "Data Privacy and Cybersecurity Investigations" },
      { value: "HealthcarePharmaInvestigations", label: "Healthcare and Pharmaceutical Investigations" },
      { value: "HrInvestigations", label: "HR Investigations" },
      { value: "EnvironmentalComplianceInvestigations", label: "Environmental Compliance Investigations" },
      { value: "CorporateGovernanceInvestigations", label: "Corporate Governance Investigations" },
    ]
  }
];
