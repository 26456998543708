import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Styles
import styles from "./AIHistoryDropdown.module.scss";

const AIHistoryDropdown = ({
    isDisabled,
    handleChatSelect,
    handleChatDelete,
    chats,
    currentChatId
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);
    const searchInputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
                setSearchTerm("");
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (isDropdownOpen && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isDropdownOpen]);

    const onDelete = (e, chatId) => {
        e.stopPropagation();
        handleChatDelete(chatId);
    };

    const onSelect = (chatId) => {
        handleChatSelect(chatId);
        setIsDropdownOpen(false);
        setSearchTerm("");
    };

    const filteredChats = chats
        .filter(chat => 
            chat.chatName.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (searchTerm) {
                return (b.timestamp || 0) - (a.timestamp || 0);
            }
            
            if (a.chatId === currentChatId) return -1;
            if (b.chatId === currentChatId) return 1;
            
            return (b.timestamp || 0) - (a.timestamp || 0);
        });

    return (
        <div className={styles["ai-history-dropdown"]} ref={dropdownRef}>
            <button
                className={clsx(
                    styles["dropdown-toggle"], 
                    isDisabled && styles["disabled"]
                )}
                onClick={() => !isDisabled && setIsDropdownOpen(!isDropdownOpen)}
                disabled={isDisabled}
            >
                <img src="/images/icons/chat-history.svg" alt="Chat History" />
            </button>
            {isDropdownOpen && (
                <div className={styles["dropdown-menu"]}>
                    <div className={styles["search-container"]}>
                        <input
                            ref={searchInputRef}
                            type="text"
                            className={styles["search-input"]}
                            placeholder="Search chat history..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm && (
                            <button
                                className={styles["clear-search"]}
                                onClick={() => setSearchTerm("")}
                                title="Clear search"
                            >
                                ×
                            </button>
                        )}
                    </div>
                    <div className={styles["dropdown-items"]}>
                        {filteredChats.length > 0 ? (
                            filteredChats.map((chat, index) => (
                                <div 
                                    key={index} 
                                    className={styles["dropdown-item"]}
                                    onClick={() => onSelect(chat.chatId)}
                                >
                                    <span>{chat?.chatName}</span>
                                    <button
                                        className={styles["clear-chat-button"]}
                                        onClick={(e) => onDelete(e, chat.chatId)}
                                        title="Delete chat"
                                    >
                                        ×
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className={styles["no-results"]}>
                                No chats found
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

AIHistoryDropdown.propTypes = {
    isDisabled: PropTypes.bool,
    handleChatSelect: PropTypes.func,
    handleChatDelete: PropTypes.func,
    chats: PropTypes.array,
    currentChatId: PropTypes.string
};

export default AIHistoryDropdown;