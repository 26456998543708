import axios from "axios";
import { authHeader } from "./ApiConfig";

const uploadNativeFileChunk = async (matterId, dataSourceId, filePath, blockId, chunkData, options) => {
    return await axios({
        method: 'put',
        baseURL: `${process.env.REACT_APP_API_URL}/importFile/${matterId}/uploadNativeFileBlock/${dataSourceId}`,
        timeout: 0,
        headers: {
            ...authHeader(),
        },
        params: {
            filePath,
            blockId
        },
        data: chunkData,
        ...options
    });
};

const nativeFileCommit = async (matterId, dataSourceId, filePath, blockIds) => {
    return await axios({
        method: 'post',
        baseURL: `${process.env.REACT_APP_API_URL}/importFile/${matterId}/nativeFileCommit/${dataSourceId}`,
        timeout: 0,
        headers: {
            ...authHeader(),
        },
        params: {
            filePath,
        },
        data: {
            blockIds,
        }
    });
};

const uploadLoadfileBlock = async (matterId, dataSourceId, filePath, blockId, chunkData, options) => {
    return await axios({
        method: 'put',
        baseURL: `${process.env.REACT_APP_API_URL}/importFile/${matterId}/uploadLoadfileBlock/${dataSourceId}`,
        timeout: 0,
        headers: {
            ...authHeader(),
        },
        params: {
            filePath,
            blockId
        },
        data: chunkData,
        ...options
    });
};

const loadfileCommit = async (matterId, dataSourceId, filePath, blockIds, loadFileType, datMap) => {
    return await axios({
        method: 'post',
        baseURL: `${process.env.REACT_APP_API_URL}/importFile/${matterId}/loadfileCommit/${dataSourceId}`,
        timeout: 0,
        headers: {
            ...authHeader(),
        },
        params: {
            filePath,
        },
        data: {
            blockIds,
            loadFileType,
            datMap
        }
    });
};

export {
    uploadNativeFileChunk,
    nativeFileCommit,
    uploadLoadfileBlock,
    loadfileCommit,
};
