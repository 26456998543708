import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DocumentEditorComponent } from '@syncfusion/ej2-react-documenteditor';
import { getDocumentJson } from '../../services/AttachmentService';
import SpinnerLoading from '../shared/spinner-loading/SpinnerLoading';

// Constants
import { ZERO_BYTE_ERROR, NOT_FOUND_ERROR, TOO_LARGE_ERROR, RENDER_ERROR } from 'constants/NativeViewerConstants';

// Styles
import styles from './DocAttachmentRenderer.module.scss';

const DocAttachmentRenderer = (props) => {
    const {
        attachmentId,
        projectId,
    } = props;

    const viewerRef = useRef(null);
    const [viewerInstance, setViewerInstance] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [showCannotRender, setShowCannotRender] = useState(false);
    const [cannotRenderMessage, setCannotRenderMessage] = useState('');
    const [previewJson, setPreviewJson] = useState(null);

    const handleViewerLoaded = (instance) => {
        setViewerInstance(instance);
    };

    const onCreated = () => {
        window.addEventListener('resize', () => {
            if (viewerInstance) {
                viewerInstance.fitPage('FitPageWidth');
            }
        });
    };

    useEffect(() => {
        const fetchPreview = async () => {
            try {
                const response = await getDocumentJson(projectId, attachmentId);
                setPreviewJson(response.data);
            } catch (error) {
                setShowCannotRender(true);
                if (error.response?.status === 400 && error.response?.data?.message?.includes('file is empty')) {
                    setCannotRenderMessage(ZERO_BYTE_ERROR);
                } else if (error.response?.status === 404) {
                    setCannotRenderMessage(NOT_FOUND_ERROR);
                } else if (error.response?.status === 413) {
                    setCannotRenderMessage(TOO_LARGE_ERROR);
                } else {
                    setCannotRenderMessage(RENDER_ERROR);
                }
                setIsLoading(false);
            }
        };

        fetchPreview();
    }, [projectId, attachmentId]);

    useEffect(() => {
        if (previewJson && viewerInstance) {
            viewerInstance.open(previewJson);
            viewerInstance.className = styles['doc-renderer'];
            setIsLoading(false);
            setTimeout(() => {
                viewerInstance.fitPage('FitPageWidth');
            }, 100);
        }
    }, [previewJson, viewerInstance]);

    useEffect(() => {
        return () => {
            if (viewerInstance) {
                setPreviewJson(null);
                viewerInstance.destroy();
            }
        }
    }, []);

    return showCannotRender ? (
        <div className={styles['cannot-render']}>
            <img
                src="/images/image-notfound.png"
                alt="not found"
            />
            <p>{cannotRenderMessage}</p>
        </div>
    ) : (
        <>
            {isLoading &&
                <div className={styles['loading-spinner-container']}>
                    <SpinnerLoading isCenter={false} />
                </div>}
            <div
                className={isLoading
                    ? styles['doc-renderer-hidden']
                    : styles['doc-renderer']}>
                <DocumentEditorComponent
                    id="document-viewer"
                    ref={instance => {
                        viewerRef.current = instance;
                        if (instance) {
                            handleViewerLoaded(instance);
                        }
                    }}
                    height='100%'
                    created={onCreated}
                />
            </div>

        </>
    )
};

DocAttachmentRenderer.propTypes = {
    attachmentId: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DocAttachmentRenderer;
