import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";

// Components
import { toast } from "react-toastify";
import { Button } from "components/shared/button/Button";
import FieldSelect from "components/shared/field/field-select/FieldSelect";
import FieldUpload from "components/shared/field/field-upload/FieldUpload";
import { Query } from '@syncfusion/ej2-data';
import { 
  ColumnsDirective, 
  ColumnDirective, 
  GridComponent, 
  Sort, 
  Edit,
  Search,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";

// Constants & Helpers
import { GENERIC_FIELDS, TEAMS_FIELDS, SLACK_FIELDS, AUTOSTREEM_FIELDS } from "constants/FieldMapConstants";
import { SOURCE_VALIDATION, VALIDATION_TEXT } from "constants/Validations";
import { getFileTypeFromName } from "helpers/GetFileNameHelper";
import { IMPORT_TYPE } from "constants/DataSourceConstant";

// Styles
import styles from "./AddSourceDataModal.module.scss";

const AddSourceDataModal = (props) => {
  const { show = false, onClose, onSubmit } = props;

  const fileTypeList = Object.values(IMPORT_TYPE).sort((a, b) => {
    if (a.label === "DAT (User Defined)") return -1;
    if (b.label === "DAT (User Defined)") return 1;
    return a.label.localeCompare(b.label);
  });

  const getTypeExtension = (id) =>
    fileTypeList.find((item) => item.value === id)?.typeExtension;

  const [currentStep, setCurrentStep] = useState(1);
  const [currentTitle, setCurrentTitle] = useState("Add Source Data");

  const [extractedFields, setExtractedFields] = useState([]);

  const showUnmappedFieldsRef = useRef(true);

  const [datFieldTypes, setDatFieldTypes] = useState([])

  const initialFormikValues = {
    sourceTypeId: "",
    fileUpload: null,
  };

  const [formikValues, setFormikValues] = useState(initialFormikValues)

  const [errorMessage, setErrorMessage] = useState("");

  let grid;

  const [forceReRender, setForceReRender] = useState(false);
  let isDropdown = false;

  const [dashboardData, setDashboardData] = useState([]);
  const [unfilteredDashboardData, setUnfilteredDashboardData] = useState([]);

  const settings = { type: 'Multiple' };
  const loadingIndicator = { indicatorType: 'Shimmer' };

  const editOptions = { allowEditing: true, allowDeleting: false, mode: 'Normal' };

  const getCheckboxTemplate = () => {
    return `
      <div class="${styles["checkbox-container"]}">
        <input class="${styles['toolbar-input']}" 
               type="checkbox" 
               id="showUnmappedFieldsCheckbox" 
               ${showUnmappedFieldsRef.current ? 'checked' : ''} />
        <label class="${styles["toolbar-label"]}" for="showUnmappedFieldsCheckbox">
          Show Unmapped Fields
        </label>
      </div>
    `;
  };
  
  const toolbarOptions = [
    'Edit', 
    'Update', 
    'Cancel',
    {
      template: getCheckboxTemplate(),
      tooltipText: 'Show Unmapped Fields',
      id: 'showUnmapped',
      align: 'Right',
    }, 
    'Search',
  ];

  const typeParams = {
    params: { 
      query: new Query(), 
      dataSource: datFieldTypes.map(field => ({
        ...field,
        disabled: field.text !== "Unmapped" &&
          dashboardData?.some(row => 
            row.destinationField === field.text && 
            (!grid?.getSelectedRecords()?.length ||
              row.sourceField !== grid.getSelectedRecords()[0]?.sourceField)
          )
      })),
      fields: { text: 'text', value: 'value', type: 'type', disabled: 'disabled' },
      sortOrder: 'None',
    },
  };

  const fieldTypeMap = new Map([
    [1, TEAMS_FIELDS],
    [2, GENERIC_FIELDS],
    [4, SLACK_FIELDS],
    [5, AUTOSTREEM_FIELDS],
  ]);

  const validationSchemas = [
    Yup.object({
      sourceTypeId: Yup.string().required(VALIDATION_TEXT.required),
      fileUpload: Yup.mixed().required(VALIDATION_TEXT.required),
    }),
    Yup.object({}),
  ];
  
  const currentValidationSchema = validationSchemas[currentStep - 1];  

  const extractFieldNames = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const BLOCK_SIZE = 1024;
      
      let offset = 0;
      let headerLine = '';
  
      const readNextBlock = () => {
        const slice = file.slice(offset, offset + BLOCK_SIZE);
        if (slice.size === 0) {
          reject(new Error("No newline found in file"));
          return;
        }

        reader.readAsText(slice, 'UTF-8');
      };
  
      reader.onload = (event) => {
        try {
          const chunk = event.target.result;
          const newlineIndex = chunk.indexOf('\n');
  
          if (newlineIndex !== -1) {
            headerLine += chunk.substring(0, newlineIndex);
          } else {
            headerLine += chunk;
            offset += BLOCK_SIZE;
            readNextBlock();

            return;
          }
  
          if (!headerLine.includes("þ")) {
            reject(new Error("Invalid DAT file format: Header line not found or incomplete"));
            return;
          }
  
          const possibleDelimiters = ["\t", ",", "|", ";"];
          let delimiter = possibleDelimiters.reduce((a, b) =>
            headerLine.split(a).length > headerLine.split(b).length ? a : b
          );
  
          const fields = headerLine.split(delimiter).map((field) => field.trim());
  
          if (fields.length === 0) {
            reject(new Error("No fields found in the header line"));
            return;
          }
  
          resolve(fields);
        } catch (error) {
          reject(new Error(`Error processing file content: ${error.message}`));
        }
      };
  
      reader.onerror = (error) => reject(new Error(`Failed to read the file: ${error.message}`));
      readNextBlock();
    });
  };

  const formatFieldNames = (fields) => {
    const trimmedFieldNames = fields[0].replace(/^þ|þ$/g, "");
    const controlChar = String.fromCharCode(20);
    const splitPattern = new RegExp(`þ${controlChar}þ`, "g");
    
    return trimmedFieldNames.split(splitPattern);
  };

  const constructDashboardData = (includeUnmappedFields) => {
    let data;
  
    if (!unfilteredDashboardData || unfilteredDashboardData.length === 0) {
      const typeMap = new Map(datFieldTypes.map(item => [item.value, { text: item.text, type: item.type }]));
  
      data = extractedFields.reduce((acc, sourceField) => {
        const destinationField = typeMap.get(sourceField) || typeMap.get("Unmapped");
  
        if (!includeUnmappedFields && destinationField.text === "Unmapped")
          return acc; 
  
        acc.push({ sourceField, destinationField: destinationField.text, type: destinationField.type });
        return acc;
      }, []);
  
      setUnfilteredDashboardData(data);
    } else {
      data = unfilteredDashboardData.filter(item => {
        return includeUnmappedFields || item.destinationField !== "Unmapped";
      });
    }
  
    setDashboardData(data);
  };  

  const handlePreviousStep = (resetForm) => {
    const newStep = currentStep - 1;
    updateStep(newStep, resetForm);
  };
  
  const updateStep = (step, resetForm = false) => {
    setCurrentStep(step);
  
    if (step === 1) {
      setCurrentTitle("Add Source Data");
  
      if (resetForm) {
        setFormikValues(initialFormikValues);
        resetForm({
          values: { sourceTypeId: "", fileUpload: null },
        });
      }
    } else if (step === 2) {
      setCurrentTitle("Map Fields");
    }
  };  

  const handleNextStep = async (values, setFieldError) => {
    if (getFileTypeFromName(values.fileUpload.name) !== getTypeExtension(values.sourceTypeId)) {
      setFieldError("fileUpload", SOURCE_VALIDATION.typeExtension.invalid);
      return;
    }

    setFormikValues(values);

    if (currentStep === 1) {
      try {
        if (getTypeExtension(values.sourceTypeId) === "dat") {
          setDatFieldTypes(fieldTypeMap.get(values.sourceTypeId))

          const fields = await extractFieldNames(values.fileUpload);
          const formattedFields = formatFieldNames(fields);

          setExtractedFields(formattedFields);
          updateStep(currentStep + 1);
        } else {
          onSubmit(values);
        }
      } catch (error) {
        setErrorMessage(error.message || "Failed to process the file.");
      }
    }
  };

  const handleFinalSubmit = () => {  
    const unmappedRequiredFields = datFieldTypes
    .filter(field => field.required)
    .filter(requiredField => 
      !dashboardData.some(mapping => 
        mapping.destinationField === requiredField.text && 
        mapping.destinationField !== "Unmapped"
      )
    );

    if (unmappedRequiredFields.length > 0) {
      const fieldList = unmappedRequiredFields
        .map(field => field.text.replace(' *', ''))
        .join(', ');
      toast.error(`The following fields are required and must be mapped: ${fieldList}`, {
        autoClose: 10000
      });
      return;
    }

    const textToValueMap = new Map();
    for (const fields of fieldTypeMap.values()) {
      fields.forEach(field => {
        textToValueMap.set(field.text, field.value);
      });
    }
    
    const datMap = dashboardData.reduce((acc, item) => {
      acc[item.sourceField] = textToValueMap.get(item.destinationField) || "Unmapped";
      return acc;
    }, {});
  
    onSubmit({ 
      sourceTypeId: formikValues?.sourceTypeId, 
      fileUpload: formikValues?.fileUpload, 
      extractedFields, 
      datMap: JSON.stringify(datMap) 
    });
  };

  const resetState = () => {
    showUnmappedFieldsRef.current = true;
    setUnfilteredDashboardData([]);
    setDashboardData([]);
    setExtractedFields([]);
    setDatFieldTypes([]);
  }

  const handleClick = (e) => {
    if (grid && grid.isEdit && !grid.element.contains(e.target)) {
        grid.endEdit();
    }
  };

  const toolbarClick = (args) => {
    if (grid) {
      if (args.item.id === 'showUnmapped') {
        const checkbox = document.getElementById('showUnmappedFieldsCheckbox');
        showUnmappedFieldsRef.current = checkbox.checked;
        
        constructDashboardData(showUnmappedFieldsRef.current);
      }
    }
  };

  const triggerReRender = () => {
    setForceReRender(prevState => !prevState);
  }

  const created = () => {
    grid?.element?.addEventListener('mouseup', (e) => {
      if (e.target.classList.contains('e-rowcell')) {
        if (grid.isEdit) {
          grid.endEdit();
        }
        let rowInfo = grid.getRowInfo(e.target);
        if (rowInfo && rowInfo.column && rowInfo.column.field === 'destinationField') {
          isDropdown = true;
          grid.selectRow(rowInfo.rowIndex);
          grid.startEdit();
        }
      }
    });
  };

  const renderSpinner = () => {
    return (
      <div className={styles['empty-record']}>
        <p>No DAT fields found</p>
      </div>
    );
  }

  const rowDataBound = (args) => {
    if (args.data.destinationField === "Unmapped")
      args.row.classList.add(styles["unmapped-row"]);
  };

  const getFieldText = (fieldTypeMap, destinationFieldValue) => {
    let result = null;
  
    for (const fields of fieldTypeMap.values()) {
      const matchingField = fields.find(field => field.value === destinationFieldValue);
      if (matchingField) {
        result = matchingField.text;
        break;
      }
    }
  
    return result;
  }

  const onActionComplete = (args) => {
    const isFieldDisabled = (field, rowData) =>
      field.text !== "Unmapped" &&
      dashboardData?.some(
        (row) => row.destinationField === field.text && row.sourceField !== rowData.sourceField
      );
  
    const updateDropdownDataSource = (dropdownObj, rowData) => {
      dropdownObj.dataSource = datFieldTypes.map((field) => ({
        ...field,
        disabled: isFieldDisabled(field, rowData),
      }));

      const currentIndex = dropdownObj.dataSource.findIndex(
        (item) => item.text === rowData.destinationField
      );

      if (currentIndex !== -1) dropdownObj.index = currentIndex;
  
      dropdownObj.dataBind();
      dropdownObj.element.focus();
      dropdownObj.showPopup();
    };
  
    if (args.requestType === "beginEdit" && isDropdown) {
      isDropdown = false;
  
      const dropdownObj = args.form.querySelector(".e-dropdownlist")["ej2_instances"][0];
      updateDropdownDataSource(dropdownObj, args.rowData);
  
      dropdownObj.addEventListener("change", () => {
        grid.endEdit();
      });
  
      document.addEventListener("click", handleClick);
    }
  
    if (args.requestType === "save") {
      const destinationFieldValue = args.data?.destinationField;
      const destinationFieldText = getFieldText(fieldTypeMap, destinationFieldValue);
  
      const selectedField = datFieldTypes.find((item) => item.text === destinationFieldText);
      if (selectedField) {
        const updatedRow = grid.dataSource.find(
          (item) => item.sourceField === args.data.sourceField
        );
  
        if (updatedRow) {
          updatedRow.destinationField = destinationFieldText;
          updatedRow.type = selectedField.type;
        }
  
        grid.refresh();
      }
  
      const modifiedDashboardData = unfilteredDashboardData.map((item) =>
        item.sourceField === args.data?.sourceField
          ? {
              ...item,
              destinationField: args.data?.destinationField,
              type: selectedField?.type,
            }
          : item
      );
  
      if (!showUnmappedFieldsRef.current && args.data?.destinationField === "Unmapped")
        setUnfilteredDashboardData(modifiedDashboardData);
  
      document.removeEventListener("click", handleClick);
    }
  };  

  useEffect(() => {
    triggerReRender();
  }, [dashboardData]);

  useEffect(() => {
    if (extractedFields.length > 0) 
      constructDashboardData(showUnmappedFieldsRef.current);
  }, [extractedFields, datFieldTypes, unfilteredDashboardData]);

  return (
    <Formik
      initialValues={initialFormikValues}
      validationSchema={currentValidationSchema}
      onSubmit={(values, { setFieldError }) => {
        if (currentStep === 1)
          handleNextStep(values, setFieldError);
        else
          handleFinalSubmit();
      }}
    >
      {({ values, handleSubmit, resetForm }) => (
        <Form>
          <Modal
            show={show}
            onHide={onClose}
            centered
            backdrop="static"
            className={styles["modal-container"]}
            dialogClassName={`${styles["modal-dialog"]} ${currentStep === 2 ? styles["modal-dialog-large"] : ""}`}
            contentClassName={styles["modal-content"]}
          >
            <Modal.Header closeButton className={styles["modal-header"]}>
              <Modal.Title>{currentTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles["modal-body"]}>
              {currentStep === 1 && (
                <>
                  <FieldSelect
                    label="Source Type"
                    name="sourceTypeId"
                    placeHolder="Please select an option..."
                    options={fileTypeList}
                    required
                  />
                  {values.sourceTypeId && (
                    <p>
                      *Note: You can only upload files with the extension{" "}
                      {getTypeExtension(values.sourceTypeId)?.toUpperCase()}
                    </p>
                  )}
                  <FieldUpload
                    label="Upload Path"
                    name="fileUpload"
                    placeHolder="Source Path..."
                    required
                  />
                </>
              )}
              {currentStep === 2 && (
                <>
                  {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                  <div className={styles["field-names-dashboard__table"]}>
                    <GridComponent 
                      created={created} 
                      actionComplete={onActionComplete}
                      dataSource={dashboardData}
                      allowSorting={true}
                      selectionSettings={settings} 
                      enableHover={false} 
                      editSettings={editOptions} 
                      toolbar={toolbarOptions} 
                      emptyRecordTemplate={renderSpinner}
                      height={500} 
                      width={800}
                      loadingIndicator={loadingIndicator} 
                      toolbarClick={toolbarClick}
                      ref={(g) => (grid = g)}
                      key={forceReRender ? "ReRenderKey" : "Key"}
                      sortSettings={{ columns: [{ field: 'sourceField', direction: 'Ascending' }] }}
                      rowDataBound={rowDataBound}
                    >
                      <ColumnsDirective>
                        <ColumnDirective field='sourceField' headerText='Source Field' width='360' allowSorting={true} isPrimaryKey={true}/>
                        <ColumnDirective field='destinationField' headerText='Destination Field' autoFit={true} width='150' editType="dropdownedit" edit={typeParams} sort/>
                        <ColumnDirective field='type' headerText='Type' autoFit={true} width='90' allowEditing={false}/>
                      </ColumnsDirective>
                      <Inject services={[Sort, Edit, Search, Toolbar]} />
                    </GridComponent>
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className={styles["modal-footer"]}>
              <div className={styles["group-btn"]}>
                {currentStep > 1 && (
                  <Button
                    name="Back"
                    handleClick={() => {
                      resetState();
                      handlePreviousStep(() => {
                        setFormikValues(initialFormikValues);
                        resetForm({ values: { sourceTypeId: values.sourceTypeId, fileUpload: null } });
                      });
                    }}
                  />
                )}
                <Button name="Cancel" handleClick={onClose} />
                <Button
                  className="btn-primary-fill"
                  name={currentStep === 2 ? "Submit" : "Next"}
                  type="submit"
                  handleClick={handleSubmit}                  
                />
              </div>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

AddSourceDataModal.propTypes = {
  submitButtonText: PropTypes.string,
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default AddSourceDataModal;