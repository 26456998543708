import React, { useRef, useState } from "react";
import { Field } from "formik";
import PropTypes from "prop-types";
import Select from "react-select";
import { useOnClickOutside } from "hook/click-outside";

// Components
import ErrorInline from "components/shared/error-inline/ErrorInline";
import SuggestionList from "components/shared/suggestion-list/SuggestionList";

// Styles
import styles from "./FieldSelect.module.scss";

const FieldSelect = (props) => {
  const {
    name,
    label,
    placeHolder,
    placeHolderSearch,
    options = [],
    required = false,
    isLoading = false,
    hideSearchIcon = false,
    defaultValue = null,
    isGrouped = false,
  } = props;

  const dropdownRef = useRef(null);
  const menuRef = useRef(null);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useOnClickOutside(menuRef, () => setMenuIsOpen(false), dropdownRef?.current);

  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <div className={styles["field-container"]}>
          <label>
            {label}
            {required && <span className="asterisk"> *</span>}
          </label>
          <div
            onClick={() => !isLoading && setMenuIsOpen(!menuIsOpen)}
            ref={dropdownRef}
          >
            <Select
              className={
                meta.touched && meta.error ? styles["border-error"] : ""
              }
              classNamePrefix="field-select"
              placeholder={placeHolder}
              value={
                options
                  ? isGrouped 
                    ? options.flatMap(group => group.options).find(option => option.value === field.value)
                    : options.find((option) => option.value === field.value)
                  : ""
              }
              defaultValue={defaultValue}
              isLoading={isLoading}
              isDisabled={isLoading}
              components={{ Menu: () => null }}
              isSearchable={false}
              onKeyDown={(e) => e.preventDefault()}
            />
            {meta.touched && meta.error && (
              <div className={styles["form-error"]}>
                <ErrorInline errorMsg={meta.error} touched={meta.touched} />
              </div>
            )}
          </div>
          <div
            className={styles["suggestion-list"]}
            ref={menuRef}
            hidden={!menuIsOpen}
          >
            <SuggestionList
              placeholder={placeHolderSearch}
              suggestionList={options}
              selectedItem={field.value}
              getDataSelected={(data) => {
                form.setFieldValue(field.name, data.value);
                setMenuIsOpen(false);
              }}
              clearSearch={!menuIsOpen}
              hideSearchIcon={hideSearchIcon}
              isGrouped={isGrouped}
            />
          </div>
        </div>
      )}
    </Field>
  );
};

FieldSelect.propTypes = {
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  placeHolderSearch: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  hideSearchIcon: PropTypes.bool,
  defaultValue: PropTypes.object,
  isGrouped: PropTypes.bool,
};

export default FieldSelect;
