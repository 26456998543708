import React, { useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";

// Services
import {
  createIndexApi,
  loadProcedureApi,
  updatePartyToContactApi,
} from "services/StreemViewConfigServices";
import { updateMatterAISettings, runAIJob } from "services/AIJobService";

// Components
import ConfigWithProjectId from "components/streemview-config/config-general/ConfigWithProjectId";
import HashingDatabasePassword from "components/streemview-config/hashing-password/HashingDatabasePassword";
import ConvertCompressImage from "components/streemview-config/config-image/ConvertCompressImage";
import CreateProjectDatabase from "components/streemview-config/create-project-database/CreateProjectDatabase";
import CreateClient from "components/streemview-config/create-client/CreateClient";
import ConfigIndex from "components/streemview-config/config-index/ConfigIndex";
import ConfigAiSettings from "components/streemview-config/config-ai/ConfigAiSettings";

// Styles
import styles from "./StreemViewConfigPage.module.scss";

const StreemViewConfigPage = () => {
  const [selectId, setSelectId] = useState({
    createIndex: null,
    convertCompressImage: null,
    updatePartyToContact: null,
  });

  const [createIndexLoading, setCreateIndexLoading] = useState(false);
  const [aiSettingsLoading, setAiSettingsLoading] = useState(false);
  const [loadProcedureLoading, setLoadProcedureLoading] = useState(false);
  const [updatePartyLoading, setUpdatePartyLoading] = useState(false);

  // Store
  const { projects, loading: projectLoading } = useSelector(
    (state) => state.projects
  );

  const projectListOption = projects.map((project) => ({
    value: project.projectId,
    label: project.projectName,
    aiSettings: project.aiSettings ? JSON.parse(project.aiSettings) : null,
  }));


  const handleCreateIndex = async (indexOptions) => {
    setCreateIndexLoading(true);
    try {
      const result = await createIndexApi(selectId.createIndex, {
        extractAttachments: indexOptions?.extractAttachments,
        identifyLanguage: indexOptions?.identifyLanguage
      });
      if (!result) return;
    } catch (error) {
      console.log(error);
    } finally {
      setCreateIndexLoading(false);
    }
  };

  const handleRunAiSettings = async (aiSettings) => {
    setAiSettingsLoading(true);
    try {
      if (aiSettings) {
        // Save settings
        await updateMatterAISettings(selectId.aiSettings, {
          aiSettings: aiSettings
        });
      } else {
        // Run job
        await runAIJob(selectId.aiSettings);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setAiSettingsLoading(false);
    }
  };

  const handleUpdatePartyToContact = async () => {
    setUpdatePartyLoading(true);
    try {
      const result = await updatePartyToContactApi(selectId.updatePartyToContact);
      if (!result) return;
    } catch (error) {
      console.log(error);
    } finally {
      setUpdatePartyLoading(false);
    }
  };

  const handleLoadProcedure = async () => {
    setLoadProcedureLoading(true);
    try {
      const result = await loadProcedureApi();
      if (!result) return;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadProcedureLoading(false);
    }
  };

  const handleSelectProject = (type, data) =>
    setSelectId({
      ...selectId,
      [type]: data.value,
    });

  return (
    <div className={clsx(styles["config-page"], "main")}>
      <div className={styles["config-header"]}>
        <h2>StreemView Configuration</h2>
      </div>
      <div className={styles["config-body"]}>
        {/* Create index UI */}
        <ConfigIndex
          titleConfig="Create and Run Index Job"
          isLoading={createIndexLoading}
          isLoadingProject={projectLoading}
          options={projectListOption}
          onChangeProjectId={(event) =>
            handleSelectProject("createIndex", event)
          }
          handleRunConfig={handleCreateIndex}
          projectId={selectId.createIndex}
        />
        <hr />

        {/* Convert and compress image UI */}
        <ConvertCompressImage
          titleConfig="Run Thumbnail Generation/Attachment Extraction"
          isLoadingProject={projectLoading}
          options={projectListOption}
          onChangeProjectId={(event) =>
            handleSelectProject("convertCompressImage", event)
          }
          projectId={selectId.convertCompressImage}
        />
        <hr />


        {/* Config AI Settings */}
        <ConfigAiSettings
          titleConfig="Run AI Job"
          isLoading={aiSettingsLoading}
          isLoadingProject={projectLoading}
          options={projectListOption}
          onChangeProjectId={(event) =>
            handleSelectProject("aiSettings", event)
          }
          handleRunConfig={handleRunAiSettings}
          projectId={selectId.aiSettings}
        />
        <hr />

        {/* Update Party to Contact */}
        <ConfigWithProjectId
          titleConfig="Update Party To Contact"
          isLoading={updatePartyLoading}
          isLoadingProject={projectLoading}
          options={projectListOption}
          onChangeProjectId={(event) =>
            handleSelectProject("updatePartyToContact", event)
          }
          handleRunConfig={handleUpdatePartyToContact}
          projectId={selectId.updatePartyToContact}
        />
        <hr />

        {/* Load procedure UI */}
        <ConfigWithProjectId
          titleConfig="Load Procedure"
          isLoading={loadProcedureLoading}
          handleRunConfig={handleLoadProcedure}
          isShowProjectId={false}
        />
        <hr />

        {/* Hashing Database password UI */}
        <HashingDatabasePassword />
        <hr />

        {/* Create Project Database */}
        <CreateProjectDatabase />
        <hr />

        {/* Create Client */}
        <CreateClient />
        <hr />
      </div>
    </div>
  );
};

export default StreemViewConfigPage;
