import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import SearchFilter from "../search-input/search-filter/SearchFilter";
import SuggestionItem from "../search-input/suggestion-item/SuggestionItem";

// Styles
import styles from "./SuggestionList.module.scss";

const SuggestionList = ({
  suggestionList = [],
  placeholder,
  selectedItem,
  getDataSelected = () => {},
  clearSearch = false,
  hideSearchIcon = false,
  isGrouped = false,
}) => {
  const [searchInput, setSearchInput] = useState("");

  const filteredList = isGrouped
    ? suggestionList.map(group => ({
        ...group,
        options: group.options.filter(
          item =>
            item.label
              ?.trim()
              .toLowerCase()
              .indexOf(searchInput.trim().toLowerCase()) !== -1
        )
      })).filter(group => group.options.length > 0)
    : suggestionList?.filter(
        item =>
          item.label
            ?.trim()
            .toLowerCase()
            .indexOf(searchInput.trim().toLowerCase()) !== -1
      );

  const renderNoResultData = () => (
    <p className={styles["no-result"]}>No Result</p>
  );

  useEffect(() => {
    setSearchInput("");
  }, [clearSearch]);

  const renderGroupedList = () => (
    <ul>
      {filteredList.map((group) => (
        <li key={group.label} className={styles["group"]}>
          <div className={styles["group-header"]}>{group.label}</div>
          <ul className={styles["group-options"]}>
            {group.options.map((item) => (
              <li
                className={item.value === selectedItem ? styles["active"] : ""}
                key={item.value}
                onClick={() => getDataSelected(item)}
                title={item.label}
              >
                {searchInput ? (
                  <SuggestionItem
                    data={item.label}
                    input={searchInput.trim()}
                  />
                ) : (
                  <span>{item.label}</span>
                )}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );

  const renderRegularList = () => (
    <ul>
      {filteredList.length > 0 ? (
        <React.Fragment>
          {filteredList.map((item) => (
            <li
              className={item.value === selectedItem ? styles["active"] : ""}
              key={item.value}
              onClick={() => getDataSelected(item)}
              title={item.label}
            >
              {searchInput ? (
                <SuggestionItem
                  data={item.label}
                  input={searchInput.trim()}
                />
              ) : (
                <span>{item.label}</span>
              )}
            </li>
          ))}
        </React.Fragment>
      ) : (
        renderNoResultData()
      )}
    </ul>
  );

  return (
    <div className={styles["wrap"]}>
      {placeholder && (
        <SearchFilter
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
          placeholder={placeholder}
          handleClearSearch={() => setSearchInput("")}
          hideSearchIcon={hideSearchIcon}
        />
      )}
      {suggestionList?.length > 0 ? (
        isGrouped ? renderGroupedList() : renderRegularList()
      ) : (
        renderNoResultData()
      )}
    </div>
  );
};

SuggestionList.propTypes = {
  suggestionList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      PropTypes.shape({
        label: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          })
        ),
      }),
    ])
  ),
  placeholder: PropTypes.string,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearSearch: PropTypes.bool,
  hideSearchIcon: PropTypes.bool,
  getDataSelected: PropTypes.func,
  isGrouped: PropTypes.bool,
};

export default SuggestionList;
