import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import { VariableSizeList as List } from 'react-window';

// Styles
import styles from './ExtractedTextRenderer.module.scss';

const ExtractedTextRenderer = (props) => {
    const {
        content,
        searchHitPositions,
        searchTermPositions,
    } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [contentLines, setContentLines] = useState([]);
    const listRef = useRef();
    const rowHeights = useRef({});
    const [containerHeight, setContainerHeight] = useState(0);
    const parentRef = useRef(null);

    const convertNewlineToBreaks = (textString) => {
        return textString
            .split('\n')
            .filter(line => line.trim() !== '')
            .join('<br />');
    };

    const getHighlightedContentHtml = (content, searchHitPositions, searchTermPositions) => {
        if (!content || (!searchHitPositions?.length && !searchTermPositions?.length)) {
            return content;
        }

        const positionMap = new Map();
        
        const searchHits = searchHitPositions?.map(hit => ({ ...hit, type: 'search-highlight' })) || [];
        searchHits.forEach(hit => {
            positionMap.set(hit.offset, hit);
        });

        const searchTerms = searchTermPositions?.map(term => ({ ...term, type: 'search-term-highlight' })) || [];
        searchTerms.forEach(term => {
            if (!positionMap.has(term.offset)) {
                positionMap.set(term.offset, term);
            }
        });

        const allHighlights = Array.from(positionMap.values())
            .sort((a, b) => a.offset - b.offset);

        let result = '';
        let currentPosInContentString = 0;

        for (const highlight of allHighlights) {
            result += content.slice(currentPosInContentString, highlight.offset);
            
            const highlightedText = content.slice(highlight.offset, highlight.offset + highlight.length);
            result += `<span class="${highlight.type}">${highlightedText}</span>`;
            
            currentPosInContentString = highlight.offset + highlight.length;
        }

        result += content.slice(currentPosInContentString);

        return convertNewlineToBreaks(result);
    };

    const prepareVirtualizedContent = (htmlContent) => {
        if (!htmlContent) return [];
        return htmlContent.split('<br />').filter(line => line.trim() !== '');
    };

    const loadContentHtml = (content, searchHitPositions, searchTermPositions) => {
        if (content == null || content === '') {
            setIsLoading(false);
            return;
        }

        let result;
        if (!searchHitPositions?.length && !searchTermPositions?.length) {
            result = convertNewlineToBreaks(content);
        } else {
            result = getHighlightedContentHtml(content, searchHitPositions, searchTermPositions);
        }

        setContentLines(prepareVirtualizedContent(result));
        setIsLoading(false);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => loadContentHtml(content, searchHitPositions, searchTermPositions), 100);
        return () => clearTimeout(timeoutId);
    }, [content, searchHitPositions, searchTermPositions]);

    useEffect(() => {
        if (parentRef.current) {
            setContainerHeight(parentRef.current.clientHeight);
        }
    }, []);

    const getRowHeight = index => {
        return rowHeights.current[index] || 50;
    };

    const Row = ({ index, style }) => {
        const rowRef = useRef(null);

        useEffect(() => {
            if (rowRef.current) {
                const height = rowRef.current.getBoundingClientRect().height;
                if (height !== rowHeights.current[index]) {
                    rowHeights.current[index] = height;
                    listRef.current?.resetAfterIndex(index);
                }
            }
        }, [contentLines[index]]);

        return (
            <div 
                ref={rowRef}
                className={styles['content-line']}
                style={{
                    ...style,
                    height: 'auto'
                }} 
                dangerouslySetInnerHTML={{ __html: contentLines[index] }} 
            />
        );
    };

    Row.propTypes = {
        index: PropTypes.number.isRequired,
        style: PropTypes.object.isRequired,
    };

    return (
        <div ref={parentRef} className={styles['extracted-text-container']}>
            {isLoading ? (
                <div className={styles['loading-spinner-container']}>
                    <SpinnerLoading isCenter={false} />
                </div>
            ) : (
                content == null ? (
                    <div className={styles['no-content']}>
                        <p>No text content available</p>
                    </div>
                ) : (
                    <div className={styles['content']}>
                        <div className={styles['document-content']}>
                            <List
                                ref={listRef}
                                className={styles['virtualized-list']}
                                height={containerHeight}
                                itemCount={contentLines.length}
                                itemSize={getRowHeight}
                                width="100%"
                                overscanCount={5}
                            >
                                {Row}
                            </List>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

ExtractedTextRenderer.propTypes = {
    content: PropTypes.string,
    searchHitPositions: PropTypes.arrayOf(PropTypes.shape({
        offset: PropTypes.number,
        length: PropTypes.number,
    })),
    searchTermPositions: PropTypes.arrayOf(PropTypes.shape({
        offset: PropTypes.number,
        length: PropTypes.number,
    })),
};

export default ExtractedTextRenderer;
