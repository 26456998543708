import React from "react";
import PropTypes from "prop-types";

// Constants
import { PREVIEW_NOT_AVAILABLE_ERROR } from "constants/NativeViewerConstants";

// Styles
import styles from "./PreviewNotAvailable.module.scss";

const PreviewNotAvailable = ({ message }) => {
  return (
    <p className={styles["preview-not-available"]}>
      <img
                src="/images/image-notfound.png"
                alt="not found"
            />
      <p>{message || PREVIEW_NOT_AVAILABLE_ERROR}</p>
    </p>
  );
};

PreviewNotAvailable.propTypes = {
  message: PropTypes.string,
};

export default PreviewNotAvailable;
