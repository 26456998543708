import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { BeatLoader } from "react-spinners";

// Components
import DropdownGeneral from "components/shared/dropdown-selector/dropdown-general/DropdownGeneral";
import { Button } from "components/shared/button/Button";
import Checkbox from "components/shared/checkbox/Checkbox";

// Constants
import { COLORS } from "constants/Common";

// Styles
import styles from "./ConfigAiSettings.module.scss";

const ConfigAiSettings = (props) => {
  const {
    titleConfig = "",
    projectId = 0,
    isLoadingProject = false,
    options = [],
    onChangeProjectId = () => { },
    handleRunConfig = () => { },
  } = props;

  const [aiSettings, setAiSettings] = React.useState({
    aiSearch: false,
  });
  const [isSaving, setIsSaving] = React.useState(false);
  const [isRunning, setIsRunning] = React.useState(false);

  const onChangeAiSearch = () => {
    setAiSettings({ aiSearch: !aiSettings?.aiSearch });
  }

  const handleSaveSettings = async () => {
    setIsSaving(true);
    try {
      await handleRunConfig(aiSettings);
    } finally {
      setIsSaving(false);
    }
  };

  const handleRunJob = async () => {
    setIsRunning(true);
    try {
      await handleRunConfig();
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <div className={styles["wrap"]}>
      <h3>{titleConfig}</h3>
      <div className={clsx(styles["form-group"], "app-form-group")}>
        <label className="app-form-label">
          Project Name
          <span className={styles["form-required"]}> *</span>
        </label>
        <DropdownGeneral
          className={clsx(
            "dropdown-react-select",
            "w-100",
            styles["select-project"]
          )}
          placeholder="Select Project Name"
          options={options}
          isLoading={isLoadingProject}
          isDisabled={isLoadingProject}
          onChange={(option) => {
            onChangeProjectId(option);
            setAiSettings({
              aiSearch: option?.aiSettings?.aiSearch
            });
          }}
          value={options.filter((item) => item.value === projectId)}
        />
      </div>
      <div className={styles["settings-checkbox"]}>
        <Checkbox
          id="aiSearch"
          label="Enable AI Search"
          handleClick={onChangeAiSearch}
          isChecked={aiSettings.aiSearch}
          size="lg"
        />
      </div>
      <div className={styles["button-container"]}>
        {(isSaving || isRunning) ? (
          <BeatLoader
            color={COLORS.black}
            loading={true}
            size={8}
            className={styles["beat-loader"]}
          />
        ) : (
          <>
            <Button
              name="Save Settings"
              className="btn-primary-fill"
              handleClick={handleSaveSettings}
              disabled={!projectId}
            />
            <Button
              name="Run Job"
              className="btn-secondary-fill"
              handleClick={handleRunJob}
              disabled={!projectId}
            />
          </>
        )}
      </div>
    </div>
  );
};

ConfigAiSettings.propTypes = {
  titleConfig: PropTypes.string,
  projectId: PropTypes.number,
  options: PropTypes.array,
  isShowProjectId: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingProject: PropTypes.bool,
  onChangeProjectId: PropTypes.func,
  handleRunConfig: PropTypes.func,
};

export default ConfigAiSettings;

