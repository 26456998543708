import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginAuth0 = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({ prompt: "login" });
  }, []);

  return (<></>);
};

export default LoginAuth0;
