import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useToken } from "hook/auth";
import Toggle from "components/shared/toggle/Toggle";

// Constants
import { MATTER_VALIDATION } from "constants/Validations";
import { KEY_CODE } from "constants/Common";
import { MATTER_TYPES } from "constants/MatterConstants";

// Components
import { Button } from "components/shared/button/Button";
import FieldSelect from "components/shared/field/field-select/FieldSelect";
import FieldInput from "components/shared/field/field-input/FieldInput";

// Styles
import styles from "./CreateMatterModal.module.scss";

const CreateMatterModal = (props) => {

  const { show = false, onClose, onSubmit, clientList } = props;

  const btnSubmitRef = useRef(null);
  const { isAdmin } = useToken();

  const [aiSettings, setAiSettings] = useState({
    piiIdentification: false,
    // explicitTextIdentification: false, -- future options
    // explicitMediaIdentification: false,
    // financialMisconduct: false,
    // transcribeContent: false,
    aiSearch: false
  });

  const handleAiSettingChange = (setting) => (e) => {
    setAiSettings(prev => ({
      ...prev,
      [setting]: e.target.checked
    }));
  };

  const handleSubmit = () => {
    if (btnSubmitRef.current) btnSubmitRef?.current?.click();
  };

  const matterSchema = Yup.object().shape({
    clientId: isAdmin()
      ? Yup.string().required(MATTER_VALIDATION.required)
      : Yup.string().notRequired(),
    matterName: Yup.string()
      .trim()
      .required(MATTER_VALIDATION.required)
      .max(128, MATTER_VALIDATION.matterName_max),
    matterType: Yup.string(),
    clientMatterReference: Yup.string()
      .trim()
      .max(128, MATTER_VALIDATION.reference_max),
  });

  const onKeyDown = (event) => {
    if (event.keyCode === KEY_CODE.enter) event.preventDefault();
  };

  const defaultMatterType = MATTER_TYPES[0].options.find(option => option.value === "GeneralLitigation");

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      className={styles["modal-container"]}
      dialogClassName={styles["modal-dialog"]}
      contentClassName={styles["modal-content"]}
    >
      <Modal.Header closeButton className={styles["modal-header"]}>
        <Modal.Title>Add New Matter</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles["modal-body"]}>
        <Formik
          initialValues={{
            clientId: "",
            matterName: "",
            matterType: defaultMatterType.value,
            clientMatterReference: "",
          }}
          validationSchema={matterSchema}
          onSubmit={(values, { setFieldError }) =>
            onSubmit({ ...values, aiSettings }, setFieldError)
          }
        >
          <Form className={styles["form-container"]} onKeyDown={onKeyDown}>
            {isAdmin() && (
              <FieldSelect
                label="Client"
                name="clientId"
                placeHolder="Select Client"
                placeHolderSearch="Search Clients"
                options={clientList}
                required
                hideSearchIcon={true}
              />
            )}
            <div className={styles["field-input"]}>
              <FieldInput
                label="Matter Name"
                name="matterName"
                placeHolder="Enter Matter Name..."
                required
              />
              <FieldInput
                label="Client Matter Reference"
                name="clientMatterReference"
                placeHolder="Client Matter Reference..."
              />
              <FieldSelect
                label="Matter Type"
                name="matterType"
                placeHolder="Select Matter Type"
                placeHolderSearch="Search Matter Types"
                options={MATTER_TYPES}
                required={false}
                hideSearchIcon={true}
                isGrouped={true}
                defaultValue={defaultMatterType}
              />
            </div>
            <div className={styles["ai-capabilities-section"]}>
              <label className={styles["section-label"]}>AI Capabilities (Beta)</label>
              <div className={styles["toggle-container"]}>
                <div className={styles["note-tooltip-group"]}>
                  <span>AI Search</span>
                  <img
                    className={styles["note-tooltip"]}
                    src="/images/note.svg"
                    alt="note"
                  />
                  <span className={styles["tooltip-description"]}>
                    Enables intelligent AI searching in the matter.
                  </span>
                </div>
                <Toggle
                  checked={aiSettings.aiSearch}
                  onChange={handleAiSettingChange('aiSearch')}
                />
              </div>
              {/* <div className={styles["toggle-container"]}>
                <div className={styles["note-tooltip-group"]}>
                  <span>PII Identification</span>
                  <img
                    className={styles["note-tooltip"]}
                    src="/images/note.svg"
                    alt="note"
                  />
                  <span className={styles["tooltip-description"]}>
                    Automatically identifies and flags personally identifiable information (PII) in documents
                  </span>
                </div>
                <Toggle
                  checked={aiSettings.piiIdentification}
                  onChange={handleAiSettingChange('piiIdentification')}
                />
              </div> */}
            </div>
            <div className={styles["ai-search-section"]}>

            </div>
            <button ref={btnSubmitRef} type="submit" hidden>
              Submit
            </button>
          </Form>
        </Formik>
      </Modal.Body>
      <Modal.Footer className={styles["modal-footer"]}>
        <div className={styles["group-btn"]}>
          <Button name="Cancel" handleClick={onClose} />
          <Button
            className="btn-primary-fill"
            name="Add Matter"
            type="submit"
            handleClick={handleSubmit}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

CreateMatterModal.propTypes = {
  show: PropTypes.bool,
  clientList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateMatterModal;
