import { configureStore } from "@reduxjs/toolkit";
import { UserReducer } from "./UserReducer";
import { HeatMapReducer } from "./HeatMapReducer";
import { DatePickerReducer } from "./DatePickerReducer";
import { DataSourceReducer } from "./DataSourceReducer";
import { ISReducer } from "./ISReducer";
import { GeneralReviewReducer } from "./GeneralReviewReducer";
import { TagReducer } from "./TagReducer";
import { SearchConditionReducer } from "./SearchConditionReducer";
import { EventTimelineReducer } from "./EventTimelineReducer";
import { ProjectReducer } from "./ProjectReducer";
import { ExportManagementReducer } from "./ExportManagementReducer";
import { MediaControlReducer } from "./MediaControlReducer";
import { CommonReducer } from "./CommonReducer";
import { ImportBlobReducer } from "./ImportBlobReducer";
import { NotificationReducer } from "./NotificationReducer";
import { MatterDateReducer } from "./MatterDateReducer";
import { MassTagReducer } from "./MassTagReducer";
import { SearchTermsReducer } from "./SearchTermsReducer";
import { FilterTagReducer } from "./FilterTagReducer";
import { AvatarReducer } from "./AvatarReducer";
import { ViewerReducer } from "./ViewerReducer";
import { AttachmentReviewReducer } from "./AttachmentReviewReducer";

export default configureStore({
  reducer: {
    user: UserReducer,
    heatMap: HeatMapReducer,
    datePicker: DatePickerReducer,
    dataSource: DataSourceReducer,
    is: ISReducer,
    generalRV: GeneralReviewReducer,
    tag: TagReducer,
    searchCondition: SearchConditionReducer,
    eventTimeline: EventTimelineReducer,
    projects: ProjectReducer,
    export: ExportManagementReducer,
    mediaControl: MediaControlReducer,
    common: CommonReducer,
    importBlob: ImportBlobReducer,
    notification: NotificationReducer,
    massTag: MassTagReducer,
    matterDate: MatterDateReducer,
    searchTerms: SearchTermsReducer,
    filterTag: FilterTagReducer,
    avatar: AvatarReducer,
    viewer: ViewerReducer,
    attachmentReview: AttachmentReviewReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
