import axios, { authHeader } from "./ApiConfig";

const applyMassTag = async (matterId, data) => {
    return await axios.post(`tag/${matterId}/massTag`, 
    data, {
        headers: authHeader(),
    });
};

const applyMassUntag = async (matterId, data) => {
    return await axios.post(`tag/${matterId}/massUntag`, 
    data, {
        headers: authHeader(),
    });
};

export {
    applyMassTag,
    applyMassUntag,
};