import axios from "axios";
import { authHeader } from "./ApiConfig";

export const updateMatterAISettings = async (matterId, aiSettings) => {
    return await axios({
        method: 'put',
        baseURL: `${process.env.REACT_APP_API_URL}/projectAdmin/matters/${matterId}/aiSettings`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
        data: aiSettings
    });
};

export const runAIJob = async (matterId) => {
    return await axios({
        method: 'post',
        baseURL: `${process.env.REACT_APP_API_URL}/aiManagement/${matterId}/startAIJob`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
    });
};
