import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./PreviewExtractedText.module.scss";

// Components
import ExtractedTextRenderer from "components/attachment-render/ExtractedTextRenderer";

const PreviewExtractedText = (props) => {
    const {
        content,
        searchHitPositions,
        searchTermPositions,
    } = props;

    return <div className={styles["preview-extracted-text__content"]}>
        <ExtractedTextRenderer content={content} searchHitPositions={searchHitPositions} searchTermPositions={searchTermPositions} />
    </div>;
};

PreviewExtractedText.propTypes = {
    content: PropTypes.string,
    searchHitPositions: PropTypes.array,
    searchTermPositions: PropTypes.array,
};

export default PreviewExtractedText;
