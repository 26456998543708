import axios from "axios";
import { AUTH_CONSTANT } from "constants/AuthConstant";

const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = process.env.REACT_APP_BASE_URL;

let controller = new AbortController();

const getAbortController = () => controller;

const handleAbortRequest = () => {
  controller.abort();
  controller = new AbortController();
};

const authHeader = () => {
  const token = JSON.parse(localStorage.getItem(AUTH_CONSTANT.token) || null);

  return {
    Authorization: "Bearer " + token,
    Accept: "application/json",
    "Content-Type": "application/json",
    "TimeOffset": new Date().getTimezoneOffset(),
  };
};

const authHeaderFormData = () => {
  const token = JSON.parse(localStorage.getItem(AUTH_CONSTANT.token) || null);

  return {
    Authorization: "Bearer " + token,
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "TimeOffset": new Date().getTimezoneOffset(),
  };
}

export default axios.create({
  baseURL: apiUrl,
  timeout: 60000,
});

const axiosWithoutErrorPage = axios.create({
  baseURL: apiUrl,
  timeout: 60000,
});

export { apiUrl, baseUrl, getAbortController, authHeader, authHeaderFormData, handleAbortRequest, axiosWithoutErrorPage };
