// Keep these sorted in ascending by text, with "Unmapped" at the top

export const GENERIC_FIELDS = [
  { value: "Unmapped", text: "Unmapped", type: "", required: false },
  { value: "Attachment File Name", text: "Attachment File Name", type: "Text", required: false },
  { value: "Control Number", text: "Control Number", type: "Text", required: false },
  { value: "Conversation Identifier", text: "Conversation Identifier", type: "Text", required: false },
  { value: "Family Identifier", text: "Family Identifier", type: "Text", required: false },
  { value: "File Name", text: "File Name", type: "Text", required: false },
  { value: "File Source Path", text: "File Source Path", type: "Text", required: false },
  { value: "ImportBatchGuid", text: "ImportBatchGuid", type: "Text", required: false },
  { value: "Message Body", text: "Message Body *", type: "Text", required: true },
  { value: "Message Sent Date", text: "Message Sent Date *", type: "Date", required: true },
  { value: "Message Deleted", text: "Message Deleted", type: "Yes/No", required: false },
  { value: "Message From", text: "Message From *", type: "Text", required: true },
  { value: "Message To", text: "Message To *", type: "Text", required: true },
  { value: "Record Type", text: "Record Type *", type: "Text", required: true },
  { value: "Source Application", text: "Source Application", type: "Text", required: false }
];

export const AUTOSTREEM_FIELDS = [ 
  { value: "Unmapped", text: "Unmapped", type: "", required: false },
  { value: "Attach Count", text: "Attach Count", type: "Text", required: false },
  { value: "Attach Names", text: "Attach Names", type: "Text", required: false },
  { value: "Body", text: "Body *", type: "Text", required: true },
  { value: "Cc", text: "Cc", type: "Text", required: false },
  { value: "Conversation Index", text: "Conversation Index", type: "Text", required: false },
  { value: "Date Received", text: "Date Received", type: "Date", required: false },
  { value: "Date Sent", text: "Date Sent *", type: "Text", required: true },
  { value: "Doc Id", text: "Doc Id", type: "Text", required: false },
  { value: "Extension", text: "Extension", type: "Text", required: false },
  { value: "File Name", text: "File Name", type: "Text", required: false },
  { value: "Flag", text: "Flag", type: "Text", required: false },
  { value: "From", text: "From *", type: "Text", required: true },
  { value: "Full Path", text: "Full Path", type: "Text", required: false },
  { value: "Is Deleted", text: "Is Deleted", type: "Text", required: false },
  { value: "Native Link", text: "Native Link *", type: "Text", required: true },
  { value: "Parent Id", text: "Parent Id *", type: "Text", required: true },
  { value: "Record Type", text: "Record Type *", type: "Text", required: true },
  { value: "Relative Path", text: "Relative Path", type: "Text", required: false },
  { value: "Size", text: "Size", type: "Text", required: false },
  { value: "Subject", text: "Subject *", type: "Text", required: true },
  { value: "Time Received", text: "Time Received", type: "Date", required: false },
  { value: "Time Sent", text: "Time Sent *", type: "Text", required: true },
  { value: "To", text: "To *", type: "Text", required: true },
  { value: "Bcc", text: "Bcc", type: "Text", required: false }
];

export const TEAMS_FIELDS = [ 
  { value: "Unmapped", text: "Unmapped", type: "", required: false },
  { value: "Control Number", text: "Control Number *", type: "Text", required: true },
  { value: "Family Identifier", text: "Family Identifier *", type: "Text", required: true },
  { value: "File Container Path", text: "File Container Path", type: "Text", required: false },
  { value: "File Name", text: "File Name", type: "Text", required: false },
  { value: "ImportBatchGuid", text: "ImportBatchGuid", type: "Text", required: false },
  { value: "Message Body", text: "Message Body *", type: "Text", required: true },
  { value: "Message ID", text: "Message ID", type: "Text", required: false },
  { value: "Message Sent Date", text: "Message Sent Date *", type: "Date", required: true },
  { value: "Message Sensitivity", text: "Message Sensitivity", type: "Text", required: false },
  { value: "Message Subject", text: "Message Subject", type: "Text", required: false },
  { value: "Record Type", text: "Record Type *", type: "Text", required: true },
  { value: "Teams Channel Name", text: "Teams Channel Name", type: "Text", required: false },
  { value: "Teams From Email", text: "Teams From Email *", type: "Text", required: true },
  { value: "Teams Skype Thread Id", text: "Teams Skype Thread Id *", type: "Text", required: true },
  { value: "Teams SharePoint Path", text: "Teams SharePoint Path", type: "Text", required: false },
  { value: "Teams To Email", text: "Teams To Email *", type: "Text", required: true },
  { value: "Teams Topic", text: "Teams Topic", type: "Text", required: false },
  { value: "Teams Topic", text: "Teams Topic", type: "Text", required: false },
  { value: "native link", text: "native link *", type: "Text", required: true }
];

export const SLACK_FIELDS = [ 
  { value: "Unmapped", text: "Unmapped", type: "", required: false },
  { value: "Control Number", text: "Control Number *", type: "Text", required: true },
  { value: "Family Identifier", text: "Family Identifier *", type: "Text", required: true },
  { value: "File Name", text: "File Name", type: "Text", required: false },
  { value: "File Source Path", text: "File Source Path *", type: "Text", required: true },
  { value: "File Size", text: "File Size", type: "Text", required: false },
  { value: "File MimeType", text: "File MimeType", type: "Text", required: false },
  { value: "File Title", text: "File Title", type: "Text", required: false },
  { value: "File Type", text: "File Type", type: "Text", required: false },
  { value: "ImportBatchGuid", text: "ImportBatchGuid", type: "Text", required: false },
  { value: "Message Body", text: "Message Body", type: "Text", required: true },
  { value: "Message Deleted", text: "Message Deleted", type: "Yes/No", required: false },
  { value: "Message From", text: "Message From", type: "Text", required: false },
  { value: "Message ID", text: "Message ID", type: "Text", required: false },
  { value: "Message Importance", text: "Message Importance", type: "Text", required: false },
  { value: "Message Sent Date", text: "Message Sent Date *", type: "Date", required: true },
  { value: "Message Subject", text: "Message Subject", type: "Text", required: false },
  { value: "Message To", text: "Message To *", type: "Text", required: true },
  { value: "Message Type", text: "Message Type *", type: "Text", required: true },
  { value: "Record Type", text: "Record Type *", type: "Text", required: true },
  { value: "Slack Channel Name", text: "Slack Channel Name", type: "Text", required: false },
  { value: "Slack File ID", text: "Slack File ID", type: "Text", required: false },
  { value: "Slack Subtype", text: "Slack Subtype", type: "Text", required: false },
  { value: "Slack Type", text: "Slack Type", type: "Text", required: false }
];