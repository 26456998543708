import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import AIMessage from "./ai-message/AIMessage";
import AIHistoryDropdown from "./ai-history-dropdown/AIHistoryDropdown";
import AIPromptSuggestion from "./ai-prompt-suggestion/AIPromptSuggestion";

// Services

import {
    getAISearchChats,
    createAISearchChat,
    getAISearchChatMessages,
    deleteAISearchChat,
    runAIPrompt
} from 'services/AISearchChatService';

import styles from "./AISearch.module.scss";

const AISearch = ({ matterId, onViewExamples, isReady, onQueryStart, onQueryEnd }) => {
    const messageBottomRef = useRef(null);
    const messagesAreaRef = useRef(null);

    const [inputText, setInputText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [chats, setChats] = useState([]);
    const [currentChatId, setCurrentChatId] = useState(null);

    const promptSuggestions = [
        "Provide me a list of the top 5 participants in this matter",
        "What are the key points in this data set I should ask about?",
        "Identify the key relationships between people in this matter"
    ];

    const addMessages = (chatId, newMessages) => {
        setChats(prevChats => {
            return prevChats.map(chat => {
                if (chat.chatId === chatId) {
                    return {
                        ...chat,
                        messages: chat.messages?.length > 0
                            ? [...chat.messages, ...newMessages]
                            : [...newMessages]
                    };
                }
                return chat;
            });
        });
    };

    const scrollToBottom = () => {
        if (messageBottomRef.current && messagesAreaRef.current) {
            messagesAreaRef.current.scrollTo({
                top: messagesAreaRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    const handleSubmit = async (e, promptText) => {
        e.preventDefault();
        if (!promptText.trim()) return;

        setIsLoading(true);

        onQueryStart();
        setInputText("");

        addMessages(currentChatId, [{ text: promptText, sender: "User", timestamp: Date.now() }]);
        setTimeout(() => scrollToBottom(), 100);
        try {
            const result = await runAIPrompt(matterId, currentChatId, promptText);
            addMessages(currentChatId, [{
                text: result.data.response,
                sender: "Assistant",
                timestamp: Date.now(),
                sourceChunks: result.data.sourceChunks
            }]);

            setChats(prevChats => prevChats.map(chat =>
                chat.chatId === currentChatId
                    ? { ...chat, chatName: `${promptText.substring(0, 50)}...`, timestamp: Date.now() }
                    : chat
            ));
        } catch (error) {
            console.error("Error during search:", error);
            addMessages(currentChatId, [{
                text: 'Oops, an error occurred while sending the question. Please try again later.',
                sender: "Assistant",
                timestamp: Date.now(),
                isError: true
            }]);
        } finally {
            setIsLoading(false);
            scrollToBottom();
            onQueryEnd();
        }
    };

    const handleSourcesClick = async (sourceChunks) => {
        await onViewExamples(sourceChunks.map(chunk => chunk.id));
    };

    const fetchChatMessages = async (chatId) => {
        try {
            setIsLoading(true);
            const response = await getAISearchChatMessages(matterId, chatId);
            const sortedMessages = response.data.sort((a, b) => {
                return new Date(a.timestamp) - new Date(b.timestamp);
            });

            setChats(prevChats => prevChats.map(chat =>
                chat.chatId === chatId
                    ? { ...chat, messages: sortedMessages }
                    : chat
            ));
        } catch (error) {
            console.error(error.message);
            addMessages(currentChatId, [{
                text: 'Oops, an error occurred while fetching the messages. Please try again later.',
                sender: "Assistant",
                timestamp: Date.now(),
                isError: true
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChatSelect = async (chatId) => {
        if (currentChatId === chatId) return;
        setCurrentChatId(chatId);
        await fetchChatMessages(chatId);
        scrollToBottom();
    };

    const handleNewChat = async () => {
        try {
            setIsLoading(true);
            const response = await createAISearchChat(matterId);
            const newChat = {
                chatId: response.data,
                chatName: 'New Chat',
                messages: [],
                timestamp: Date.now()
            };
            setChats(prevChats => [...prevChats, newChat].sort((a, b) => {
                return new Date(b.timestamp) - new Date(a.timestamp);
            }));
            setCurrentChatId(newChat.chatId);
            await fetchChatMessages(newChat.chatId);
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChatDelete = async (chatId) => {
        try {
            await deleteAISearchChat(matterId, chatId);
            setChats(prevChats => prevChats.filter(chat => chat.chatId !== chatId));
            const sortedChats = [...chats]
                .filter(chat => chat.chatId !== chatId)
                .sort((a, b) => {
                    return new Date(b.timestamp) - new Date(a.timestamp);
                });
            if (sortedChats.length > 0) {
                const mostRecentChat = sortedChats[0];
                await handleChatSelect(mostRecentChat.chatId);
            } else {
                await handleNewChat();
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    useEffect(() => {
        const initializeChats = async () => {
            try {
                setIsLoading(true);
                const response = await getAISearchChats(matterId);
                const resultChats = response.data;
                setChats(resultChats);

                if (resultChats.length > 0) {
                    const sortedChats = [...resultChats].sort((a, b) => {
                        return new Date(b.timestamp) - new Date(a.timestamp);
                    });
                    const mostRecentChat = sortedChats[0];
                    await handleChatSelect(mostRecentChat.chatId);
                } else {
                    await handleNewChat();
                }
            } catch (error) {
                console.error(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        initializeChats();
    }, [matterId]);

    const currentChat = chats?.find(chat => chat.chatId === currentChatId);

    const renderMessages = () => {
        return (
            <div className={styles["messages-area"]} ref={messagesAreaRef}>
                {currentChat?.messages?.map((message, index) => (
                    <AIMessage
                        key={index}
                        messageText={message.text}
                        messageType={message.sender === "User" ? "user-message" : "ai-message"}
                        isError={message.isError}
                        hasSources={message.sender === "Assistant" && message.sourceChunks?.length > 0}
                        handleSourcesClick={() => handleSourcesClick(message.sourceChunks)}
                    />
                ))}
                {isLoading && (
                    <AIMessage
                        messageText="Thinking..."
                        messageType="ai-message"
                        isError={false}
                        hasSources={false}
                        handleSourcesClick={() => { }}
                        isLoading={true}
                    />
                )}
                <div ref={messageBottomRef} />
            </div>
        );
    };

    const renderPromptSuggestions = () => {
        return (
            <div className={styles["no-messages-area"]}>
                <span>What would you like to know?</span>
                <div className={styles["prompt-suggestions"]}>
                    {promptSuggestions.map((suggestion, index) => (
                        <AIPromptSuggestion
                            key={index}
                            text={suggestion}
                            onSelect={(e, suggestionText) => {
                                setInputText(suggestionText);
                                handleSubmit(e, suggestionText);
                            }}
                        />
                    ))}
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (!isReady) {
            return (
                <div className={styles["not-ready-area"]}>
                    <span>AI search index is being prepared. Once complete, return to begin searching.</span>
                </div>
            );
        }

        if (currentChat?.messages?.length > 0) {
            return renderMessages();
        }

        if (isLoading) {
            return <div className={styles["messages-area-loading"]}>
                <SpinnerLoading isCenter={false} />
            </div>;
        }

        return renderPromptSuggestions();
    };

    return (
        <div className={styles["ai-search-wrap"]}>
            <div className={styles["chat-container"]}>
                <div className={styles["header-controls"]}>
                    <button
                        className={styles["new-chat-button"]}
                        onClick={handleNewChat}
                        disabled={isLoading || !isReady}
                    >
                        <img src="/images/icons/new-chat.svg" alt="New Chat" />
                    </button>
                    <AIHistoryDropdown
                        isDisabled={isLoading || !isReady}
                        handleChatSelect={handleChatSelect}
                        handleChatDelete={handleChatDelete}
                        currentChatId={currentChatId}
                        chats={chats}
                    />
                </div>
                {renderContent()}
                <form className={styles["input-area"]}>
                    <input
                        type="text"
                        placeholder="Ask a question..."
                        className={styles["message-input"]}
                        disabled={isLoading || !isReady}
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                    />
                    <button
                        type="submit"
                        className={styles["submit-button"]}
                        disabled={isLoading || !isReady}
                        onClick={(e) => handleSubmit(e, inputText)}
                    >
                        {isLoading ? (
                            <div className={styles["spinner-loading"]}>
                                <SpinnerLoading isCenter={false} />
                            </div>
                        ) : (
                            <img src="/images/icons/colorful-search-magnifying-glass.svg" alt="Search Icon" />
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

AISearch.propTypes = {
    matterId: PropTypes.string.isRequired,
    onViewExamples: PropTypes.func.isRequired,
    isReady: PropTypes.bool.isRequired,
    onQueryStart: PropTypes.func.isRequired,
    onQueryEnd: PropTypes.func.isRequired
};

export default AISearch;
