import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from 'react-markdown';
import clsx from "clsx";

// Styles
import styles from "./AIMessage.module.scss";

const AIMessage = ({
    messageText,
    messageType,
    isError,
    hasSources = false,
    handleSourcesClick = () => { },
    isLoading = false,
}) => {
    return (
        <>
            <div className={clsx(
                styles["message-container"],
                styles[messageType],
                isError && styles["error-message"]
            )}>
                {isLoading ? (
                    <div className={styles["loading-message"]}>
                        {messageText}
                    </div>
                ) : (
                    <ReactMarkdown
                        className={styles["markdown"]}
                        components={{
                            code: ({ node, inline, className, children, ...props }) => {
                                const match = /language-(\w+)/.exec(className || '');
                                return !inline ? (
                                    <div className={styles["code-block"]}>
                                        <code className={match ? `language-${match[1]}` : ''} {...props}>
                                            {children}
                                        </code>
                                    </div>
                                ) : (
                                    <code className={styles["inline-code"]} {...props}>
                                        {children}
                                    </code>
                                );
                            },
                        }}
                    >
                        {messageText}
                    </ReactMarkdown>
                )}
                {hasSources && (
                    <div className={styles["message-footer"]}>
                        <button
                            className={styles["sources-suggestion"]}
                            onClick={handleSourcesClick}
                        >
                            Show Examples
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

AIMessage.propTypes = {
    messageText: PropTypes.string,
    messageType: PropTypes.string,
    isError: PropTypes.bool,
    hasSources: PropTypes.bool,
    handleSourcesClick: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default AIMessage;
