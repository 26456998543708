import React, { useState, useEffect, useRef } from "react";
import _get from "lodash/get";
import { useDispatch } from "react-redux";

// Services
import { getProjects } from "services/ProjectService";

// Stores
import { clearDatePickerData } from "store/DatePickerReducer";
import { resetStoreReview } from "store/GeneralReviewReducer";
import { fetchProjectList } from "store/ProjectReducer";

// Constants
import { EVENT_KEY_LISTENER, PATH_NAME } from "constants/Common";
import { SCREEN_WIDTH_3839, SCREEN_WIDTH_2559 } from "constants/Constants";
import { RESPONSE_STATUS } from "constants/StatusCodeConstant";
import { PROJECT_CONSTANT } from "constants/ProjectConstant";
import { LOCAL_STORAGE } from "constants/LocalStorage";

// Components
import LastActivity from "components/shared/last-activity/LastActivity";
import NoProjectsFounded from "components/project/NoProjectsFounded";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import { ProjectDetailItem } from "components/project/ProjectDetailItem";
import { PaginationResult } from "components/shared/paging/PaginationResult";
import { Loading } from "components/shared/loading/Loading"

// Styles
import "components/project/styles.scss";

const ActiveProject = () => {
  const inputRef = useRef();
  const dispatch = useDispatch();

  const itemsCount = {
    lg: 12,
    md: 8,
    sm: 6,
  };
  const perPage =
    window.innerWidth > SCREEN_WIDTH_3839
      ? itemsCount.lg
      : window.innerWidth > SCREEN_WIDTH_2559
      ? itemsCount.md
      : itemsCount.sm;
  const pageIndex = 1;

  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [originProjects, setOriginProjects] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [lastActivities, setLastActivities] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [forcePageIndex, setForcePageIndex] = useState(0);

  const fetchAllProjects = async () => {
    setLoading(true);
    let pageSize = perPage;
    try {
      const { data } = await getProjects({
        pageSize,
        pageIndex,
      });
      setProjects(_get(data, "projects.items", []));
      setOriginProjects(_get(data, "projects.items", []));
      setLastActivities(_get(data, "recentActivities", []));
      setTotalCount(_get(data, "projects.totalRecords", 0));
    } catch (err) {
      console.log("Call API error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleBackByBrowser = () => {
    if (originProjects) setProjects(originProjects);
    if (inputRef.current) inputRef.current.value = "";
  };

  const fetchProjectsSuggestions = async () => {
    try {
      const { status, data } = await getProjects();
      if (status === RESPONSE_STATUS.success && data?.projects)
        setSuggestions(_get(data, "projects.selectName", []));
    } catch (err) {
      console.log("Call API error: ", err);
    }
  };

  const onSearchHandle = async (e) => {
    if (e) {
      e.preventDefault();
      if (!e.target.value) return;
    }
    const keyword = inputRef.current.value.trim();
    setLoading(true);
    setForcePageIndex(0);
    try {
      const { data } = await getProjects({
        search: keyword,
        pageSize: perPage,
        pageIndex: 1,
      });
      if (!data || !data.projects) return;
      setProjects(_get(data, "projects.items", []));
      setTotalCount(_get(data, "projects.totalRecords", 0));
    } catch (error) {
      console.log("Call API error: ", error);
    } finally {
      setLoading(false);
    }
  };


  const handlePagingClick = async (e) => {
    setLoading(true);
    setForcePageIndex(e.selected);
    const pageIndex = e.selected + 1;
    const pageSize = perPage;
    try {
      const { data } = await getProjects({
        pageSize,
        pageIndex,
      });
      data?.projects && setProjects(_get(data, "projects.items", []));
    } catch (err) {
      console.log("Call API error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const resetStore = () => {
    // clear store review
    dispatch(resetStoreReview());
    dispatch(clearDatePickerData());
    //get new project list
    dispatch(fetchProjectList());
    localStorage.setItem(PROJECT_CONSTANT.projectDetail, "");
    localStorage.setItem(LOCAL_STORAGE.previousPath, "");
  }

  useEffect(() => {
    fetchAllProjects();
    fetchProjectsSuggestions();
  }, []);

  useEffect(() => {
    window.addEventListener(EVENT_KEY_LISTENER.popstate, handleBackByBrowser());
    return () =>
      window.removeEventListener(
        EVENT_KEY_LISTENER.popstate,
        handleBackByBrowser()
      );
  }, []);

  return (
    <div className="container-fluid active-project">
      <div className="main" id="main">
        <div className="row gx-5">
          <div className="main-container-projects main-container-auto-width col-12 col-lg-9 col-xl-10">
            <div className="projects">
              <div className="entity-navigate">
                <h2>Active Matters </h2>
                <form id="searchproject" onSubmit={onSearchHandle}>
                  <div className="entity-search">
                    <SearchInputGeneral
                      inputRef={inputRef}
                      isAutoComplete={true}
                      suggestions={suggestions}
                      placeholder="Search Matters"
                      name="search"
                      onSubmitSearch={onSearchHandle}
                    />
                  </div>
                </form>
              </div>
              <div className="position-relative">
                <Loading loading={loading} />

                {!loading && (
                  <>
                    <div className="project">
                      {projects.length > 0 ? (
                        <>
                          <p className="projects-count">
                            {totalCount} Matter{totalCount > 1 ? "s" : ""}
                          </p>
                          <div className="row g-6">
                            {projects.map((project, projectIndex) => (
                              <div
                                className="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2"
                                key={projectIndex}
                              >
                                <ProjectDetailItem
                                  project={project}
                                  detailUrl={PATH_NAME.matters}
                                  resetStore={resetStore}
                                />
                              </div>
                            ))}
                          </div>
                          <PaginationResult
                            perPage={perPage}
                            forcePage={forcePageIndex}
                            totalRecord={Number(totalCount)}
                            pageItems={projects.length}
                            handlePagingClick={handlePagingClick}
                          />
                        </>
                      ) : (
                        <NoProjectsFounded />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="main-activities main-activities-modified col-12 col-lg-3 col-xl-2">
            <h3 className="main-activities-ttl">Last Activity</h3>
            {lastActivities &&
              lastActivities.map((activity, activityIndex) => (
                <LastActivity
                  key={activityIndex}
                  className="activities-box"
                  title={activity.name}
                  name={activity.projectName}
                  content={activity.description}
                  postTime={activity.creationTime}
                  projectId={activity.projectId}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveProject;
