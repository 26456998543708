import { authHeader, axiosWithoutErrorPage } from "./ApiConfig";

export const getAISearchStatus = async (matterId) => {
    return await axiosWithoutErrorPage({
        method: 'get',
        baseURL: `${process.env.REACT_APP_API_URL}/aiSearchChat/${matterId}/status`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
    });
};

export const runAIPrompt = async (matterId, chatId, prompt) => {
    return await axiosWithoutErrorPage({
        method: 'post',
        baseURL: `${process.env.REACT_APP_API_URL}/aiSearchChat/${matterId}/query`,
        timeout: 600000,
        headers: {
          ...authHeader(),
        },
        data: {
            chatId: chatId,
            prompt: prompt
        }
    });
};

export const getAISearchChats = async (matterId) => {
    return await axiosWithoutErrorPage({
        method: 'get',
        baseURL: `${process.env.REACT_APP_API_URL}/aiSearchChat/${matterId}/chats`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
    });
};

export const createAISearchChat = async (matterId) => {
    return await axiosWithoutErrorPage({
        method: 'post',
        baseURL: `${process.env.REACT_APP_API_URL}/aiSearchChat/${matterId}/chats`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
    });
};

export const getAISearchChatMessages = async (matterId, chatId) => {
    return await axiosWithoutErrorPage({
        method: 'get',
        baseURL: `${process.env.REACT_APP_API_URL}/aiSearchChat/${matterId}/chat/${chatId}/messages`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
    });
};

export const deleteAISearchChat = async (matterId, chatId) => {
    return await axiosWithoutErrorPage({
        method: 'delete',
        baseURL: `${process.env.REACT_APP_API_URL}/aiSearchChat/${matterId}/chat/${chatId}`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
    });
};
