import React from "react";
import PropTypes from "prop-types";

// Components
import RichTextAttachmentRenderer from "components/attachment-render/RichTextAttachmentRenderer";
import DocumentAttachmentRenderer from "components/attachment-render/DocAttachmentRenderer";
import SpreadsheetAttachmentRenderer from "components/attachment-render/SpreadsheetAttachmentRenderer";
import PdfAttachmentRenderer from "components/attachment-render/PdfAttachmentRenderer";
import PresentationAttachmentRenderer from "components/attachment-render/PresentationAttachmentRenderer";
import PreviewNotAvailable from "components/intelligent-search/search-result/preview/preview-not-available/PreviewNotAvailable";

// Styles
import styles from "./PreviewFile.module.scss";

// Constants
import { FILE_TYPE, SPREADSHEET_MIME_TYPES, WORD_MIME_TYPES, PRESENTATION_MIME_TYPES, PDF_RECORD_TYPES, WORD_RECORD_TYPES, SPREADSHEET_RECORD_TYPES, PRESENTATION_RECORD_TYPES, ZIP_MIME_TYPES } from "constants/DataType";

const PreviewFile = (props) => {
    const { projectId, attachmentId, contentType, recordType, hasHtml } = props;

    const getFileRenderer = () => {
        if (ZIP_MIME_TYPES.includes(contentType)) {
            return <PreviewNotAvailable />;
        }

        const recordTypeRenderer = getFileRendererByRecordType();
        if (recordTypeRenderer) {
            return recordTypeRenderer;
        }

        const mimeTypeRenderer = getFileRendererByMimeType();
        if (mimeTypeRenderer) {
            return mimeTypeRenderer;
        }

        if (hasHtml) {
            return <RichTextAttachmentRenderer
                projectId={projectId}
                attachmentId={attachmentId}
            />
        }

        return <PreviewNotAvailable />;
    };

    const getFileRendererByRecordType = () => {
        if (PDF_RECORD_TYPES.includes(recordType)) {
            return <PdfAttachmentRenderer
                projectId={projectId}
                attachmentId={attachmentId}
            />
        }

        if (WORD_RECORD_TYPES.includes(recordType)) {
            return <DocumentAttachmentRenderer
                projectId={projectId}
                attachmentId={attachmentId}
            />
        }

        if (SPREADSHEET_RECORD_TYPES.includes(recordType)) {
            return <SpreadsheetAttachmentRenderer
                projectId={projectId}
                attachmentId={attachmentId}
            />
        }

        if (PRESENTATION_RECORD_TYPES.includes(recordType)) {
            return <PresentationAttachmentRenderer
                projectId={projectId}
                attachmentId={attachmentId}
            />
        }

        return null;
    };

    const getFileRendererByMimeType = () => {
        if (WORD_MIME_TYPES.includes(contentType)) {
            return <DocumentAttachmentRenderer
                projectId={projectId}
                attachmentId={attachmentId}
            />
        }

        if (SPREADSHEET_MIME_TYPES.includes(contentType)) {
            return <SpreadsheetAttachmentRenderer
                projectId={projectId}
                attachmentId={attachmentId}
            />
        }

        if (PRESENTATION_MIME_TYPES.includes(contentType)) {
            return <PresentationAttachmentRenderer
                projectId={projectId}
                attachmentId={attachmentId}
            />
        } 

        if (FILE_TYPE.pdf === contentType) {
            return <PdfAttachmentRenderer
                projectId={projectId}
                attachmentId={attachmentId}
            />
        }

        return null;
    };

    return <div className={styles["preview-file__content"]}>{getFileRenderer()}</div>;
};

export default PreviewFile;

PreviewFile.propTypes = {
    projectId: PropTypes.string,
    attachmentId: PropTypes.string,
    contentType: PropTypes.string,
    recordType: PropTypes.string,
    hasHtml: PropTypes.bool,
};

