import axios, { authHeader } from "./ApiConfig";

// Constants & Helpers
import { METHOD_API } from "constants/Common";

const getClients = (params = {}) =>
  axios.get(`/projectAdmin/clients`, {
    headers: authHeader(),
    params,
  });

const postRequestArchive = (projectId) =>
  axios({
    url: `/projectAdmin/${projectId}/requestArchive`,
    method: METHOD_API.post,
    headers: authHeader(),
  });

const postRequestDelete = (projectId) =>
  axios({
    url: `/projectAdmin/${projectId}/requestDelete`,
    method: METHOD_API.post,
    headers: authHeader(),
  });

const postRequestNearline = (projectId) =>
  axios({
    url: `/projectAdmin/${projectId}/requestNearline`,
    method: METHOD_API.post,
    headers: authHeader(),
  });

const postApproveArchive = (projectId) =>
  axios({
    url: `/projectAdmin/${projectId}/approveArchive`,
    method: METHOD_API.post,
    headers: authHeader(),
  });

const postRejectArchive = (projectId) =>
  axios({
    url: `/projectAdmin/${projectId}/rejectArchive`,
    method: METHOD_API.post,
    headers: authHeader(),
  });

const postActiveProject = (projectId) =>
  axios({
    url: `/projectAdmin/${projectId}/activeProject`,
    method: METHOD_API.post,
    headers: authHeader(),
  });

const postRestoreArchive = (projectId) =>
  axios({
    url: `/projectAdmin/${projectId}/restoreArchive`,
    method: METHOD_API.post,
    headers: authHeader(),
  });

const getProjectUsers = (projectId) =>
  axios.get(`/projectAdmin/${projectId}/users`, {
    headers: authHeader(),
  });

const exportExcelForAdmin = (params = {}) =>
  axios({
    url: `/projectAdmin/exportExcel`,
    method: METHOD_API.post,
    headers: authHeader(),
    data: params,
    responseType: "blob",
  });

const getProjectToManage = (params) =>
  axios.get("/projectAdmin/manageProjects", {
    headers: authHeader(),
    params,
  });

const createMatterApi = (data) =>
  axios.post(`/projectAdmin/createMatter`, data, {
    headers: authHeader(),
    timeout: 210000,
  });

const getMatterDateRange = (matterId) =>
  axios({
    url: `/projectAdmin/matters/${matterId}/dateRange`,
    method: METHOD_API.get,
    headers: authHeader()
  });

const setMatterDateRange = (params = {}, matterId) =>
  axios({
    url: `/projectAdmin/matters/${matterId}/dateRange`,
    method: METHOD_API.put,
    headers: authHeader(),
    data: params,
  });

const getMatterAvatar = (matterId, userId) =>
  axios({
    url: `/projectAdmin/matters/${matterId}/userAvatar/${userId}`,
    method: METHOD_API.get,
    headers: authHeader(),
    responseType: "blob",
  });

export {
  getClients,
  postRequestArchive,
  postApproveArchive,
  postRejectArchive,
  postRestoreArchive,
  postActiveProject,
  getProjectUsers,
  exportExcelForAdmin,
  getProjectToManage,
  createMatterApi,
  getMatterDateRange,
  setMatterDateRange,
  getMatterAvatar,
  postRequestDelete,
  postRequestNearline
};
