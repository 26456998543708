import React from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

// Components
import { MemberList } from "components/event-timeline/members/MemberList";

// Helpers & Constants
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { DATE_TIME_TYPE } from "constants/Constants";
import ItemResultInline from "components/shared/item-result-inline/ItemResultInline";
import { PATH_NAME } from "constants/Common";

const ProjectDetailItem = ({ project, detailUrl, resetStore }) => {

  const matterData = [
    {
      label: "Total items",
      value: project.totalItems.toLocaleString(),
      isBold: true,
    },
    {
      label: "Total GB",
      value: project.totalGBHosted.toLocaleString(),
      isBold: true,
    },
    {
      label: "Responsive",
      value: project.responsiveItems.toLocaleString(),
      isBold: true,
    },
    {
      label: "Non-Responsive",
      value: project.nonResponsiveItems.toLocaleString(),
      isBold: true,
    },
    {
      label: "Untagged",
      value: project.unTaggedItems.toLocaleString(),
      isBold: true,
    },
    {
      label: "Exported",
      value: project.exportedItems.toLocaleString(),
      isBold: true,
    },
    {
      label: "Last Activity",
      value: formatDateTime({
        dateTime: project.lastActivity,
        type: DATE_TIME_TYPE.MM_DD_YYYY,
      }),
    },
  ];

  return (
    <LazyLoad
      height={100}
      offset={[-100, 100]}
      placeholder={<Spinner animation="border" variant="primary" />}
    >
      <div id="projectbox" className="project-box">
        <div className="project-ttl">
          <h4>
            <Link
              key={project.projectId}
              title={project.projectName}
              to={`/${detailUrl}/${project.projectId}/${PATH_NAME.streems}`}
              onClick={resetStore}
            >
              {project.projectName}
            </Link>
          </h4>
        </div>
        <div className="project-items">
          <dl>
            <dt>Users:</dt>
            <div className="project-items-members">
              <MemberList
                data={project.users.map((user) => ({
                  name: user.name,
                  avatar: user.avatar,
                  userId: user.userId
                }))}
                matterId={project.projectId}
                limitMember={5}
                size="md"
              />
            </div>
          </dl>
          {matterData.map((item) => (
            <ItemResultInline
              key={item.label}
              label={item.label}
              value={item.value}
              fwBold={item.isBold}
            />
          ))}
        </div>
      </div>
    </LazyLoad>
  );
};

ProjectDetailItem.propTypes = {
  project: PropTypes.any,
  detailUrl: PropTypes.string,
  resetStore: PropTypes.func,
};

export { ProjectDetailItem };
