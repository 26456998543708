import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import ReactSelect from "react-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";

// Constants
import {
  ARCHIVE_MSG,
  ARCHIVE_STATUS,
  ARCHIVE_TYPE,
  PAGE_NAME,
  PATH_NAME,
  PATHNAME_ITEMS,
} from "constants/Common";
import { LOCAL_STORAGE } from "constants/LocalStorage";

// Hooks
import { useToken } from "hook/auth";
import { useOnClickOutside } from "hook/click-outside";

// Services
import { logOut } from "services/AuthService";
import { getProjectDetailApi } from "services/ProjectDetailService";

import { handleAbortRequest } from "services/ApiConfig";

// Stores
import {
  clearProjectAdminSearchCondition,
  clearSavedSearchCondition,
} from "store/SearchConditionReducer";
import { setProjectDetail } from "store/ProjectReducer";
import { resetStoreReview, setPaging } from "store/GeneralReviewReducer";
import { resetStoreSearchResult } from "store/ISReducer";
import { clearDatePickerData } from "store/DatePickerReducer";
import { setIsGoToOrigin } from "store/CommonReducer";
import { resetStoreDataSource } from "store/DataSourceReducer";
import { clearHeatmapData } from "store/HeatMapReducer";
import { resetStoreTimeline } from "store/EventTimelineReducer";

// Components
// import { RenderImage } from "components/renderImage/RenderImage";
import UserAvatar from "components/shared/file-components/user-avatar/UserAvatar";
import DropDownBox from "components/shared/dropdown-box/DropDownBox";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";
import AssignUserManagement from "components/project/manage-assign-user/AssignUserManagement";
import NavBar from "components/header/nav-bar/NavBar";

// Helpers
import { isReadValue } from "helpers/ObjectHelper";

// Styles
import styles from "./Header.module.scss";

const Header = ({ errorCode }) => {
  const supportLink = "https://streemview.freshdesk.com/support/home";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuRef = useRef();
  const { pathname } = useLocation();
  const { isUser, isClientAdmin } = useToken();
  const { logout: logOutAuth0 } = useAuth0();

  const user = useSelector((state) => state.user.userInfo);
  const {
    projects,
    projectDetail,
    loading: loadingMatter,
  } = useSelector((state) => state.projects);
  const disableMatter = useSelector((state) => state.common.disableMatter);

  const existProjectId = pathname.split("/").length < 3 ? false : true;
  const projectId = pathname.split("/")[2];
  const ADMINISTRATION_ACTION = { addUser: 2, archive: 4 };

  const [matters, setMatters] = useState([]);
  const [currentMatter, setCurrentMatter] = useState({});
  const [isShowModal, setIsShowModal] = useState(false);
  const [projectStatus, setProjectStatus] = useState(ARCHIVE_TYPE.active);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPopupAssignDsp, setIsPopupAssignDsp] = useState(false);
  const [loadingProjectDetail, setLoadingProjectDetail] = useState(false);
  const [archiveItem, setArchiveItem] = useState({
    id: ADMINISTRATION_ACTION.archive,
    name: "",
    path: "",
    disable: false,
  });

  const ARCHIVE_ACTIONS = [
    {
      status: ARCHIVE_STATUS.pending.status,
      title: ARCHIVE_STATUS.pending.actionLabel,
      content: ARCHIVE_MSG.request,
      textReject: "Reject",
      textConfirm: "Approve",
    },
    {
      status: ARCHIVE_STATUS.active.status,
      title: ARCHIVE_STATUS.active.actionLabel,
      content: ARCHIVE_MSG.archive,
      textReject: "",
      textConfirm: "Yes",
    },
  ];

  const menuList = [
    { id: 1, name: "Matters", path: PATH_NAME.matters },
    {
      id: 2,
      name: "Users",
      path: PATH_NAME.userAdministration,
      disable: isUser(),
    },
  ];

  const navList = [
    { id: 1, name: PAGE_NAME.dataSources, path: PATH_NAME.dataSources },
    { id: 2, name: PAGE_NAME.people, path: PATH_NAME.people },
    { id: 3, name: PAGE_NAME.streems, path: PATH_NAME.streems },
    {
      id: 6,
      name: PAGE_NAME.search,
      children: [
        { id: 101, name: PAGE_NAME.intelligentSearch, path: PATH_NAME.intelligentSearch },
        { id: 102, name: PAGE_NAME.savedSearches, path: PATH_NAME.savedSearches },
        { id: 103, name: PAGE_NAME.searchTerms, path: PATH_NAME.searchTerms }
      ]
    },
    {
      id: 4,
      name: PAGE_NAME.insights,
      children: [
        { id: 104, name: PAGE_NAME.communicationAnalyzer, path: PATH_NAME.communicationAnalyzer },
        { id: 105, name: PAGE_NAME.eventTimeline, path: PATH_NAME.eventTimeline }
      ]
    },
    {
      id: 5,
      name: PAGE_NAME.administration,
      path: PATH_NAME.userAdministration,
      children: [
        { id: 1, name: PAGE_NAME.exportManagement, path: PATH_NAME.exportManagement, disable: isUser() },
        { id: ADMINISTRATION_ACTION.addUser, name: PAGE_NAME.addUser, path: "", disable: isUser() },
        { id: 3, name: PAGE_NAME.tagManagement, path: PATH_NAME.tagManagement, },
        { id: 4, name: PAGE_NAME.exportData, path: PATH_NAME.createExport, disable: true },
      ],
    }
  ];

  const getNavTabId = (pageName) => {
    return navList.find((item) => item.name === pageName).id;
  };

  useOnClickOutside(menuRef, () => setIsMenuOpen(false));

  const getCurrentPath = () => {
    const segments = pathname.split("/");
    const parentTab = segments[3] || "";
    const previousPath = localStorage.getItem(LOCAL_STORAGE.previousPath);

    const isPathInItemDetail = () => {
      return pathname.includes(PATH_NAME.itemDetail);
    };

    const getParentChildTabs = () => {
      let result = { parentTab: getNavTabId(PAGE_NAME.streems), childTab: "" };
      navList.forEach((parent) => {
        if (parent.path === parentTab) {
          result.parentTab = parent.id;
          return;
        }
        if (parent?.children) {
          parent.children.forEach((child) => {
            if (child.path === parentTab) {
              result = { parentTab: parent.id, childTab: child.id };
              return;
            }
          });
        }
      });
      return result;
    };

    if (isPathInItemDetail()) {
      if (
        [
          PATH_NAME.intelligentSearch,
          PATH_NAME.savedSearches,
          PATH_NAME.dataSources,
        ].includes(previousPath) || !previousPath
      ) {
        return { parentTab: getNavTabId(PAGE_NAME.streems), childTab: "" };
      } else {
        return { parentTab: getNavTabId(PAGE_NAME.insights), childTab: "" };
      }
    }

    return getParentChildTabs();
  };

  // Update current path and update current matter
  const onSwitchMatter = (matter) => {
    handleAbortRequest();
    setCurrentMatter(matter);
    navigate(pathname.replace(`${currentMatter.value}`, matter.value));
  };

  // Handling click item in menu
  // Navigate to screen
  const handleClickMenu = (itemId) => {
    dispatch(setIsGoToOrigin(false));
    const path = menuList.find((item) => item.id === itemId).path;
    if (path) {
      navigate(path);
      resetStore();
    }
  };

  // Handling click tab and child tab
  // Including Assigning user, Archive and navigate
  const handleClickNav = ({ parentTab, childTab }) => {
    dispatch(setIsGoToOrigin(false));
    if (parentTab === getNavTabId(PAGE_NAME.administration)) {
      // Handle assign user into project
      if (ADMINISTRATION_ACTION.addUser === childTab) {
        setIsPopupAssignDsp(true);
        return;
      }
      // Handle archive project
      if (ADMINISTRATION_ACTION.archive === childTab) {
        setIsShowModal(true);
        return;
      }
    }
    // Navigate to other screens
    const result = navList?.find((item) => item.id === parentTab);

    if (parentTab === getNavTabId(PAGE_NAME.search) && (childTab === 101 || childTab === 102))
      resetSearchLocalStorage();

    // Get current path and navigate to this path
    const currentPath = childTab === null
        ? result.path
        : result?.children.find((item) => item.id === childTab).path;

    currentPath && navigate(`/${PATH_NAME.matters}/${projectId}/${currentPath}`);

    resetStore();
    resetInsightsStore(childTab);
  };

  const resetSearchLocalStorage = () => {
    localStorage.setItem(
      `${LOCAL_STORAGE.searchData}${projectId}`,
      JSON.stringify({
        dataSources: [],
        endDate: null,
        entities: [],
        dataTypes: [],
        includeBlankDate: true,
        startDate: null,
        typeFilter: 0,
      })
    );
    dispatch(resetStoreReview());
    dispatch(setPaging({ recordNumber: 0, pageIndex: 0 }));
    dispatch(clearDatePickerData());
    dispatch(clearSavedSearchCondition()); // clear store saved search
    localStorage.setItem(LOCAL_STORAGE.intelligentSearchPath, "");
  };

  const resetInsightsStore = (tabNumber) => {
    switch (tabNumber) {
      case 104:
        dispatch(clearHeatmapData()); // Clear store communication analyzer
        break;
      case 105:
        dispatch(resetStoreTimeline()); // Clear store event timeline
        break;
      default:
        break;
    }
  };

  const fetchProjectData = async () => {
    try {
      const { data } = await getProjectDetailApi(projectId);
      setProjectStatus(data.project.status);
      dispatch(setProjectDetail(data.project));
    } catch (err) {
      console.log(err);
    }
  };

  const onLogOut = async () => {
    try {
      await logOut().then(() =>
        logOutAuth0({ returnTo: `${window.location.origin}/login` })
      );
      navigate(PATHNAME_ITEMS.login);
    } catch (error) {
      console.log(error);
    }
  };

  


  // Get popup's information (title, content, btnName, ..)
  const getInfoPopUp = () =>
    ARCHIVE_ACTIONS.find((item) => item.status === projectStatus);

  const navigateUserProfile = () => navigate(PATHNAME_ITEMS.userProfile);

  // Update user list when assign or remove user
  const updateUsers = async () => {
    try {
      setLoadingProjectDetail(true);
      const dataResult = await getProjectDetailApi(projectId);
      dispatch(setProjectDetail(dataResult.data.project));
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingProjectDetail(false);
    }
  };

  const resetStore = () => {
    // clear store review
    if (!pathname.includes(PATH_NAME.streems)) {
      dispatch(resetStoreReview());
      dispatch(clearDatePickerData());
      // clear previous path local storage
      localStorage.removeItem(LOCAL_STORAGE.previousPath);
    }
    // clear store data source
    if (!pathname.includes(PATH_NAME.dataSources))
      dispatch(resetStoreDataSource());

    // clear store project admin
    dispatch(clearProjectAdminSearchCondition());
    // clear store search result
    dispatch(resetStoreSearchResult());
    // clear store saved search
    dispatch(clearSavedSearchCondition());
  };

  // Set matter list and set current matter when project list has changing
  useEffect(() => {
    if (projects.length === 0) return;
    // Update matter list
    setMatters(
      projects.map((project) => ({
        value: project.projectId,
        label: project.projectName,
      }))
    );
  }, [JSON.stringify(projects)]);

  useEffect(() => {
    // Update action archive in header navigation
    let name = ARCHIVE_TYPE.request;
    let disable = false;
    if (ARCHIVE_TYPE.active === projectStatus) {
      name = ARCHIVE_TYPE.request;
      // Hidden with client admin role
      disable = isClientAdmin();
    }
    if (ARCHIVE_TYPE.pending === projectStatus) {
      name = ARCHIVE_TYPE.archive;
      // Hidden with user role
      disable = isUser();
    }
    setArchiveItem({ ...archiveItem, name, disable });
  }, [projectStatus]);

  useEffect(() => {
    existProjectId && fetchProjectData();
  }, [projectId]);

  useEffect(() => {
    if (existProjectId && projects.length > 0) {
      const currentProject = projects.find(
        (item) => item.projectId === Number(projectId)
      );
      if (isReadValue(currentProject)) {
        // Set current matter
        setCurrentMatter({
          value: currentProject.projectId,
          label: currentProject.projectName,
        });
      }
    }
  }, [projectId, JSON.stringify(projects)]);

  return (
    <header className={styles["header"]}>
      <div className={styles["logo-container"]}>
        <figure onClick={() => setIsMenuOpen(true)} ref={menuRef}>
          <img src="/images/icons/menu-icon.svg" alt="menu icon" />
          <div className={styles["menu-box"]} hidden={!isMenuOpen}>
            <DropDownBox
              dataList={menuList.filter((item) => item?.disable !== true)}
              onclickItem={(item) => {
                handleClickMenu(item.id);
                setIsMenuOpen(false);
              }}
            />
          </div>
        </figure>
        <Link to={PATHNAME_ITEMS.matters} className="btn_alpha">
          <img
            src="/images/streemview-logo.svg"
            alt="Streemview logo"
            className={styles["logo"]}
          />
        </Link>
      </div>
      {existProjectId && (
        <nav
          className={clsx(
            `${[423, 403, 400].includes(errorCode) ? "page-error" : ""}`,
            styles["header-nav"]
          )}
          id="nav_global"
        >
          <NavBar
            tabList={navList}
            currentTab={getCurrentPath()}
            size="lg"
            onClickTab={({ parentTab, childTab }) =>
              handleClickNav({ parentTab, childTab })
            }
          />
        </nav>
      )}
      <div
        className={clsx(
          `profile ${[423, 403, 400].includes(errorCode) ? "page-error" : ""}`,
          styles["profile-container"]
        )}
      >
        {existProjectId && (
          <div className={styles["matter-wrap"]}>
            <ReactSelect
              styles={{ control: (base) => ({ ...base, width: "22rem" }) }}
              options={matters}
              value={currentMatter}
              onChange={onSwitchMatter}
              isDisabled={disableMatter || loadingMatter}
            />
          </div>
        )}

        <figure className={styles["avatar-header"]}>
          <Link to={PATHNAME_ITEMS.userProfile}>
            <UserAvatar />
          </Link>
        </figure>
        <Dropdown>
          <Dropdown.Toggle
            className="profile-name dropdown-toggle"
            id="dropdown-basic"
          >
            {user?.firstName} {user?.lastName}
          </Dropdown.Toggle>
          <Dropdown.Menu className="profile-name-dropdown dropdown-menu mt-2">
            <Dropdown.Item onClick={() => window.open(supportLink)}>
              <img
                className="profile-name-icon"
                src="/images/phone_icon.svg"
                alt="Phone"
              />
              <span>Support</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={navigateUserProfile}>
              <img
                className="profile-name-icon"
                src="/images/setting_icon.svg"
                alt="Settings"
              />
              <span>Settings</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={onLogOut}>
              <img
                className="profile-name-icon"
                src="/images/sign_out_icon.svg"
                alt="Sign out"
              />
              <span>Log Out</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <PopupConfirm
          isShow={isShowModal}
          title={getInfoPopUp().title}
          content={getInfoPopUp().content}

          handleClose={() => setIsShowModal(false)}
        />
        {isPopupAssignDsp && (
          <AssignUserManagement
            users={projectDetail.users}
            projectId={projectDetail.projectId}
            onHide={() => setIsPopupAssignDsp(false)}
            show={isPopupAssignDsp}
            updateUsers={updateUsers}
            isLoading={loadingProjectDetail}
          />
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  errorCode: PropTypes.number,
};

export default Header;
